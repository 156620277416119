( function(app, $) {

	var $cache = {},
		scrolTop, pdpImagesHeight, pdpDetailHeight, headerHeight, endPdpImagesTop, pdpImagesTop, pdpDetailTop, endPdpDetailTop,
		devicesConfigs = {
			"ipad" : {
				position : 'fixed',
				setWidth : true,
				getDynamicOptions : function () {
					return { 
						condition1 : pdpDetailHeight > pdpImagesHeight && pdpDetailTop-scrolTop <= headerHeight,
						condition2 : endPdpImagesTop + headerHeight >= endPdpDetailTop,
						top : headerHeight
					}
				}
			},
			"safari" : {
				position : 'fixed',
				setWidth : true,
				getDynamicOptions : function () {
					return { 
						condition1 : pdpDetailHeight > pdpImagesHeight && pdpDetailTop-scrolTop <= headerHeight,
						condition2 : endPdpImagesTop + headerHeight >= endPdpDetailTop,
						top : headerHeight
					}
				}
			},
			"msie" : {
				position : 'fixed',
				setWidth : true,
				getDynamicOptions : function () {
					return { 
						condition1 : pdpDetailHeight > pdpImagesHeight && pdpDetailTop-scrolTop <= headerHeight,
						condition2 : endPdpImagesTop + headerHeight >= endPdpDetailTop,
						top : headerHeight
					}
				}
			},
			"default" : {
				position : 'absolute',
				setWidth : false,
				getDynamicOptions : function () {
					return {
						condition1 : pdpDetailHeight > pdpImagesHeight && pdpDetailTop-scrolTop <= headerHeight,
						condition2 : endPdpImagesTop + headerHeight >= endPdpDetailTop,
						top : scrolTop - pdpDetailTop + headerHeight
					}
				}
			}
		};	

	function getConfigs() {
		return $cache.deviceType in devicesConfigs ? devicesConfigs[$cache.deviceType] : devicesConfigs['default'];
	}

	function initializeCache() {
		$cache = {
			scrollElem : $(window),
			html : $('html'),
			pdpImagesCntr : $('.js-product_images_container'),
			pdpImagesBlock : $('.js-product_images_fixed'),
			pdpDetailBlock : $('.js-product_detail-fixed-images'),
			header : $('.l-header_main'),
			tabs : $('.js-product_tabs'),
			primaryContent : $('.js-pdp_primary_content'),
		};
		$cache.deviceType = navigator.userAgent.match(/iPad/i) ? 'ipad' : app.device.currentBrowser();
	} 
	
	function initializeDOM() {
		$cache.html.addClass($cache.deviceType);
	}
	
	function updateValues() {
		scrolTop = $cache.scrollElem.scrollTop(); 
		headerHeight = $cache.header.outerHeight();
		pdpImagesHeight = $cache.pdpImagesBlock.outerHeight();
		pdpDetailHeight = $cache.pdpDetailBlock.outerHeight();
		pdpImagesTop = $cache.pdpImagesBlock.offset().top;
		pdpDetailTop = $cache.pdpDetailBlock.offset().top;
		endPdpImagesTop = scrolTop + pdpImagesHeight;
		endPdpDetailTop = pdpDetailTop + pdpDetailHeight;
	}
	
	function setPosition(top, position, elem){
		(elem || $cache.pdpImagesBlock).css({
			'top' : top + 'px',
			'position' : position
		});
	}
	
	function setWidth(width, elem) {
		(elem || $cache.pdpImagesBlock).css({
			'width' : width + (isNaN(width) ? '' : 'px')
		});	
	}
	
	function initImgScrollFixed(params) {
		var dynamicOptions,
			cntrMargins;
		
		if (params.setWidth) {
			cntrMargins = $cache.pdpImagesCntr.outerWidth(true) - $cache.pdpImagesCntr.outerWidth();
			setWidth(($cache.primaryContent.outerWidth() - $cache.pdpDetailBlock.outerWidth(true) - cntrMargins), $cache.pdpImagesBlock);
		}

		updateValues();
		dynamicOptions = params.getDynamicOptions();
		
		if(dynamicOptions.condition1){
			if (dynamicOptions.condition2) {
				setPosition(pdpDetailHeight - pdpImagesHeight, 'relative');
			} else {
				setPosition(dynamicOptions.top, params.position);
			}
		} else {
			setPosition('0', 'relative');	
		}	
	}
	
	function initializeEvents() {
		var params = getConfigs();
		
		$cache.scrollElem = params.scrollElem ? $(params.scrollElem) : $cache.scrollElem;
		
		$cache.scrollElem.on('scroll resize orientationchange', function(){
			initImgScrollFixed(params);
		});

		if($cache.tabs.length) {
			$cache.tabs.on('click', function(){
				initImgScrollFixed(params);
			});
		}	
	
		$cache.pdpImagesBlock.on('images.container.cloned', function(e, params){
			setPosition(0, 'static', params.clone);
			setWidth('auto', params.clone);
		});
	}
	
	/**
	* @namespace app.product.fixedimages public object
	**/
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.fixedimages = {
		init : function() {
			if (!app.device.isMobileView()) {
				initializeCache();

				if($cache.pdpImagesBlock.length){
					initializeDOM();
					initializeEvents();
				}
			}
		}
	};

}(window.app = window.app || {}, jQuery));

