;(function (app, $) {

	if (app.device.isMobileView()) {
		var $cache = {
			body : $('body'),
			swipeContOpenMenuSel : '.m-site_opened .js-header_menu_toggle'
		}

		$cache.body.on('swipeleft', $cache.swipeContOpenMenuSel, app.components.global.categoriesNavigation.menuToggle);
	}

	/**
	 * @function
	 * @description Append part of suggested phrase 
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	var placeSuggest = function (data, $cache) {
		if (!data || data.length == 0){ 
			$cache.suggestInput.empty();
			return;
		}
		var firstElem = data[0],
			searchTerm = $cache.searchInput.val();
		
		if ("suggest" in firstElem){
			var suggestPhrase = firstElem.suggest;
			
			suggestPhrase = suggestPhrase.substring(searchTerm.length, suggestPhrase.length);
			$cache.suggestInput.html(suggestPhrase);
		}
	}
	/**
	 * @function
	 * @description callback functions that are using in HR brand 
	 */
	app.simpleSearchFunctions = {
		simpleSearchHandler : function ($cache) {
			isLoading = true;
			$.extend($cache, {
				selectedCategory : $(".js-min_search .js-simple_search_category_button.active").val() || app.preferences.simpleSearchDefaultCategoryId
			});
			var that = this,
				searchTerm = $cache.searchInput.val(),
				url = app.util.appendParamsToUrl(app.urls.searchSuggestions, {"cgid" : $cache.selectedCategory, "q" : searchTerm});
				
			app.ajax.load({
				url: url,
				callback : function (data) {
					that.successSimpleSearchCallback(data, $cache);
				}
			});

		},
		
		/**
		 * @function
		 * @description Append part of suggested phrase 
		 * @param {Array} Array of searched product + suggested phrase
		 * @param {Object} global cache
		 */		
		successSimpleSearchCallback : function(data, $cache) {
			this.buildResponseDom(data, $cache);
			placeSuggest(data, $cache);
			if($cache.resultContainer.find('li').length){
				$cache.resultContainer.removeClass(this.minimizedClass);
			};
			setTimeout(function() {
				isLoading = false;

				var bodyPaddingTop = parseInt($("header").height());
				$('body').animate({"padding-top": bodyPaddingTop + "px"}, 1000, "easeInExpo");
			
			}, this.delay);
			
		}
	};
	
	app.recommendations.initCarouselAfterAjaxLoad = function($carouselContainer){
		$($carouselContainer).each(function(){
			// Skip carousels which are already initialized (in case when several carousel present on page)
			if(!app.owlcarousel.getInstance(this)){
				app.owlcarousel.initCarousel(this);
			}
		})
	}
}(window.app = window.app || {}, jQuery));