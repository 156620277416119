;(function(app, $) {
	function AttributeTab(id, config) {
		this.id = id;
		this.main = config.main;
		this.attributes = [];
		this.html = createHTML.call(this, config.attributes);
		this.lastUnselectedAttribute = null;
	}
	
	AttributeTab.prototype.getId = function() {
		return this.id;
	};
	
	AttributeTab.prototype.isMain = function() {
		return this.main;
	};
	
	AttributeTab.prototype.getHTML = function() {
		return this.html;
	};
	
	AttributeTab.prototype.getSelectedAttribute = function() {
		return this.attributes.filter(function(attribute) {
			return attribute.isSelected();
		})[0];
	};
	
	AttributeTab.prototype.getLastUnselectedAttribute = function() {
		return this.lastUnselectedAttribute;
	};
	
	AttributeTab.prototype.getAttribute = function(id) {
		return this.attributes.filter(function(attribute) {
			return attribute.getId() === id;
		})[0];
	};
	
	AttributeTab.prototype.show = function() {
		this.html.$title.addClass('active');
		this.html.$body.removeClass('h-hidden');
	};
	
	AttributeTab.prototype.hide = function() {
		this.html.$title.removeClass('active');
		this.html.$body.addClass('h-hidden');
	};
	
	AttributeTab.prototype.selectAttribute = function(id) {
		var selectedAttribute = this.getSelectedAttribute();
		if (selectedAttribute && selectedAttribute.getId() !== id) {
			this.unselectSelectedAttribute();
		}
		
		var attribute = this.getAttribute(id);
		if (attribute) {
			attribute.select();
		}
	};
	
	AttributeTab.prototype.unselectSelectedAttribute = function() {
		var attribute = this.getSelectedAttribute();
		
		if (attribute) {
			attribute.unselect();
		}
	};
	
	AttributeTab.prototype.notify = function(data) {
		switch(data.message) {
			case 'attribute.selected':
				var previouslySelectedAttribute = this.attributes.filter(function(attribute) {
					return attribute.isSelected() && attribute.getId() !== data.attribute.getId();
				})[0];
				
				if (previouslySelectedAttribute) {
					previouslySelectedAttribute.unselect();
				}
				
				this.lastUnselectedAttribute = null;
				
				break;
			case 'attribute.unselected':
				this.lastUnselectedAttribute = this.getAttribute(data.attribute.getId());
				break;
			default:
				break;
		}
		
		data.tab = this;
		app.configurator.attributeTabsMgr.notify(data);
	};
	
	AttributeTab.prototype.update = function(data) {
		switch(data.message) {
			case 'attribute.selected':
			case 'attribute.unselected':
				if (!this.main) {
					var availableValues = app.configurator.attributeTabsMgr.getAvailableAttributeValues(this.id);
					
					for (var i = 0, len = this.attributes.length; i < len; i++) {
						var attribute = this.attributes[i];
						
						if (availableValues.indexOf(attribute.getId()) === -1) {
							attribute.disable();
						}
						else {
							attribute.enable();
						}
					}
				}
				
				break;
			default:
				break;
		}
	};
	
	function createHTML(attributes) {
		var tab = this;
		var $tabTitle = $('<div>', {
			'class': 'b-product-configurator-filters-btn'
		}).text(app.resources.get('CONF.' + tab.id.toUpperCase()));
		
		$tabTitle.on('click', function() {
			app.configurator.attributeTabsMgr.activateTab(tab.id);
			
			return false;
		});
		
		var $tabBody = $('<div>', {
			'class': 'b-product-configurator-filters-tabs-tab h-hidden ' + tab.id
		});
		
		var availableValues = app.configurator.attributeTabsMgr.getAvailableAttributeValues(tab.id);
		for (var i = 0, len = attributes.length; i < len; i++) {
			var attribute = new app.configurator.Attribute(tab, attributes[i]);
			tab.attributes.push(attribute);
			
			if (availableValues.indexOf(attribute.getId()) === -1) {
				attribute.disable();
			}
			
			$tabBody.append(attribute.getHTML());
		}
		
		return {
			$title: $tabTitle,
			$body: $tabBody
		};
	}
	
	app.configurator = app.configurator || {};
	app.configurator.AttributeTab = AttributeTab;
})((window.app = window.app || {}), jQuery);