( function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document : $(document),
			owlQuickviewSel : ".js-pdp_primary_content_quickview .js-quickview_slider",
			productVarReloadEvent : "product.variation.reloaded"
		};
	}
	
	function initializeQuickviewCache() {
		$cache.quickviewContainer = $('.js-pdp_primary_content_quickview');
		$cache.quickviewContainerSlider = $('.js-quickview_slider');
	}
	
	function initializeQuickviewContainer() {
		var container = $cache.quickviewContainer.find('.js-product_images_container'),
			firstImageContainer = $cache.quickviewContainer.find('img:eq(0)');
		if (container.length && firstImageContainer.length) {
			container.css({'height': container.width() * firstImageContainer.data('height') / firstImageContainer.data('width')});
		}
	}
	
	function initializeEvents() {
		$cache.document.on('quickview.beforeShow', function(){
			initializeQuickviewCache();
			initializeQuickviewContainer();
			var carousel = $cache.quickviewContainerSlider;
			app.owlcarousel.initCarousel(carousel);
			app.components.global.socials.init();
			$cache.quickviewContainer.find(".js-owl_carousel_quickview_nav").on('click', function(e) {
				var $this = $(this),
					action = $this.data('nav');
				if(action) {
					carousel.trigger(action + '.owl.carousel', [300]);
				}
			});
		});
		
		$cache.document.on($cache.productVarReloadEvent, function(event, data){
			if (data && data.mode === 'quickview') {
				var carousel = $($cache.owlQuickviewSel);
				initializeQuickviewCache();
				initializeQuickviewContainer();
				app.owlcarousel.initCarousel(carousel);
			};
		});
	}
	
	app.components = app.components || {};
	app.components.quickview = app.components.quickview || {};
	app.components.quickview.custom = {

		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);