;(function(app) {
	function TabFactory() {}
	
	TabFactory.create = function(tabType, id, config) {
		switch(tabType) {
			case 'attribute':
				return new app.configurator.AttributeTab(id, config);
			default:
				throw new Error('There is no constructor for tab of type: ' + tabType);
		}
	};
	
	app.configurator = app.configurator || {};
	app.configurator.tabFactory = {
		create: function(tabType, id, config) {
			return TabFactory.create(tabType, id, config);
		}
	};
})((window.app = window.app || {}));
