(function (app, $) {

	var $cache;

	function initializeCache() {
		$cache = {
			document: $(document),
			cartTableHeaderSel: '.js-cart_table-header',
			cvnTipButton: $('.js-cvn_tip-button'),
			cvnTipCloseButton: $('.js-cvn_tip-close_button'),
			cvnTipContent: $('.js-cvn_tip-content'),
			cvnTipShownClass: 'm-shown',
			taxInfoButton: $('.js-sale-tax-icon-custom'),
			taxInfoCloseButton: $('.js-sale-tax-info-close_button'),
			taxInfoContent: $('.js-sale-tax-content'),
			taxInfoHiddenClass: 'h-hidden'
		};
	}

	function initializeEvents(){
		//Add sticky header for product section on checkout
		app.device.isMobileView() || app.util.fixElement($cache.cartTableHeaderSel);
		initCvnTipPopup();
		initTaxInfoPopup();
	}

	function initCvnTipPopup() {
		$cache.cvnTipButton.on('click', function(openingEvent) {
			openingEvent.stopPropagation();

			if (!$cache.cvnTipContent.hasClass($cache.cvnTipShownClass)) {
				$cache.cvnTipContent.addClass($cache.cvnTipShownClass);

				$cache.document.on('click.CvnTipInfo', function(closingEvent) {
					if ($(closingEvent.target).is($cache.cvnTipCloseButton) || !$cache.cvnTipContent.has(closingEvent.target).length) {
						$cache.cvnTipContent.removeClass($cache.cvnTipShownClass);
						$cache.document.off('click.CvnTipInfo');
					}
				});
			} else {
				$cache.cvnTipContent.removeClass($cache.cvnTipShownClass);
				$cache.document.off('click.CvnTipInfo');
			}
		});
	}

	function initTaxInfoPopup() {
		$cache.taxInfoButton.on('click', function(openingEvent) {
			openingEvent.stopPropagation();

			if ($cache.taxInfoContent.hasClass($cache.taxInfoHiddenClass)) {
				$cache.taxInfoContent.removeClass($cache.taxInfoHiddenClass);

				$cache.document.on('click.taxInfo', function(closingEvent) {
					if ($(closingEvent.target).is($cache.taxInfoCloseButton) || !$cache.taxInfoContent.has(closingEvent.target).length) {
						$cache.taxInfoContent.addClass($cache.taxInfoHiddenClass);
						$cache.document.off('click.taxInfo');
					}
				});
			} else {
				$cache.taxInfoContent.addClass($cache.taxInfoHiddenClass);
				$cache.document.off('click.taxInfo');
			}
		});
	}

	app.components = app.components || {};
	app.components.checkout = app.components.checkout || {};
	app.components.checkout.custom = {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));