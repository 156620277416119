;(function(app, $) {
	var timeout;
	
	function AttributeTabsMgr() {
		this.tabs = [];
		this.activeTab = null;
		this.products = [];
		this.currentProduct = {};
		this.html = createHTML.call(this);
		this.isNotificationMessaggesProcessingBlocked = false;
		this.selectedAttribute = null;
		this.unselectedAttributes = [];
	}
	
	AttributeTabsMgr.prototype.notify = function(data) {
		if (!this.isNotificationMessaggesProcessingBlocked) {
			switch(data.message) {
				case 'attribute.selected':
					if (!this.selectedAttribute) {
						this.selectedAttribute = data.attribute;
					}
					
					if (this.unselectedAttributes.length === 1 && data.tab.getId() === this.unselectedAttributes[0].getTab().getId()) {
						var applicableProduct = getApplicableProducts.call(this)[0];
						
						if (applicableProduct) {
							clearTimeout(timeout);
							$(document).trigger('configurator.productSelected', [applicableProduct]);
							this.selectedAttribute = null;
							this.unselectedAttributes = [];
							this.currentProduct = applicableProduct;
						}
					}
					
					removeSelectedFromUnselectedAttributes.call(this, data);
					break;
				case 'attribute.unselected':
					this.unselectedAttributes.push(data.attribute);
					
					this.currentProduct = {};
					clearTimeout(timeout);
					timeout = setTimeout(function(tabManagerContext) {
						tabManagerContext.selectFirstApplicableProduct();
						var unselectedAttributesExceptSelectedTab = tabManagerContext.unselectedAttributes.filter(function(attribute) {
							return attribute.getTab().getId() !== tabManagerContext.selectedAttribute.getTab().getId();
						}).map(function(attribute) {
							return attribute.getTab().getId();
						});
						$(document).trigger('configurator.productUnselected', [unselectedAttributesExceptSelectedTab, tabManagerContext.selectedAttribute.getTab().getId()]);
						tabManagerContext.selectedAttribute = null;
						tabManagerContext.unselectedAttributes = [];
					}, 0, this);
					
					break;
				case 'attribute.unavailable':
					$(document).trigger('configurator.attributeUnavailable');
					break;
				default:
					break;
			}
		}
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			this.tabs[i].update(data);
		}
	};
	
	AttributeTabsMgr.prototype.init = function(products, tabsConfig) {
		this.products = products;
		
		for (var tabConfig in tabsConfig) {
			addTab.call(this, app.configurator.tabFactory.create('attribute', tabConfig, tabsConfig[tabConfig]));
		}
	};
	
	AttributeTabsMgr.prototype.getTab = function(id) {
		return this.tabs.filter(function(tab) {
			return tab.getId() === id;
		})[0];
	};
	
	AttributeTabsMgr.prototype.getHTML = function() {
		return this.html;
	};
	
	AttributeTabsMgr.prototype.activateTab = function(tabId) {
		var tab = this.getTab(tabId);
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			this.tabs[i].hide();
		}
		
		this.activeTab = tab;
		tab.show();
	};
	
	AttributeTabsMgr.prototype.getAvailableAttributeValues = function(tabId) {
		var applicableProducts = getApplicableProducts.call(this, tabId);
		
		var uniqueAttributeValues = applicableProducts.map(function(product) {
			return product[tabId];
		}).filter(function(value, index, self) {
			return self.indexOf(value) === index;
		});
		
		return uniqueAttributeValues;
	};
	
	AttributeTabsMgr.prototype.getSelectedAttributes = function() {
		var selectedAttributes = [];
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			var tab = this.tabs[i];
			var selectedAttribute = tab.getSelectedAttribute();
			
			if (selectedAttribute) {
				selectedAttributes.push({
					id: tab.getId(),
					value: selectedAttribute.getId()
				});
			}
		}
		
		return selectedAttributes;
	};
	
	AttributeTabsMgr.prototype.selectProduct = function(id) {
		if (id !== this.currentProduct.variantID) {
			var product = this.products.filter(function(product) {
				return product.variantID === id;
			})[0];
			
			if (product && product.available) {
				selectProduct.call(this, product);
				
				var mainTab = this.tabs.filter(function(tab) {
					return tab.isMain();
				})[0];
				
				if (mainTab) {
					this.activateTab(mainTab.getId());
				}
			}
		}
	};
	
	AttributeTabsMgr.prototype.selectFirstApplicableProduct = function() {
		var applicableProducts = getApplicableProducts.call(this);
		var lastUnselectedAttributes = [];
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			var lastUnselectedAttribute = this.tabs[i].getLastUnselectedAttribute();
			if (lastUnselectedAttribute) {
				lastUnselectedAttributes.push(lastUnselectedAttribute);
			}
		}
		
		var products = getProductsAccordingToEachAttribute(applicableProducts, lastUnselectedAttributes);
		var productToSelect = products[0] || applicableProducts[0];
		
		selectProduct.call(this, productToSelect);
	};
	
	function addTab(tab) {
		this.tabs.push(tab);
		
		this.html.$titleSection.append(tab.getHTML().$title);
		this.html.$bodySection.append(tab.getHTML().$body);
		
		if (this.tabs.length === 1) {
			this.activateTab(tab.getId());
		}
	}
	
	function selectProduct(product) {
		this.isNotificationMessaggesProcessingBlocked = true;
		this.currentProduct = {};
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			this.tabs[i].unselectSelectedAttribute();
		}
		
		this.isNotificationMessaggesProcessingBlocked = false;
		
		for (var i = 0, len = this.tabs.length; i < len; i++) {
			var tab = this.tabs[i];
			tab.selectAttribute(product[tab.getId()]);
		}
		
		this.currentProduct = product;
		$(document).trigger('configurator.productSelected', [product]);
	}
	
	function getApplicableProducts(tabId) {
		var selectedAttributesExcludingCurrentTab = this.getSelectedAttributes().filter(function(selectedAttribute) {
			return selectedAttribute.id !== tabId;
		});
		
		return this.products.filter(function(product) {
			if (!product.available) {
				return false;
			}
			
			for (var i = 0, len = this.length; i < len; i++) {
				var selectedAttribute = this[i];
				if (product[selectedAttribute.id] !== selectedAttribute.value) {
					return false;
				}
			}
			
			return true;
		}, selectedAttributesExcludingCurrentTab);
	}
	
	function getProductsAccordingToEachAttribute(products, attributes) {
		return products.filter(function(product) {
			for (var i = 0, len = this.length; i < len; i++) {
				var attribute = this[i];
				
				if (product[attribute.getTab().getId()] === attribute.getId()) {
					return true;
				}
			}
			
			return false;
		}, attributes);
	}
	
	function removeSelectedFromUnselectedAttributes(data) {
		this.unselectedAttributes = this.unselectedAttributes.filter(function(attribute) {
			return attribute !== data.attribute;
		});
	}
	
	function createHTML() {
		var $titleSection = $('<div>', {
			'class': 'b-product-configurator-filters-wrap'
		});
		var $bodySection = $('<div>', {
			'class': 'b-product-configurator-filters-tabs'
		});
		
		return {
			$titleSection: $titleSection,
			$bodySection: $bodySection
		};
	}
	
	app.configurator = app.configurator || {};
	app.configurator.attributeTabsMgr = new AttributeTabsMgr();
})((window.app = window.app || {}), jQuery);