;(function(app, $) {
	function Attribute(tab, config) {
		this.tab = tab;
		this.id = config.ID;
		this.name = config.title;
		this.iconImage = config.iconImage;
		this.hex = config.hex;
		this.selected = false;
		this.disabled = false;
		this.$html = createHTML.call(this);
	}
	
	Attribute.prototype.getTab = function() {
		return this.tab;
	};
	
	Attribute.prototype.getId = function() {
		return this.id;
	};
	
	Attribute.prototype.getName = function() {
		return this.name;
	};
	
	Attribute.prototype.getHTML = function() {
		return this.$html;
	};
	
	Attribute.prototype.isSelected = function() {
		return this.selected;
	};
	
	Attribute.prototype.select = function() {
		if (!this.disabled && !this.selected) {
			this.selected = true;
			this.$html.addClass('active');
			
			this.tab.notify({
				'message': 'attribute.selected',
				'attribute': this
			});
		}
		else if ( this.disabled ) {
			this.tab.notify({
				'message': 'attribute.unavailable'
			});
		}
	};
	
	Attribute.prototype.unselect = function() {
		if (this.selected) {
			this.selected = false;
			this.$html.removeClass('active');
			
			this.tab.notify({
				'message': 'attribute.unselected',
				'attribute': this
			});
		}
	};
	
	Attribute.prototype.enable = function() {
		if (this.disabled) {
			this.disabled = false;
			this.$html.removeClass('disabled');
		}
	};
	
	Attribute.prototype.disable = function() {
		if (!this.disabled) {
			this.disabled = true;
			this.$html.addClass('disabled');
			
			if (this.selected) {
				this.unselect();
			}
		}
	};
	
	function createHTML() {
		var attribute = this;
		
		var $attribute = $('<div>', {
			'class': 'js-product-configurator-attribute b-product-configurator-filters-filter'
		});
		
		$attribute.on('click', function() {
			attribute.select();
			
			return false;
		});
		
		var $text = $('<span>', {
			'class': 'tab-text'
		}).text(attribute.name);
		
		$attribute.append($text);
		
		var $icon = $('<div>', {
			'class': 'tab-icon'
		});
		
		if (attribute.iconImage) {
			$icon.css('background-image','url(' + attribute.iconImage + ')');
		}
		else if (attribute.hex) {
			$icon.css('background-color', attribute.hex);
		}
		
		$attribute.append($icon);
		
		return $attribute;
	}
	
	app.configurator = app.configurator || {};
	app.configurator.Attribute = Attribute;
})((window.app = window.app || {}), jQuery);