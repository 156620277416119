(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			footer: $('footer'),
			socialLinkFancyboxOpen: $('.js-social_link_fancybox_open'),
			hiddenClass: 'h-hidden'
		};
	}

	function initializeEvents() {
		$cache.socialLinkFancyboxOpen.on('click', function() {
			var fancyboxContent = $(this).data('content');
			if (fancyboxContent) {
				app.fancybox.open($(fancyboxContent).html(), {
					wrapCSS: 'fancybox_social-links b-modal-wrapper',
					maxWidth: 640
				});
			}
		});
	}

	/** app.components.global.footer public object */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.footer = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
