( function(app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			document : $(document),
			video2x2: $(".l-special_plp .l-special_plp-resp_container.video2x2"),
			specPlpBot: $('.l-special_plp-bottom'),
			plpSpecBannerTextSel: '.js-plp-intro_text'
		};
	}
	
	function initializeScrollBar() {
		// currently could be cached because all <main role="main"> tag's content is replaced by AJAX
		// @TODO: refactor PLP AJAX update part
		$('.js-refinements').find('.js-refinement>ul').scrollbar({
			ignoreMobile: false,
			disableBodyScroll: true
		});
	}
	
	function initializeEvents() {
		$cache.document.on('refinements-update', function(e) {
			app.components.search.filters.init();
			initializeScrollBar();
			initSpecPlpBannerTextShow();
		});
		initSpecPlpVideoBlock();
	}
	
	function initSpecPlpVideoBlock(){
		//change video/frame block height due to design impossibility
	    $cache.video2x2.height(function(e,val){
	        return val*2;
	    });
	    //change video/frame block height in case of window resize
		$(window).resize(function() {
			$cache.video2x2.height($cache.specPlpBot.height()*2);
		});
	}

	function initSpecPlpBannerTextShow() {
		var $textBanner = $($cache.plpSpecBannerTextSel);

		$textBanner.moreLines({
			linecount: 0,
			baseclass: 'l-special_plp-banner',
			basejsclass: 'js-plp-intro',
			classspecific: '_text',
			buttontxtmore: app.resources.READ_MORE_LINE,
			buttontxtless: app.resources.READ_LESS_LINE,
			animationspeed: 300
		});
	}

	/**
	* @namespace app.components.search.custom public object
	**/
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.custom = {
		init : function() {
			initializeCache();
			initializeScrollBar();
			initializeEvents();
		}
	};
	
}(window.app = window.app || {}, jQuery));