/**
 * @class app.components.product.configurator.utils
 */
(function(app, $) {
	var $cache,
		_CONSTANTS = {
			step1Types: ['listItem', 'customizationPliID']
		};
	
	/**
	 * @private
	 * @function
	 * @description Initializes the cache on the product detail page.
	 */
	function initializeCache(container) {
		$cache = {
			document : $(document),
			html : $('html'),
			body: $('body'),
			window: $(window),
			header : $('header'),
			container : container,
			currentStep : 1,
			brokenConfigurationsChecked: false,
			notifyMeAutoclose: app.util.getConfig('product.notifyMe.autoClose', 0),
			configuratorStep1Wrapper: $('.js-product-configurator-step-1'),
			configuratorStep1Loader: $('.js-product-configurator-step-1 .loader'),
			configuratorStep2Wrapper: $('.js-product-configurator-step-2'),
			configuratorStep2Loader: $('.js-product-configurator-step-2 .loader'),
			configuratorStep3Wrapper: $('.js-product-configurator-step-3'),
			configuratorPrimaryContent: $('.js-pdp_primary_content.configurator'),
			configuratorPriceInput: $('.js-product-configurator-price-value'),
			configuratorCustomProductsInput: $('.js-product-configurator_customProducts'),
			configuratorProductIdInput: $('.js-product-configurator-product_id'),
			configuratorCompare: $('.js-product-configurator-compare_item'),
			configuratorSelectedSize: $('.js-product-configurator-selected_size'),
			configuratorStep1Controls: $('.js-step1-controls'),
			configuratorStep10Controls: $('.js-step10-controls'),
			configuratorStep1Tabs: $('.js-product-configurator-step-1 .js-product_tabs'),
			configuratorTopbarItems: $(".js-configurator-items"),
			saveGalleryBtn : $(".js-configurator-savegallery"),
			needToReinitCarousels: false,
			compareCarouselConfig: {
				items: 3,
				nav: true,
				wrapCSS: "l-compare-designs_popup",
				margin: 2
			},
			compareListCarouselConfig: {
				items: 3,
				nav: true,
				margin: 2,
				loop: false,
				rewind: false
			},
			compareListCarouselConfigMobile: {
				items: 4,
				nav: true,
				margin: 2,
				loop: false,
				rewind: false
			},
			//$cache.selectors
			selectors : {
				addtoWishList : '.js-add_to_wishlist, .js-hp_wishlist-add',
				addToWishListPDP: '.js-add_to_wishlist[data-pid]',
				addToCart : '.js-add_to_cart',
				configuratorStart1Btn: '.js-configurator-start-1',
				configuratorStart2Btn: '.js-configurator-start-2',
				configuratorStart2BtnActive: '.js-configurator-start-2.js-start-2-active',
				configuratorCloseBtn: '.js-configurator-close',
				configuratorEditDesignBtn: '.js-configurator-editdesign',
				configuratorStartNewBtn: '.js-configurator-startnew',
				configuratorCompareList: '.js-product-configurator-compare_list',
				configuratorItemsWrapper: '.js-product-configurator-items-wrapper',
				configuratorItemsContent: '.js-product-configurator-items-content',
				configuratorCompareLink: '.js-product-configurator-compare_link',
				productPriceStandart: '.js-product_price-standard:not([data-saved-customization="true"])',
				productVariations: '.js-product_variations',
				configuratorVariationsList: '.js-swatches.b-swatches_size',
				selectedVariation: '.js-swatches_size-item-selected',
				owlCarouselGeneral: '.js-owl_carousel',
				owlCarouselItem: '.b-owl_carousel-item',
				configuratorTopbarItemHasPatch: ".has-patch",
				configuratorTopbarItemActive: ".active",
				configuratorTabsLoader: '.js-configurator-tabs-loader',
				configuratorTabsWrapper: '.js-product-attributes-tabs',
				sideContainerJsSel : ".js-product-configurator-compare-carousel",
				notificationsContainer: '.js-product-configurator-notifications-block'
			},
			//$cache.classes
			classes : {
				configuratorStart2BtnActiveCls: 'js-start-2-active',
				configuratorCompareItem: 'b-product-configurator-compare_item',
				configuratorComparePatch: 'b-product-configurator-compare_patch',
				configuratorCompareImg: 'b-product-configurator-compare_img',
				productAddToCartSubmitAdded : "b-product_add_to_cart-submit--added",
				productAddToWishlistSubmitAdded: "b-add_to_wishlist--added",
				containerCssClass : "b-product-configurator-compare",
				titleContainer : "b-product-configurator-compare-title",
				itemsContainerCssClass : "b-product-configurator-compare-items",
				itemsCssClass : "b-product-configurator-compare-items-item",
				itemsControllerCssClass : "b-product-configurator-compare-items-controller",
				sidesContainerCssClass : "b-product-configurator-compare-items-item-sides",
				sidesControllerCssClass : "b-product-configurator-compare-items-item-sides-controller",
				sideContainerCssClass : "b-product-configurator-compare-items-item-sides-side b-product-configurator-compare-carousel js-product-configurator-compare-carousel",
				configurationContainerCssClass : "b-product-configurator-compare-items-item-sides-side-configuration b-product-configurator-compare-carousel-item",
				imagesWrapperCssClass : "b-product-configurator-compare-items-item-sides-side-configuration-wrapper",
				sideImageCssClass : "b-product-configurator-compare-side-image",
				patchImageCssClass : "b-product-configurator-compare-patch-image",
				chooserContainerCssClass : "b-product-configurator-compare-chooser",
				compareListCarouselClass: "js-compare-list-carousel",
				compareListCarouselScrolledClass: "m-carousel-scrolled",
				configurationCompareChooseBtnCLass: 'b-product-configurator-compare-chooser_choose-btn',
				configurationCompareRemoveBtnCLass: 'b-product-configurator-compare-chooser_remove-btn',
				configuratorComparePopupClass: 'l-configurator-compare_popup',
				variationSwatchCssClass: 'b-swatches_size-item-selected',
				emptyVariationSwatchCssClass: 'emptyswatch',
				hiddenClass : 'h-hidden',
				invisibleClass: 'h-invisible',
				noScroll: 'no-scroll',
				active: 'active',
				addedToCart: 'addedToCart',
				loaderClass: 'ui-tabs',
				addedToWishlist: 'addedToWishlist',
				multiproductInited: 'm-multiproduct-inited',
				carousel: {
					dots: 'b-owl_carousel-nav_dots',
					dot: 'b-owl_carousel-nav_dot',
					itemElement: 'b-product-configurator-items-shoe-side',
					itemElementImage: 'b-product-configurator-item_side',
					itemVideo: 'b-product-configurator-items-shoe-video js-configurator_carousel-video',
					itemVideoImage: 'b-product-configurator_carousel-video-preview h-hidden',
					videoDot: 'video',
					loading: 'owl-loading',
					itemWrapper: 'b-product-configurator-items-shoe-side-wrapper'
				}
			}
		};
	}

	function initializeDOM() {
		$cache.variantsData = app.resources.CONF.VARIANTS;
		$cache.masterId = $cache.variantsData.masterId;
		$cache.currentProductId = $cache.variantsData.currentProductId;
		$cache.localStorageKey = 'pdp_configurator' + $cache.masterId;
		$cache.compareKey = 'pdp_configurator_compare' + $cache.masterId;
		$cache.stored = [];
		$cache.resizingFactors = app.resources.CONF.PREFERENCES;
		$cache.disSizeResizingFactor = getDisSizeResizingFactor();
		$cache.realMasterId = ($cache.variantsData.realMasterId) ? $cache.variantsData.realMasterId : $cache.variantsData.masterId;
		$cache.customProductsConfigData = {};
		$cache.customProductsConfigData[$cache.realMasterId] = $.extend({}, $cache.variantsData);
		app.progress.setAditionalClass('step1','b-product-configurator_overlay');
		app.notificationsMgr.setContainer($($cache.selectors.notificationsContainer));
		if ( $('.js-product-configurator-step-2').hasClass('l-product-configurator-filterable') ) {
			$cache.configuratorSelectedSize.addClass( $cache.classes.hiddenClass );
			app.progress.setAditionalClass('step2', 'b-product-configurator_overlay no-header');
			$cache.multiproductConfigurator = {
				inited: false,
				currentProductConfig: {},
				defaultVariantID: false,
				mainImage: $cache.configuratorStep2Wrapper.find('img.js-primary_image'),
				isLoading: false
			};
		}
		monitorCarousels();
		
		if(localStorage.getItem($cache.compareKey) !== null){
			try{
				$cache.stored = JSON.parse(localStorage.getItem($cache.compareKey));
				
				for (var i = 0, len = $cache.stored.length; i < len; i++) {
					$cache.stored[i][0].addedToCart = false;
					$cache.stored[i][0].addedToWishlist = false;
				}
				
				localStorage.setItem($cache.compareKey, JSON.stringify($cache.stored));
			}
			catch (e) {
			}
		}
	}
	
	function monitorCarousels() {
		$.each($($cache.selectors.owlCarouselGeneral + '[data-item-side]'), function(i, carousel) {
			$(carousel).on('refreshed.owl.carousel', function() {
				if ($cache.selectedPatch > -1 && $cache.variation && $cache.currentStep === 10 ) {
					updateMainImage($cache.selectedPatch);
				}
				else if ( $cache.currentStep !== 10 ) {
					$cache.needToReinitCarousels = true;
				}
			});
		});
	}

	function getDisSizeResizingFactor() {
		var disSizeResizingFactor = null;
		if ( $cache.variantsData['disResizingFactor'] ) {
			// get RF on product level
			disSizeResizingFactor = $cache.variantsData['disResizingFactor'];
		}
		else if ( $cache.resizingFactors['disResizingFactor'] ) {
			// get RF on global level ( site prefs )
			disSizeResizingFactor = $cache.resizingFactors['disResizingFactor'];
		}
		return disSizeResizingFactor;
	}
	
	function isSameExternalPatch(externalPatch, restPatches, excludeArr) {
		restPatches = restPatches || $cache.stored;
		excludeArr = excludeArr || ['uuid', 'price', 'scaleInPercents'];
		if (!externalPatch || !restPatches || !restPatches.length) {
			return {
				isEqual: false
			};
		}
		
		for (var i = 0; i < restPatches.length; i++) {
			var currentRestPatch = restPatches[i];
			var equalityArr = [];
			if (currentRestPatch.length === externalPatch.length) {
				for (var j = 0; j < externalPatch.length; j++) {
					var currentExternalPatchItem = externalPatch[j];
					var currentRestPatchItem = currentRestPatch[j];
					equalityArr.push(currentRestPatchItem
							&& app.util.deepObjectCompare(currentExternalPatchItem, currentRestPatchItem, excludeArr));
				}
			}
			if (equalityArr.length && equalityArr.every(function(val) { return val; })) {
				return {
					isEqual: true,
					index: i
				};
			}
		}
		
		return {
			isEqual: false
		};
	}
	
	function updateMainImage(patch_id) {
		var container = $($cache.selectors.configuratorCompareList);
		var compareElement = container.find('[data-id='+patch_id+']');
		$cache.selectedPatch = patch_id;
		// pid should be in first patch item
		if ( $cache.stored[patch_id][0].pid ) {
			updateCurrentVariantID( $cache.stored[patch_id][0].pid );
		}
		else {
			updateCurrentVariantID( $cache.stored[patch_id][0].size );
		}

		if ( $cache.variation.realMasterId ) {
			replaceMainImageCarousel( $cache.variation.realMasterId ).then(applyPatches);
		}
		else {
			applyPatches();
		}

		container.find('.'+$cache.classes.configuratorCompareItem).removeClass($cache.classes.active);
		compareElement.addClass($cache.classes.active);

		updateCTAButtons(patch_id);

		return false;
	}
	/**
	 * @private
	 * @function
	 * @description Initializes events on the customizable product detail page
	 */
	function initializeConfiguratorEvents() {

		$cache.document.on("product.added", function() {
			var compareItemId = $cache.configuratorStep1Wrapper.find('.' + $cache.classes.active + '.' + $cache.classes.configuratorCompareItem).data("id");
			$cache.stored[compareItemId][0].addedToCart = true;
			localStorage.setItem($cache.compareKey, JSON.stringify($cache.stored));

			updateCTAButtons(compareItemId);
		});
		
		$cache.document.on('wishlist.added', function() {
			if ( $cache.multiproductConfigurator && $cache.multiproductConfigurator.isLoading ) {
				$cache.document.on('configurator.productData.loaded', function(){
					updateWishlistButton();
				});
			}
			else {
				updateWishlistButton();
			}
		});
		
		$cache.document.on('configuratorCompare.init', function(){
			Compare();
		});
		
		$cache.document.on('configuratorCompare.finish', function(e, data){
			updateMainImage(data);
			return false;
		});
		
		$cache.document.on('configuratorCompare.remove', function(e, data){
			removeStoredConfiguration(data);
			if ($cache.stored.length > 1) {
				Compare();
			}
			if (+data === $cache.selectedPatch) {
				$cache.selectedPatch = 0;
			} else if (+data < $cache.selectedPatch) {
				$cache.selectedPatch--;
			}
			initSavedDesigns($cache.selectedVariation);
			compareButtonShower();
		});
		
		$cache.document.on('configuratorStep1.init', function(e, externalPatch){
			if ($cache.stored.length > 0) {
				var productsMasterIDs = [];
				$.each($cache.stored, function(i, patches){
					if ( productsMasterIDs.indexOf(patches[0].masterPid) === -1 ) {
						productsMasterIDs.push(patches[0].masterPid);
					}
				});
				getCustomProductConfigurationData( productsMasterIDs, $cache.realMasterId )
					.then(function(){
						checkBrokenConfigurations();
						configuratorStep1Show(externalPatch);
					}).fail(function(){
						app.resources.get('GENERAL_ERROR');
					});
			}
			else {
				configuratorStep1Show(externalPatch);
			}
		});
		
		$cache.document.on('configuratorStep1.finish', function(){
			$cache.configuratorStep1Wrapper.addClass($cache.classes.hiddenClass);
			if ($cache.stored.length === 0) {
				$cache.configuratorStep1Controls.addClass($cache.classes.hiddenClass);
			} else {
				$cache.configuratorStep10Controls.addClass($cache.classes.hiddenClass);
			}
		});
		
		$cache.document.on('configuratorStep2.init', function(){
			var $configuratorStart2Btn = $($cache.selectors.configuratorStart2Btn);
			var selectedLink = $('.'+$cache.classes.variationSwatchCssClass);
			selectedLink.removeClass($cache.classes.variationSwatchCssClass).addClass($cache.classes.emptyVariationSwatchCssClass);
			selectedLink.find('a').one('click', function(){
				selectedLink.addClass($cache.classes.variationSwatchCssClass).removeClass($cache.classes.emptyVariationSwatchCssClass);
				$configuratorStart2Btn.text($configuratorStart2Btn.data("item-selected-text")).addClass($cache.classes.configuratorStart2BtnActiveCls).prop("disabled", false);
				return false;
			});
			$configuratorStart2Btn.text($configuratorStart2Btn.data("sizeselecttxt")).removeClass($cache.classes.configuratorStart2BtnActiveCls).prop("disabled", true);

			$cache.currentStep = 2;
			configuratorStep2Show(true);
		});
		
		$cache.document.on('configuratorStep2.changed', function(){
			var $configuratorStart2Btn = $($cache.selectors.configuratorStart2Btn);

			if ( $cache.configuratorStep2Wrapper.find($cache.selectors.selectedVariation).length > 0 || ( $cache.multiproductConfigurator && $cache.multiproductConfigurator.currentProductConfig ) ) {
				$configuratorStart2Btn.text($configuratorStart2Btn.data("item-selected-text")).addClass($cache.classes.configuratorStart2BtnActiveCls).prop("disabled", false);
			}
			else {
				$configuratorStart2Btn.text($configuratorStart2Btn.data("sizeselecttxt")).removeClass($cache.classes.configuratorStart2BtnActiveCls).prop("disabled", true);
			}

			var selectedVariation = $cache.configuratorStep2Wrapper.find($cache.selectors.productVariations).data('current');
			// GTM data
			app.trackerData.productSize = selectedVariation['size'] && selectedVariation['size']['displayValue'] || "";
			app.trackerData.productFabric = selectedVariation['fabric'] && selectedVariation['fabric']['displayValue'] || "";
			app.trackerData.productColor = selectedVariation['color'] && selectedVariation['color']['displayValue'] || "";
		});

		$cache.document.on('configuratorStep3.init', function() {
			$cache.currentStep = 3;
			$cache.configuratorStep3Wrapper.removeClass($cache.classes.hiddenClass);
			$cache.header.addClass($cache.classes.invisibleClass);
			$cache.html.addClass($cache.classes.noScroll);

			var params = {};
			if ( app.components.product.configurator.inited() && app.components.product.configurator.getRealMasterID() !== $cache.realMasterId ) {
				app.components.product.configurator.reset();
			}
			else {
				app.components.product.configurator.reInitVariantDataCache();
			}

			// update active paches if needed
			if ( Number.isInteger($cache.selectedPatch) ) {
				if ( app.components.product.configurator.inited() ) {
					app.components.product.configurator.setActivePatches( $cache.selectedPatch, $cache.stored[$cache.selectedPatch]);
				}
				else {
					app.components.product.configurator.setCompareID( $cache.selectedPatch );
					params.activePatches = $cache.stored[$cache.selectedPatch];
				}
			}
			else {
				app.components.product.configurator.setActivePatches();
			}
			if (!app.components.product.configurator.inited()) {
				if ($cache.variation) {
					params.currentProductId = $cache.variation.id;
				}
				$cache.document.trigger('configurator.init', [params]);
			} else {
				app.components.product.configurator.reinit($cache.variation);
			}
			compareButtonShower();
		});
		
		$cache.document.on('configuratorStep3.finish', function(){
			$cache.header.removeClass($cache.classes.invisibleClass);
			$cache.html.removeClass($cache.classes.noScroll);
			$cache.configuratorStep3Wrapper.addClass($cache.classes.hiddenClass);
			$cache.document.off('firsttabloaded');
			$cache.selectedPatch = false;
		});

		$cache.document.on('configuratorStep2.init changeVariation.changed', function() {
			var $configuratorVariationsList = $cache.configuratorStep2Wrapper.find($cache.selectors.configuratorVariationsList);

			if ($configuratorVariationsList.data('owlCarousel')) {
				$configuratorVariationsList.trigger('reload.single.owl');
			} else {
				app.owlcarousel.initCarousel($configuratorVariationsList);
			}
		});

		$cache.document.on('configuratorStep2.finish', function(){
			$cache.variantsData = app.resources.CONF.VARIANTS;
			var selectedVariation = $cache.configuratorStep2Wrapper.find($cache.selectors.productVariations).data('current');
			updateCurrentVariantID( !$.isEmptyObject(selectedVariation) ? selectedVariation.pid : null );
			configuratorStep2Show(false);
		});

		$cache.document.on('configurator.saved', function(){
			$cache.document.trigger('configuratorStep3.finish');
			$cache.document.trigger('configuratorStep1.init');
			compareButtonShower();
		});
		
		$cache.document.on('configurator.canceled', function(){
			$cache.document.trigger('configuratorStep3.finish');
			$cache.document.trigger('configuratorStep1.init');
		});
		
		$cache.document.on('configurator.restart', function(){
			$cache.document.trigger('configuratorStep3.finish');
			$cache.document.trigger('configuratorStep2.init');
		});

		$cache.document.on('click', $cache.selectors.configuratorCloseBtn, function(){
			$cache.document.trigger('configuratorStep2.finish');
			$cache.document.trigger('configuratorStep1.init');
		});

		$cache.document.on('click', $cache.selectors.configuratorStart1Btn, function(){
			$cache.document.trigger('configuratorStep1.finish');
			$cache.document.trigger('configuratorStep2.init');
			return false;
		});

		$cache.document.on('click', $cache.selectors.configuratorStart2BtnActive, function(){
			if ( $cache.realMasterId ) {
				getCustomProductConfigurationData( [ $cache.realMasterId ], $cache.realMasterId, true )
					.then(function(){
						startStep3();
					}).fail(function( message ){
						app.notificationsMgr.show('configurator', { text: message });
						$cache.multiproductConfigurator.currentProductConfig = null;
						$cache.document.trigger('configuratorStep2.changed');
					});
			}
			else {
				startStep3();
			}
		});
		
		$cache.document.on('click', $cache.selectors.configuratorStartNewBtn, function(){
			$cache.selectedPatch = false;
			$cache.document.trigger('configuratorStep1.finish');
			if ( $cache.multiproductConfigurator && $cache.multiproductConfigurator.inited ) {
				app.configurator.attributeTabsMgr.selectProduct($cache.multiproductConfigurator.defaultVariantID);
			}
			$cache.document.trigger('configuratorStep2.init');
			return false;
		});
		
		$cache.document.on('click', $cache.selectors.configuratorEditDesignBtn, function(){
			var selectedPatch = $cache.selectedPatch;
			localStorage.setItem($cache.localStorageKey, JSON.stringify($cache.stored[selectedPatch]));
			// backward compatibility
			if ( $cache.stored[selectedPatch][0].pid ) {
				updateCurrentVariantID( $cache.stored[selectedPatch][0].pid );
			}
			else {
				updateCurrentVariantID( $cache.stored[selectedPatch][0].size );
			}
			if ( $cache.multiproductConfigurator && $cache.multiproductConfigurator.inited ) {
				app.configurator.attributeTabsMgr.selectProduct($cache.stored[selectedPatch][0].pid);
			}
			$cache.document.trigger('configuratorStep1.finish');
			$cache.document.trigger('configuratorStep3.init');

			//TODO:PRCONF:V1 Workaround solution; To fix in scope of refactoring
			$cache.document.on('setActivePatchesLoop.calc', function(){
				$cache.configuratorTopbarItems.find($cache.selectors.configuratorTopbarItemActive).siblings($cache.selectors.configuratorTopbarItemHasPatch).trigger('click').siblings($cache.selectors.configuratorTopbarItemHasPatch).trigger('click');
			});

			return false;
		});

		$cache.document.on('configurator.changetype',function(e, type) {
			app.components.product.configurator.changeConfiguratorType( type );
		});
		
		//TODO:PRCONF:V1 Workaround solution; To fix in scope of refactoring
		$cache.document.on('setActivePatchesLoop.calc', function(){
			$cache.configuratorTopbarItems.find($cache.selectors.configuratorTopbarItemActive).siblings($cache.selectors.configuratorTopbarItemHasPatch).trigger('click').siblings($cache.selectors.configuratorTopbarItemHasPatch).trigger('click');
		});
		
		$cache.document.on('click', $cache.selectors.configuratorCompareLink, function(){
			$cache.document.trigger('configuratorCompare.init');
			return false;
		});

		$cache.saveGalleryBtn.on('click', function(e) {
			e.preventDefault();

			app.components.product.configurator.saveProductCustomization(function(response) {
				if('customizationID' in response){
					alert(response.customizationID);
				}
			}, $cache.configuratorCustomProductsInput.val(), {'toCustomObject': true});
		});

		if (app.device.isMobileView()) {
			$cache.document.on('changeVariation.changed', function() {
				var selectedIndex = $cache.configuratorStep2Wrapper.find($cache.selectors.owlCarouselItem)
					.index($cache.configuratorStep2Wrapper.find($cache.selectors.selectedVariation).parent());
				$cache.configuratorStep2Wrapper.find($cache.selectors.configuratorVariationsList)
					.trigger('to.owl.carousel', [selectedIndex - 2]);
			});
		}

		function configuratorStep2Show(show){
			$cache.header.toggleClass($cache.classes.invisibleClass, show);
			$cache.configuratorPrimaryContent.toggleClass($cache.classes.hiddenClass, show);
			$cache.configuratorStep2Wrapper.toggleClass($cache.classes.hiddenClass, !show);
			$cache.html.toggleClass($cache.classes.noScroll, show);

			if ( show && $cache.multiproductConfigurator ) {
				if ( !$cache.multiproductConfigurator.inited ) {
					initMultiproductSetup();
				}
				else {
					$cache.document.trigger('configuratorStep2.changed');
					hideLoader();
				}
			} else if ( show ) {
				hideLoader();
			}
		}

		function configuratorStep1Show(externalPatch) {
			var localStorageConfiguration = localStorage.getItem($cache.compareKey);
			var externalPatchExists = externalPatch && externalPatch.length;
			var patchInfo = {};
			
			if(localStorageConfiguration !== null){
				try {
					var parsedConfiguration = JSON.parse(localStorageConfiguration);
					var isNewItem = $cache.stored.length < parsedConfiguration.length;
					$cache.stored = parsedConfiguration;
					
					if (isNewItem) {
						$cache.selectedPatch = $cache.stored.length - 1;
					}
					
					patchInfo = isSameExternalPatch(externalPatch);
					
					if (externalPatchExists && !patchInfo.isEqual) {
						$cache.stored.push(externalPatch);
					}
				} catch (e) {}
			} else if (externalPatchExists) {
				$cache.stored = [externalPatch];
			}
			
			var defaultSizePatchIndex = externalPatchExists ?
				(patchInfo.index != null ? patchInfo.index : $cache.stored.length - 1) : 0;
			if ( !$cache.selectedPatch ) {
				$cache.selectedPatch = defaultSizePatchIndex;
			}
			$cache.configuratorStep1Wrapper.removeClass($cache.classes.hiddenClass);

			if ($cache.stored.length === 0) {
				hideLoader();
				$cache.currentStep = 1;
				$($cache.selectors.productPriceStandart).removeClass($cache.classes.invisibleClass);
				$cache.configuratorPrimaryContent.addClass($cache.classes.hiddenClass);
				$cache.configuratorStep1Controls.removeClass($cache.classes.hiddenClass);
				$cache.configuratorStep10Controls.addClass($cache.classes.hiddenClass);
				$($cache.selectors.configuratorCompareList).html('');
				$($cache.selectors.configuratorItemsContent).find('.'+$cache.classes.configuratorComparePatch).remove();
			} else {
				$cache.currentStep = 10;
				$cache.configuratorStep1Controls.addClass($cache.classes.hiddenClass);
				$cache.configuratorStep10Controls.removeClass($cache.classes.hiddenClass);
				compareButtonShower();
				initSavedDesigns(null, {
					externalPatchExists: externalPatchExists,
					patchInfo: patchInfo
				});
			}

			if ( $cache.needToReinitCarousels ) {
				$cache.needToReinitCarousels = false;
				$($cache.selectors.configuratorItemsWrapper).find($cache.selectors.configuratorItemsContent).trigger('reload.single.owl');
			}
		}

		initializeConfigurator();

	}

	function updateWishlistButton() {
		$cache.stored = JSON.parse(localStorage.getItem($cache.compareKey));
		for( var i = 0, len = $cache.stored.length; i < len; i++ ) {
			if ( $cache.stored[i][0].hasOwnProperty('wl_customizationID') ) {
				$cache.stored[i][0].addedToWishlist = true;
				delete $cache.stored[i][0]['wl_customizationID'];
			}
		}
		localStorage.setItem($cache.compareKey, JSON.stringify($cache.stored));

		var compareItemId = $cache.configuratorStep1Wrapper.find('.' + $cache.classes.active + '.' + $cache.classes.configuratorCompareItem).data("id");
		updateCTAButtons(compareItemId);
	}

	function initializeConfigurator() {
		if(!app.resources.CONF.CUSTOMIZATION_NOTAVAILABLE) {
			if(typeof app.resources.CONF.CUSTOMIZATION_JSON !== 'undefined' && app.resources.CONF.CUSTOMIZATION_JSON !== null){
				try {

					var pageUrl = app.util.removeParamsFromURL(window.location.href, ['customizationID','customizationPliID']);
					window.history.replaceState({}, document.title, pageUrl);

					var customizationObj = JSON.parse(app.resources.CONF.CUSTOMIZATION_JSON);
					var stepType = customizationObj.stepType;
					if (_CONSTANTS.step1Types.indexOf(stepType) !== -1) {
						$cache.document.trigger('configuratorStep1.init', [customizationObj.activePatchesIndexes]);
						app.resources.CONF.CUSTOMIZATION_JSON = null;
					} else if (app.resources.CONF.VARIANTS.type === 'bag') {
						$cache.document.trigger('configurator.changetype', [app.resources.CONF.VARIANTS.type]);
						$cache.document.trigger('configuratorStep1.finish');
						startStep3();
					} else {
						$cache.document.trigger('configuratorStep1.finish');
						$cache.document.trigger('configuratorStep2.init');
					}
				} catch(e) {
					$cache.document.trigger('configuratorStep1.init');
				}
			} else {
				$cache.document.trigger('configuratorStep1.init');
			}
		} else {
			$cache.document.trigger('configuratorStep1.init');
		}
	}

	function startStep3() {
		$cache.document.trigger('configuratorStep2.finish');
		if(typeof app.resources.CONF.CUSTOMIZATION_JSON !== 'undefined' && app.resources.CONF.CUSTOMIZATION_JSON !== null){
			try {
				var stored = JSON.parse(app.resources.CONF.CUSTOMIZATION_JSON).activePatchesIndexes;
				$.each(stored, function(i, item){
					item.pid = $cache.selectedVariation || item.pid;
				});
				localStorage.setItem($cache.localStorageKey, JSON.stringify(stored));
				app.resources.CONF.CUSTOMIZATION_JSON = null;
			} catch(e) {
				localStorage.removeItem($cache.localStorageKey);
			}
		} else {
			localStorage.removeItem($cache.localStorageKey);
		}
		$cache.document.trigger('configuratorStep3.init');
		return false;
	}

	function updateCurrentVariantID( variantID ) {
		if ( !variantID ) {
			$cache.selectedVariation = null;
			$cache.variation = null;
			$cache.currentProductId = null;
			return;
		}
		$cache.variation = $cache.variantsData.variantsData.find(function( obj ) {
			return obj.id === variantID;
		});
		// backward compatibility
		if ( !$cache.variation ) {
			$cache.variation = $cache.variantsData.variantsData.find(function( obj ) {
				return obj.size === variantID;
			});
		}
		if ( !$cache.variation ) {
			$cache.variation = $cache.variantsData.variantsData.find(function( obj ) {
				return obj.id === $cache.variantsData.defaultVariantId;
			});
		}
		$cache.selectedVariation = $cache.variation.id;
		$cache.realMasterId = ($cache.variation.realMasterId) ? $cache.variation.realMasterId : $cache.masterId;
		$cache.variantsData.customizingProducts = $cache.customProductsConfigData[$cache.realMasterId].customizingProducts;
		$cache.variantsData.fontColor = $cache.customProductsConfigData[$cache.realMasterId].fontColor;
		$cache.currentProductId = $cache.selectedVariation;
		if ( $cache.multiproductConfigurator && $cache.multiproductConfigurator.inited ) {
			app.configurator.attributeTabsMgr.selectProduct( $cache.selectedVariation );
		}
	}

	function getSizeResizingFactor(dimension, size) {
		if (typeof $cache.resizingFactors[size] !== "undefined") {
			return calcResizeFactor(dimension, $cache.resizingFactors[size]);
		} else {
			return dimension;
		}
	};
	
	function calcResizeFactor(dimension, resFactor){
		return dimension * (resFactor / 100);
	};
	
	function calcFinalResizeFactors(dimension, size, resFactor1, resFactor2) {
		var ret = getSizeResizingFactor(dimension, size);
		ret = calcResizeFactor(ret, resFactor1);
		ret = calcResizeFactor(ret, resFactor2);
		return Math.round(ret);
	};
	
	function imagesLoadFunction(container){
		container.data('images_count', container.data('images_count')-1);
		if(container.data('images_count') === 0){
			container.trigger('loaded');
		}
	};
	
	function getImitationImageSizes($img) {
		var deferred = $.Deferred();
		var $carouselBlock = $img.closest($cache.selectors.configuratorItemsWrapper + ',' + $cache.selectors.sideContainerJsSel
			+ ',' + $cache.selectors.configuratorCompareList);
		
		if ($carouselBlock.length && !$carouselBlock.is(':visible')) {
			var boardKey = $img.parent().data('boardkey'),
				$clone = $carouselBlock
					.clone()
					.css({
						display: 'block',
						position: 'absolute',
						top: '-10000px',
						left: '-10000px',
						padding: '0',
						margin: '0'
					})
					.appendTo($carouselBlock.parent()),
				$cloneImg = $clone.find('[data-boardkey="' + boardKey + '"]').children('img').eq(0);
			
			app.components.global.images.imageLoaded($cloneImg).then(function() {
				var imageWidth = $cloneImg.length ? $cloneImg.width() : 0;
				var imageHeight = $cloneImg.length ? $cloneImg.height() : 0;
				$clone.remove();
				deferred.resolve(imageWidth, imageHeight);
			});
		}
		else {
			deferred.resolve($img.width(), $img.height());
		}
		
		return deferred.promise();
	}
	
	/* Append patches to image */
	function SetPatches(container, img, patches, boardKey) {
		var items = [];
		$.each(patches, function(j, item){
			if (item.boardKey === boardKey) {
				var patch = app.resources.CONF.PATCHES.customProducts.find(function( obj ) {
					return obj.id === item.patch_id;
				});
				if(item.text){
					if (!item.font) {
						item.font = {
							font: patch.customConfiguration.font,
							color: patch.customConfiguration.fontColor || "black",
							size: patch.customConfiguration.fontSize,
							feSize: patch.customConfiguration.fontSize
						}
					}
					var patchText = $('<div></div>', {
						'class': $cache.classes.configuratorComparePatch
					});
					container.data('images_count', container.data('images_count')+1);
					patchText.css('left', item.x + "%");
					patchText.css('top', item.y + "%");
					patchText.css('fontFamily', item.font.font);
					patchText.css('color', item.font.color);
					patchText.css('visibility', 'hidden');
					patchText.text(item.text);
					
					items.push(patchText);

					var image = new Image();
					image.onload = function(){ imagesLoadFunction(container); };
					image.src = img.get(0).src;
					var setSize = function(width){
						var scaledResizingFactor = item.scaleInPercents ? item.scaleInPercents : 100;
						var imageFactor = (width / img.get(0).naturalWidth) * 100;
						var fontSize = calcFinalResizeFactors(item.font.size, item.size, scaledResizingFactor, imageFactor);
						var patchTextTransform = 'translate(-50%, -50%) rotate(' + item.rotateAngle + 'deg)';
						
						patchText.css('fontSize', fontSize);
						patchText.css('visibility', 'visible');
						
						//In case if browser default minimum font size feature is more than 0
						if(fontSize < parseInt(patchText.css('fontSize'))) {
							patchTextTransform += ' scale(' + fontSize/parseInt(patchText.css("fontSize") + ')');
						}

						patchText.css('transform', patchTextTransform);
						
					};
					
					container.one('loaded', function() {
						getImitationImageSizes(img).then(function(width) {
							setSize.call(patchText, width);
						});
					});
				}
				else{
					var patchImage = $('<img></img>', {
						'class': $cache.classes.configuratorComparePatch,
						'src': patch.imageURL
					});
					container.data('images_count', container.data('images_count')+1);
					patchImage.css('left', item.x + "%");
					patchImage.css('top', item.y + "%");
					patchImage.css('transform', 'rotate(' + item.rotateAngle + 'deg)');
					patchImage.on('load error', function(){ imagesLoadFunction(container) });
					patchImage.css('visibility', 'hidden');

					items.push(patchImage);

					var setSize = function(width, height){
						var $this = $(this);
						
						if (item.patchWidth && item.patchHeight) {
							/*
							 * Patch width calculates as x1/w1 = x2/w2
							 * 
							 * Where:
							 * x1 - patch width on the canvas
							 * x2 - unknown (patch width on the new image)
							 * w1 - width of the canvas
							 * w2 - width of the new image where we want to draw patch
							 * 
							 * x2 = (x1 * w2) / w1
							 * 
							 * **The same principle for the height of the patch
							 */
							var itemWidth = (item.patchWidth * width) / item.sideWidth;
							var itemHeight = (item.patchHeight * height) / item.sideHeight;
						}
						//TODO:PRCONF:V2:COMPATIBILITY: else is for backward compatibility and should be removed in the future
						else {
							var scaledResizingFactor = item.scaleInPercents ? (item.scaleInPercents * item.resizingFactor) / 100 : item.resizingFactor;
							var imageFactor = (width / img.get(0).naturalWidth) * 100;
							var itemWidth = calcFinalResizeFactors($this.get(0).naturalWidth, item.size, scaledResizingFactor, imageFactor);
							var itemHeight = calcFinalResizeFactors($this.get(0).naturalHeight, item.size, scaledResizingFactor, imageFactor);
						}
						
						$this.width(itemWidth);
						$this.height(itemHeight);
						$this.css('margin-left', -(itemWidth/2));
						$this.css('margin-top', -(itemHeight/2));
						$this.css('visibility', 'visible');
					};
					
					container.one('loaded', function() {
						getImitationImageSizes(img).then(function(width, height) {
							setSize.call(patchImage, width, height);
						});
					});
				}
			}
		});

		return items;
	}

	function removeStoredConfiguration(storedIndex) {
		var localStorageConfiguration = localStorage.getItem($cache.compareKey);
		if (localStorageConfiguration !== null) {
			try {
				$cache.stored = JSON.parse(localStorageConfiguration);
				$cache.stored.splice(storedIndex, 1);
				localStorage.setItem($cache.compareKey, JSON.stringify($cache.stored));
			} catch (e) {
				// empty
			}
		}
	}

	function checkBrokenConfigurations() {
		if ($cache.brokenConfigurationsChecked) {
			return;
		}
		var stored = $cache.stored;
		for (var i = stored.length - 1; i >= 0; i--) {
			var patches = stored[i];
			for (var j = 0, patchesLength = patches.length; j < patchesLength; j++) {
				if (!patchExists(patches[j].patch_id)) {
					removeStoredConfiguration(i);
					break;
				}
			}
		}
		$cache.brokenConfigurationsChecked = true;

		function patchExists(patchId) {
			return app.resources.CONF.PATCHES.customProducts.find(function(obj) {
				return obj.id === patchId;
			});
		}
	}

	/* Create list of all saved designs with applied patches */
	//TODO: Crutch with externalPatchObject should be removed. After this chosenCompareIndex logic should be re-implemented
	function initSavedDesigns( id, externalPatchObject ){

		externalPatchObject = externalPatchObject || {};
		var chosenCompareIndex = $cache.selectedPatch && $cache.stored.length ? $cache.stored.length - 1 : 0;
		
		if (externalPatchObject.externalPatchExists && externalPatchObject.patchInfo && externalPatchObject.patchInfo.index != null) {
			chosenCompareIndex = externalPatchObject.patchInfo.index;
		}
		else if ($cache.selectedPatch > -1) {
			chosenCompareIndex = $cache.selectedPatch;
		}

		updateCurrentVariantID( $cache.stored[chosenCompareIndex][0].pid || $cache.variantsData.variantsData[0].id);

		var container = $($cache.selectors.configuratorCompareList);
		if (!container) {
			return false;
		}

		container.data('images_count', 0).html('<div class="' + $cache.classes.compareListCarouselClass + '"></div');

		$.each($cache.stored, function(i, patches){
			var first_patch = patches[0];
			var variantData = getVariantDataByID( first_patch.masterPid );
			if ( !variantData.realMasterId ) {
				hideLoader();
			}
			var patch_img_src = variantData['items'][first_patch.item]['sides'][first_patch.side]['image'];
			var $item = $('<div></div>', {
				'class': $cache.classes.configuratorCompareItem + (i === chosenCompareIndex ? ' active' : ''),
				'data-id': i
			});
			var $base_img = $('<img></img>', {
				'class': $cache.classes.configuratorCompareImg,
				'src': patch_img_src
			});
			var $item_inner = $('<div></div>', {
				'class': $cache.classes.configuratorCompareItem + '-inner',
				'data-boardkey': first_patch.boardKey + '_' + i
			});

			container.data('images_count', container.data('images_count')+1);
			
			var items = SetPatches($($cache.selectors.configuratorCompareList), $base_img, patches, first_patch.boardKey);
			$base_img.on('load error', function(){ imagesLoadFunction(container) });
			$item_inner.append($base_img);
			$item_inner.append(items);
			$item.append($item_inner);
			
			$item.on('click', function(){
				if ( variantData.realMasterId ) {
					getCustomProductConfigurationData( [variantData.realMasterId], variantData.realMasterId );
				}
				updateMainImage($(this).data('id'));
			});
			container.find('.' + $cache.classes.compareListCarouselClass).append($item);
		});
		
		var compareLength = container.find('.'+$cache.classes.configuratorCompareItem).length;
		if (compareLength) {
			if ($cache.currentStep === 10) {
				var $carousel = container.find('.' + $cache.classes.compareListCarouselClass);
				app.owlcarousel.initCarousel($carousel, !app.device.isMobileView() ? $cache.compareListCarouselConfig : $cache.compareListCarouselConfigMobile);
				$carousel.trigger('refreshed.owl.carousel').trigger("to.owl.carousel", chosenCompareIndex);
				container.show();
				$($cache.selectors.configuratorEditDesignBtn).show();
				$cache.selectedPatch = container.find('.'+$cache.classes.configuratorCompareItem).eq(chosenCompareIndex).data('id');

				var variantData = getVariantDataByID( $cache.stored[$cache.selectedPatch][0].masterPid );
				if ( variantData && variantData.realMasterId ) {
					replaceMainImageCarousel( $cache.stored[$cache.selectedPatch][0].masterPid ).then(applyPatches);
				}
				else {
					applyPatches();
				}
			}
		} else {
			$($cache.selectors.configuratorCompareList).hide();
			$($cache.selectors.configuratorEditDesignBtn).hide();
		}
		$cache.configuratorStep1Wrapper.find($cache.selectors.addtoWishList).prop('disabled', !compareLength);
		$cache.configuratorStep1Wrapper.find($cache.selectors.addToCart).prop('disabled', !compareLength);
		updateCTAButtons( chosenCompareIndex );
		
		$($cache.selectors.configuratorCompareList).on('translated.owl.carousel', function (event) {
			$(this).toggleClass($cache.classes.compareListCarouselScrolledClass, event.item.index !== 0);
		});
	}

	function updateCTAButtons( patch_id ) {

		var addedToCart = !!$cache.stored[patch_id][0].addedToCart;
		var cartBtn = $cache.configuratorStep1Wrapper.find($cache.selectors.addToCart);
		cartBtn.toggleClass($cache.classes.productAddToCartSubmitAdded, addedToCart);
		cartBtn.html(addedToCart ? app.resources.get('ADDED_TO_CART') : app.resources.get('ADD_TO_CART'));

		var addedToWishlist = !!$cache.stored[patch_id][0].addedToWishlist;
		var wishlistBtn = $cache.configuratorStep1Wrapper.find($cache.selectors.addToWishListPDP);
		wishlistBtn.toggleClass($cache.classes.productAddToWishlistSubmitAdded, addedToWishlist);
	}

	function replaceMainImageCarousel( masterProductID ) {

		var defferedObject = $.Deferred();

		if ( $cache.configuratorStep1Tabs.data('pid') === masterProductID ) {
			hideLoader();
			defferedObject.resolve();
		}
		else {

			showLoader();

			var variantData = getVariantDataByID( masterProductID );
			var items = variantData.items;
			var firstImageElement = false;

			for( var item in items ) {
				var tabsBlock = $('#tab-'+item);
				var carouselBlock = $(tabsBlock).find($cache.selectors.configuratorItemsContent);
				$(carouselBlock).css('width', $(carouselBlock).outerWidth()).css('height', $(carouselBlock).outerHeight());
				$(carouselBlock).addClass( $cache.classes.carousel.loading ).empty().trigger('destroy.owl.carousel');
				var dotsBlock = $('<div/>',{'class' : $cache.classes.carousel.dots + ' '+item});
				$(tabsBlock).append(dotsBlock);
				for( var side in items[item].sides ) {
					var shapeClass = (items[item].sides[side].shape ? 'm-item-'+items[item].sides[side].shape : '');
					var sideWrapper = $('<div/>',{
						'class' : $cache.classes.carousel.itemWrapper
					});
					var imageItem = $('<div/>',{
						'class' : $cache.classes.carousel.itemElement + ' ' + side + ' ' + shapeClass,
						'data-boardKey' : item+'_'+side
					});
					var imageElement = $('<img/>',{
						'src' : items[item].sides[side].image,
						'class' : $cache.classes.carousel.itemElementImage
					});
					if ( !firstImageElement ) {
						firstImageElement = imageElement;
					}
					imageItem.append( imageElement );
					sideWrapper.append( imageItem );
					$(tabsBlock).find($cache.selectors.configuratorItemsContent).append( sideWrapper );
					$(dotsBlock).append($('<div/>',{'class': $cache.classes.carousel.dot }));
				}
				if ( items[item].video ) {
					var videoKeys = Object.keys(items[item].video);
					for( var i = 0, len = videoKeys.length; i < len; i++ ) {
						var videoID = videoKeys[i];
						if ( app.device.isMobileView() ) {
							var videoBlock = $('<div/>',{'class' : $cache.classes.carousel.itemVideo});
							var imageBlock = $('<img/>',{'class' : $cache.classes.carousel.itemVideoImage, 'data-video' : items[item].video[videoID], 'src' : ''});
							videoBlock.append( imageBlock );
						}
						else {
							var videoBlock = $('<div/>',{'class' : $cache.classes.carousel.itemVideo, 'data-video': items[item].video[videoID] });
							var iframeBlock = $('<iframe/>',{
								'src': 'https://player.vimeo.com/video/'+items[item].video[videoID], 
								'width': '100%', 
								'height': '100%',
								'frameborder': 0,
								'allow':'fullscreen'});
							videoBlock.append( iframeBlock );
						}
						$(tabsBlock).find($cache.selectors.configuratorItemsContent).append(videoBlock);
						$(dotsBlock).append($('<div/>',{'class' : $cache.classes.carousel.dot + ' ' + $cache.classes.carousel.videoDot}));
					}
				}

				carouselBlock.on('initialized.owl.carousel',function(){
					carouselBlock.off('initialized.owl.carousel');
					$(carouselBlock).css('width','').css('height','');
					monitorCarousels();
					defferedObject.resolve();
				});
				app.owlcarousel.initCarousel(carouselBlock);

				firstImageElement.on('load error', function(){ 
					hideLoader();
				});

			}
		}

		$cache.configuratorStep1Tabs.data('pid', masterProductID );

		return defferedObject.promise();
	}

	function showLoader() {
		switch($cache.currentStep) {
			case 1:
			case 10:
				if ( !$cache.configuratorStep1Loader.is('visible') ) {
					$cache.configuratorStep1Loader.show();
				}
				break;
			case 2:
				if ( !$cache.configuratorStep2Loader.is('visible') ) {
					$cache.configuratorStep2Loader.show();
				}
				break;
		}
	}

	function hideLoader() {
		switch($cache.currentStep) {
			case 1:
			case 10:
				$cache.configuratorStep1Loader.hide();
				break;
			case 2:
				$cache.configuratorStep2Loader.hide();
				break;
		}
	}

	function getVariantDataByID( productID ) {
		if ( !productID ) {
			productID = $cache.masterId;
		}
		return $cache.variantsData.variantsData.find(function( obj ) {
			return (obj.realMasterId ) ? obj.realMasterId === productID : obj.masterId === productID ;
		});
	}

	/* Update price in saved object */
	function updateProductPrice(id, callback){
		var data = JSON.stringify($cache.formObj);
		$.ajax({
			url : app.urls.getCustomPrice,
			type : 'GET',
			data : {'customProduct': data},
			dataType : 'JSON'
		}).done(function(data){
			if(data.formattedPrice) {
				var localStorageConfiguration = localStorage.getItem($cache.compareKey);
				if(localStorageConfiguration !== null){
					try{
						$cache.stored = JSON.parse(localStorageConfiguration);
						$.each($cache.stored[id], function(i, item){
							item.price = {price: data.totalPrice, formatted: data.formattedPrice, currency: data.currencyCode}
						})
						localStorage.setItem($cache.compareKey, JSON.stringify($cache.stored));
						if(typeof callback === 'function' && id === $cache.selectedPatch ) {
							callback({price: data.totalPrice, formatted: data.formattedPrice, currency: data.currencyCode});
						}
					}
					catch (e) {
					}
				}
			}
		});
	}
	
	/* Apply selected patches to product images */
	function applyPatches(){
		var id = $cache.selectedPatch;
		var patches = $cache.stored[id];
		var keys = {};
		var sides = [];
		var customProducts = {};
		var mainSizeDeferreds = [];
		var customSizeDeferreds = [];
		var updatePrices = false;
		var wishListUrl = $cache.configuratorStep1Wrapper.find($cache.selectors.addtoWishList).data('href');
		
		$($cache.selectors.configuratorItemsContent).find('.'+$cache.classes.configuratorComparePatch).remove();

		var sizeDisplayValue = $cache.variation.sizeDisplayValue ? $cache.variation.sizeDisplayValue : patches[0].size;
		$cache.configuratorSelectedSize.find('span').text(sizeDisplayValue);

		$.each(patches, function(i, patch){
			if (!patch.price || patch.price.currency !== app.user.currencyCode) {
				updatePrices = true;
			}
			if (!patch.font) {
				var temp_patch = app.resources.CONF.PATCHES.customProducts.find(function( obj ) {
					return obj.id === patch.patch_id;
				});
				patch.font = temp_patch.customConfiguration && temp_patch.customConfiguration.font ? {
					font: temp_patch.customConfiguration.font,
					color: temp_patch.customConfiguration.fontColor || "black",
					size: temp_patch.customConfiguration.fontSize,
					feSize: temp_patch.customConfiguration.fontSize
				} : {}
			}
			if (!patch.scaleInPercents) {
				patch.scaleInPercents = 100;
			}
			var container = $($cache.selectors.configuratorItemsContent).find('[data-boardkey='+patch.boardKey+']');
			var containerImage = container.filter(function(){
				return !$(this).parents('.b-owl_carousel-item').hasClass('cloned');
			}).find('img');
			var internalDeferred = $.Deferred();
			getImitationImageSizes(containerImage).then(function(currentContainer, currentPatch, def, width) {
				var imageFactor = (width / currentContainer.find('img').get(0).naturalWidth) * 100;
				wResFactor = calcFinalResizeFactors(currentContainer.find('img').get(0).naturalWidth, currentPatch.size, currentPatch.resizingFactor, imageFactor),
				hResFactor = calcFinalResizeFactors(currentContainer.find('img').get(0).naturalHeight, currentPatch.size, currentPatch.resizingFactor, imageFactor);
				customProducts[currentPatch.boardKey] = customProducts[currentPatch.boardKey] || [];
				
				customProducts[currentPatch.boardKey].push({
					"uuid" : currentPatch.uuid,
					"id" : currentPatch.patch_id,
					'text': currentPatch.text,
					'renderer': currentPatch.text ? 'freetext' : '',
					'font': currentPatch.font.font,
					'fontSize': currentPatch.font ? currentPatch.font.size * currentPatch.scaleInPercents / 100 : null,
					'FEfontSize': currentPatch.font.feSize,
					'fontColor': currentPatch.font.color,
					"type": currentPatch.text ? "FreeText" : "Patch",
					'resizingFactor': currentPatch.resizingFactor ? currentPatch.resizingFactor * currentPatch.scaleInPercents / 100 : null,
					"customProdWidth" : wResFactor,
					"customProdHeight" : hResFactor,
					"x": currentPatch.x,
					"y": currentPatch.y,
					"rotateAngle": currentPatch.rotateAngle
				});
				
				if (!keys[currentPatch.boardKey]) {
					$.each(currentContainer, function(j, item){
						$(item).data('images_count', 0);
						var img = $(item).find('img');
						var items = SetPatches($(item), img, patches, currentPatch.boardKey);
						$(item).append(items);
						
					})
					keys[currentPatch.boardKey] = true;
				}
				
				def.resolve();
			}.bind(null, container, patch, internalDeferred));
			customSizeDeferreds.push(internalDeferred);
		});
		
		$.when.apply($, customSizeDeferreds).done(function() {
			$.each(customProducts, function(i, products){
				var patch = patches.find(function( obj ) {
					return obj.boardKey === i;
				});
				var container = $($cache.selectors.configuratorItemsContent).find('[data-boardkey='+patch.boardKey+']').filter(function(){
					return !$(this).parents('.b-owl_carousel-item').hasClass('cloned');
				});
				var containerImage = container.find('img');
				var internalDeferred = $.Deferred();
				getImitationImageSizes(containerImage).then(function(currentPatch, currentProducts, def, width, height) {
					var side = {
						item: currentPatch.item,
						key: currentPatch.boardKey,
						mainProdHeight: height,
						mainProdWidth: width,
						resizingFactor: currentPatch.resizingFactor,
						side: currentPatch.side,
						sizeResizingFactor: $cache.resizingFactors[currentPatch.size],
						disSizeResizingFactor: $cache.disSizeResizingFactor,
						customProducts: currentProducts
					};
					sides.push(side);
					
					def.resolve();
				}.bind(null, patch, products, internalDeferred));
				mainSizeDeferreds.push(internalDeferred);
			});
			
			$.when.apply($, mainSizeDeferreds).done(function() {
				app.components.product.configurator.updateFormObj({sides: sides});
				
				$cache.formObj = {
					version: app.util.getConfig('configurator.activeJSONVersion'),
					type: app.resources.CONF.VARIANTS.type,
					productId: $cache.currentProductId,
					sides: sides,
					activePatchesIndexes: patches
				};
				
				if (updatePrices) {
					updateProductPrice(id, function(price){
						$cache.configuratorStep1Wrapper.find($cache.selectors.productPriceStandart).text(price.formatted).removeClass($cache.classes.invisibleClass);
						$cache.configuratorPriceInput.val(price.price);
					});
				} else {
					$cache.configuratorStep1Wrapper.find($cache.selectors.productPriceStandart).text(patches[0].price.formatted).removeClass($cache.classes.invisibleClass);
					$cache.configuratorPriceInput.val(patches[0].price.price);
				}

				$cache.configuratorProductIdInput.val($cache.variation.id);
				$cache.configuratorCustomProductsInput.val(JSON.stringify($cache.formObj));
				
				$cache.configuratorStep1Wrapper.find($cache.selectors.addtoWishList).data('pid', $cache.variation.id);
				$cache.configuratorStep1Wrapper.find($cache.selectors.addtoWishList).data('href', app.util.appendParamToURL(wishListUrl, 'pid', $cache.variation.id));
			});
		});
	}

	/* Create compare popup */
	function refreshActiveCompareCarousel(itemContainer) {
		var activeSide = itemContainer.find('.' + $cache.classes.sidesControllerCssClass + " .active");
		var activeSideClassString = activeSide.attr('class');
		var activeSideClass = activeSideClassString.substr(0, activeSideClassString.indexOf(' '));
		var activeSideCarousel = itemContainer.find($cache.selectors.sideContainerJsSel + '.m-' + activeSideClass);

		$(activeSideCarousel).trigger('reload.single.owl');
	}

	function Compare(selectedConfigurationIndex) {
		var configuration = null,
			container = $('<div></div>'),
			configurationSwitchers = [],

			show = function(container, index){
				var $this = $(this);
				
				if(isNaN(index) || index === 0) {
					$this.siblings().removeClass($cache.classes.active);
					$this.addClass($cache.classes.active);
					container.siblings().hide();
					container.find('.b-product-configurator-compare-side-btn-radio[value=' + selectedConfigurationIndex + ']').prop('checked', true);
					container.show();
				}
				
				container.trigger('shown');
			},

			loadConfiguration = function(){
				configuration = parseInt(container.data('configuration'));
				container.remove();
				container = undefined;
				app.fancybox.close();
				
				$cache.document.trigger('configuratorCompare.finish', [configuration]);
			},

			removeConfiguration = function(){
				configuration = container.data('configuration');
				container.remove();
				container = undefined;
				app.fancybox.close();
				
				$cache.document.trigger('configuratorCompare.remove', [configuration]);
			},
			
			selectConfiguration = function() {
				var value = this.value === undefined ? 0 : this.value;
				container.data('configuration', value);
				selectedConfigurationIndex = +value;
				this.checked = true;
				configurationChooser.css('display', 'block');
				configurationRemove.css('display', 'block');
				
				return false;
			},
			getConfigurationImage = function( masterPid, item, side ) {
				var currentVariant = getVariantDataByID( masterPid );
				return currentVariant.items[item].sides[side].image;
			};

		container.addClass($cache.classes.containerCssClass);
		if($cache.variantsData.type){
			container.addClass($cache.variantsData.type);
		}
		var titleContainer = $('<div></div>', {
			'class': $cache.classes.titleContainer
		});
		titleContainer.text(app.resources.get('CONF.COMPAREDESIGN').replace('{num}', $cache.stored.length));
		container.append(titleContainer);

		var itemsControlsContainer = $('<div></div>', {
			'class': $cache.classes.itemsControllerCssClass
		});
		if ( Object.keys($cache.variation.items).length === 1 ) {
			itemsControlsContainer.addClass( $cache.classes.hiddenClass );
		}
		container.append(itemsControlsContainer);

		var itemsContainer = $('<div></div>', {
			'class': $cache.classes.itemsContainerCssClass
		});
		container.append(itemsContainer);

		var itemCounter = 0;

		var configurationChooser = $('<a class="' + $cache.classes.configurationCompareChooseBtnCLass + '"></a>');
		configurationChooser.html(app.resources.get('CONF.CHOOSE'));
		configurationChooser.css('display', 'none');
		configurationChooser.on('click', loadConfiguration);

		var configurationRemove = $('<a class="' + $cache.classes.configurationCompareRemoveBtnCLass + '"></a>');
		configurationRemove.html(app.resources.get('CONF.REMOVE'));
		configurationRemove.css('display', 'none');
		configurationRemove.on('click', removeConfiguration);

		$.each($cache.variation.items, function (itemKey, item) {
			var itemContainer = $('<div></div>', {
				'class': $cache.classes.itemsCssClass
			});
			itemContainer.hide();
			itemsContainer.append(itemContainer);

			var sideControlContainer = $('<div></div>', {
				'class': $cache.classes.sidesControllerCssClass
			});
			itemContainer.append(sideControlContainer);

			var sideContentContainer = $('<div></div>', {
				'class': $cache.classes.sidesContainerCssClass
			});
			itemContainer.append(sideContentContainer);

			var itemButton = $('<a></a>', {
				'class': itemKey
			})
			itemButton.html(app.resources.get('CONF.' + itemKey));
			itemButton.on('click', function() {
				show.call(itemButton, itemContainer);
				refreshActiveCompareCarousel(itemContainer);
			});

			itemsControlsContainer.append(itemButton);

			var sideCounter = 0;

			$.each(item.sides, function (sideKey, side) {
				var sideContainer = $('<div></div>', {
					'class': $cache.classes.sideContainerCssClass + ' m-' + sideKey
				});
				sideContainer.css('visibility', 'hidden');
				sideContainer.hide();
				sideContentContainer.append(sideContainer);

				var sideButton = $('<a></a>', {
					'class': sideKey
				});
				sideButton.html(app.resources.get('CONF.' + sideKey));
				sideButton.append(sideButton);
				sideButton.on('click', function() {
					show.call(sideButton, sideContainer);
					refreshActiveCompareCarousel(itemContainer);
				});
				
				sideControlContainer.append(sideButton);
				if(sideCounter === 0){
					itemContainer.data('itemCounter', itemCounter);
					itemContainer.one('shown', function () {
						show.call(sideButton, sideContainer, sideCounter);
					});
				}

				sideContainer.one('shown', function () {
					itemCounter = itemContainer.data('itemCounter');
					sideContainer.data('images_count', 0);
					$.each($cache.stored, function (compareKey, compare) {
						var configurationContainer = $('<div></div>', {
							'class': "b-product-configurator-compare-items-item-sides-side-configuration b-product-configurator-compare-carousel-item"
						});
						sideContainer.append(configurationContainer);

						var imagesWrapper = $('<div></div>', {
							'class': "b-product-configurator-compare-items-item-sides-side-configuration-wrapper",
							'data-boardkey': itemKey + '_' + sideKey + '_' + compareKey
						});
						configurationContainer.append(imagesWrapper);

						sideContainer.data('images_count', sideContainer.data('images_count')+1);

						var masterPid = $cache.stored[compareKey][0].masterPid;
						var sideImageSrc = getConfigurationImage(masterPid, itemKey, sideKey);
						var sideImage = $('<img></img>', {
							'src': sideImageSrc,
							"class": "b-product-configurator-compare-side-image"
						});
						imagesWrapper.append(sideImage);
						sideImage.on('load error', function(){ imagesLoadFunction(sideContainer) });
						
						imagesWrapper.append(SetPatches(sideContainer, sideImage, compare, itemKey+'_'+sideKey));

						var configurationSwitcherContainer = $(document.createElement('label'));
						configurationSwitcherContainer.addClass('b-product-configurator-compare-side-btn');
						var configurationSwitcher = $('<input></input>', {
							'class': 'b-product-configurator-compare-side-btn-radio',
							'type': 'radio',
							'name': 'configurationSwitcher_'+itemCounter+sideCounter,
							'value': compareKey
						});
						var configurationSwitcherSpan = $('<span></span>', {
							'class': 'checked'
						});
						configurationSwitcher.on('change', selectConfiguration);

						configurationSwitcherContainer.append(configurationSwitcher);
						configurationSwitcherContainer.append(configurationSwitcherSpan);
						configurationContainer.append(configurationSwitcherContainer);
						configurationSwitchers.push(configurationSwitcher);
						
						if (compareKey === selectedConfigurationIndex) {
							selectConfiguration.call(configurationSwitcher.get(0));
						}
					});
					sideCounter++;
				});

				sideContainer.one('loaded', function () {
					sideContainer.css('visibility', 'visible');
					
					if(sideContainer.data('owl.loaded') !== true && !app.device.isMobileView()){
						app.owlcarousel.initCarousel(sideContainer, $cache.compareCarouselConfig);
						sideContainer.on('owl.afterUpdate', function () {
							sideContainer.trigger('loaded');
							$.fancybox.update();
						});
						sideContainer.data('owl.loaded', true);
					}
				});
			});

			if(itemCounter === 0){
				show.call(itemButton, itemContainer);
			}

			itemCounter++;
		});

		var configurationsContainer = $('<div></div>', {
			'class': $cache.classes.chooserContainerCssClass
		});
		container.append(configurationsContainer);

		configurationsContainer.append(configurationChooser);
		configurationsContainer.append(configurationRemove);

		compareShower(container, app.device.isMobileView());

		return this;
	};
	
	function compareShower(content, forceContainerHeight) {
		if (forceContainerHeight) {
			app.fancybox.open(content, {
				afterShow: function() {
					var titleContainerH = $('.' + $cache.classes.titleContainer).innerHeight(),
						itemControllerH = $('.' + $cache.classes.itemsControllerCssClass).innerHeight(),
						sidesControllerH = $('.' + $cache.classes.sidesControllerCssClass).innerHeight(),
						sidesContainerSel = $('.' + $cache.classes.sidesContainerCssClass);

					sidesContainerSel.css({
						'overflowY': 'scroll',
						'height': $cache.window.height() - (titleContainerH + itemControllerH + sidesControllerH)
					});
				},
				wrapCSS: $cache.classes.configuratorComparePopupClass
			});
		} else {
			app.fancybox.open(content);
		}
	}
	
	function compareButtonShower(){
		$($cache.selectors.configuratorCompareLink).toggleClass($cache.classes.hiddenClass, ($cache.stored.length <= 1));
	}

	function initMultiproductSetup() {

		getMultiproductConfiguration();
		attachEvents();

		function getMultiproductConfiguration() {
			showLoader();
			$.ajax({
				url : app.urls.getProductSetsConfiguration,
				type : 'GET',
				data : {'pid': $cache.configuratorStep2Wrapper.data('current-product-id')},
				dataType : 'JSON'
			}).done(function(data){
				$cache.multiproductConfigurator.inited = true;
				$cache.multiproductConfigurator.defaultVariantID = data.currentVariantID;
				
				$cache.body.addClass($cache.classes.multiproductInited);
				buildTabs(data);
				
				if ($cache.selectedVariation && $cache.selectedPatch) {
					app.configurator.attributeTabsMgr.selectProduct($cache.selectedVariation);
				}
				else {
					app.configurator.attributeTabsMgr.selectProduct($cache.multiproductConfigurator.defaultVariantID);
				}
			}).fail(function(data){
				if ( data.responseJSON && data.responseJSON.errorMessage ) {
					app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR'), data.responseJSON.errorMessage);
				}
				else {
					app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR') );
				}
			}).always(function(){
				hideLoader();
			});
		}

		function buildTabs(data) {
			app.configurator.attributeTabsMgr.init(data.productMastersConfig, data.filtersConfig);
			$($cache.selectors.configuratorTabsWrapper)
				.empty()
				.append(app.configurator.attributeTabsMgr.getHTML().$titleSection)
				.append(app.configurator.attributeTabsMgr.getHTML().$bodySection);
		}

		function attachEvents() {
			$cache.document.on('configurator.productSelected', function(e,productConfig){
				showLoader();
				$cache.multiproductConfigurator.currentProductConfig = productConfig;
				$cache.realMasterId = productConfig.productMasterID;
				$cache.multiproductConfigurator.mainImage.attr('src', productConfig.productImage );
				var variantBlock = productConfig['variationAttributes'];
				variantBlock['pid'] = productConfig['variantID'];
				$($cache.selectors.productVariations).data('current', variantBlock);
				$cache.document.trigger('configuratorStep2.changed');
				app.notificationsMgr.hideAll();
			});
			$cache.document.on('configurator.productUnselected configurator.attributeUnavailable', function() {
				var message = app.resources.get('CONF.ITEM_NOT_AVAILABLE');
				app.notificationsMgr.show('configurator', { text: message });
			});
			$cache.multiproductConfigurator.mainImage.on('load error', function(){ 
				hideLoader();
			});
		}
	}

	function getCustomProductConfigurationData( masterProductIDs, currentProductID, skipCustomProducts ) {

		var deferred = $.Deferred();
		var productsToLoad = [];
		for( var i = 0, len = masterProductIDs.length; i < len; i++ ) {
			if ( !$cache.customProductsConfigData[ masterProductIDs[i] ] ) {
				if ( productsToLoad.indexOf(  masterProductIDs[i] ) === -1 && masterProductIDs[i] !== '' && typeof masterProductIDs[i] !== 'undefined' ) {
					productsToLoad.push( masterProductIDs[i] );
				}
			}
			else {
				var productData = $cache.customProductsConfigData[ masterProductIDs[i] ];
				if ( masterProductIDs[i] === currentProductID ) {
					updateVariantsDataObject( productData );
				}
			}
		}

		if ( productsToLoad.length > 0 ) {
			showLoader();
			loadCustomProductConfigurationData( productsToLoad, currentProductID, skipCustomProducts, deferred );
		}
		else {
			deferred.resolve();
		}

		return deferred.promise();

		function loadCustomProductConfigurationData( masterProductIDs, currentProductID, skipCustomProducts, defferedObject ) {

			$cache.multiproductConfigurator.isLoading = true;
			var dataToSubmit = {
				'pids': masterProductIDs.join(',')
			};
			if ( skipCustomProducts ) {
				//TODO:PRCONF:V2:Rewrite loaders for all steps. Skip custom product loading and delegate it for initLoader in app.product.configurator.js
				dataToSubmit['skipCustomProducts'] = true;
			}
			else {
				// send already loaded customizing products to reduce loading time and size
				dataToSubmit['loadedCustomProducts'] = app.resources.CONF.loadedCustomizingProducts.join(',');
			}
			$.ajax({
				url : app.urls.getCustomProductConfigData,
				type : 'GET',
				data : dataToSubmit,
				dataType : 'JSON'
			}).done(function(data){
				if ( data && data.products ) {
					for( var i = 0, len = masterProductIDs.length; i < len; i++ ) {
						var productData = data.products[masterProductIDs[i]];
						if ( productData.error ) {
							if ( len === 1 ) {
								defferedObject.reject( productData.error );
							}
							continue;
						}
						$cache.customProductsConfigData[masterProductIDs[i]] = productData;
						if ( masterProductIDs[i] === currentProductID ) {
							updateVariantsDataObject( productData );
							extendVariantsData( productData );
						}
						else {
							extendVariantsData( productData );
						}
					}
					if ( data.customProducts ) {
						updateCustomizingProducts( data.customProducts );
					}
					defferedObject.resolve();
				}
				else {
					defferedObject.reject();
				}
				$cache.multiproductConfigurator.isLoading = false;
				$cache.document.trigger('configurator.productData.loaded');
			}).fail(function(data){
				if ( data.responseJSON.errorMessage ) {
					app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR'), data.responseJSON.errorMessage);
				}
				else {
					app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR') );
				}
				defferedObject.reject();
			});

			return defferedObject.promise();
		}

		function updateCustomizingProducts( customizingProducts ) {
			for( var i = 0, len = customizingProducts.length; i < len; i++ ) {
				app.resources.CONF.PATCHES.customProducts.push( customizingProducts[i] );
				if ( app.resources.CONF.loadedCustomizingProducts.indexOf( customizingProducts[i].productType ) === -1 ) {
					app.resources.CONF.loadedCustomizingProducts.push( customizingProducts[i].productType );
				}
			}
		}

		function extendVariantsData( data ) {
			var newVariantsData = [];
			var newVariantsDataIDs = [];
			var currentVariantsData = app.resources.CONF.VARIANTS.variantsData;
			for( var j = 0, currentVariantsDataLen = currentVariantsData.length; j < currentVariantsDataLen; j++ ) {
				newVariantsData.push(currentVariantsData[j]);
				newVariantsDataIDs.push(currentVariantsData[j].id);
			}
			for( var i = 0, variantsDataLen = data.variantsData.length; i < variantsDataLen; i++ ) {
				if ( newVariantsDataIDs.indexOf(data.variantsData[i].id) === -1 ) {
					newVariantsData.push( data.variantsData[i] );
				}
			}
			app.resources.CONF.VARIANTS.variantsData = newVariantsData;
		}

		function updateVariantsDataObject( data ) {
			var newVariantsObject= {
				'variantsData': app.resources.CONF.VARIANTS.variantsData
			};
			$.each(app.resources.CONF.VARIANTS, function(index){
				if ( index !== 'variantsData' ) {
					newVariantsObject[index] = data[index];
				}
			});
			app.resources.CONF.VARIANTS = newVariantsObject;
			$cache.variantsData = newVariantsObject;
			$cache.document.trigger('configurator.changetype',[data.type]);
		}

	}

	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.configurator = app.components.product.configurator || {};
	app.components.product.configurator.utils = {
		init: function(containerSel, force) {
			if (!$cache) {
				containerSel = containerSel || '.js-pdp_main';
				var container = $(containerSel);
				if (!container.length || container.hasClass('js-product-initialized') && (typeof force !== 'undefined' && force !== true)){
					return;
				}
				initializeCache(container);
				initializeDOM();
				initializeConfiguratorEvents();
			}
		},
		compare: Compare,
		initSavedDesigns: initSavedDesigns,
		isSameExternalPatch: isSameExternalPatch
	};
})((window.app = window.app || {}), jQuery);