/**
 * @class app.product.configurator
 */
(function(app, $) {
	var $cache,
		defaultConfiguratorType = 'SHOE_WITH_PATCHES',
		settings = {
			'type' : defaultConfiguratorType,
			'items' : {},
			'patches' : [],
			'beforeAddtocartFunction' : null
		};

	var boardContainer = $('.js-configurator'),
		isConfigurable = boardContainer.length,
		freePurchasable = false;

	if (boardContainer.length > 0) {
		freePurchasable = boardContainer[0].dataset.hasOwnProperty('freepurchasable') ? boardContainer.data('freepurchasable') : false;
	}

	/** ************* app.product.configurator private vars and functions ************** */

	/**
	 * @private
	 * @function
	 * @description Initializes the cache on the product configurator.
	 */
	function initializeCache( params ) {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			customInput: $('.js-product-configurator_customProducts'),
			boardContainer: boardContainer,
			topBarContainerSel: '.js-product-configurator-topbar',
			itemsContainerSel: '.js-configurator-items',
			sidesContainerSel: '.js-configurator-sides',
			imageContainerSel: '.js-configurator-content',
			tabsContainerSel: '.js-configurator-patches-tabs',
			patchesContainerSel: '.js-configurator-patches',
			controllersContainerSel: '.js-configurator-controllers',
			controllersCleanSel: '.js-product-configurator-clean',
			controllersBackSel: '.js-product-configurator-back',
			productPriceContainerSel : '.js-product_content .js-product_container-price',
			priceContainerSel: '.js-configurator-price',
			priceAlertSel: '.js-product-configurator-price-alert',
			priceAlertSpanSel: '.js-product-configurator-price-alert span',
			configuratorPopupSel: '.js-product-configurator-popup',
			configuratorPopupErrorSel: '.js-configurator-popup-error',
			configuratorPopupButtons: '.js-product-configurator-popup-buttons',
			loaderWrapperSel: '.js-product-configurator-loader',
			editingToolsSel: '.js-configurator-editing-tools',
			configuratorLoader: $('.js-product-configurator-step-3-loader'),
			popupButtonConfirmSel: '.js-popup-button-confirm',
			popupButtonCancelSel: '.js-popup-button-cancel',
			promotions: app.resources.CONF.PROMOTIONS,
			resizingFactors: app.resources.CONF.PREFERENCES,
			pdpFormSel : '.js-form_pdp',
			invisibleCls : 'h-invisible',
			hiddenCls : 'h-hidden',
			activeCls : 'active',
			doneCls: 'done',
			errorCls: 'error',
			percentCls: 'percentage',
			loadLineCls: 'bar-line',
			reverseAnimateCls: 'reversed-animation',
			hasPatchCls: 'has-patch',
			styleBlurCls: 'blur-loading',
			controlTitleCls: 'control-title',
			editToolCls: 'edit-tool',
			editToolOpenCls: 'edit-tool--open',
			fullWidthCls: 'full-width',
			infoMessageCls: 'info-message js-info-message',
			infoMessageSel: '.js-info-message',
			actionsTextCls: 'b-product-configurator-popup-text js-product-configurator-popup-text',
			actionsTextSel: '.js-product-configurator-popup-text',
			editTextCls: 'edit-text',
			disableAnimationCls: 'disable-animation',
			productPrice : 0,
			resizeTimeout : null,
			board : null,
			customProducts : null,
			updatePriceTimeout : null,
			updatePriceTimeoutMs: 100,
			formObject : {},
			beforeAddtocartFunction : settings.beforeAddtocartFunction,
			productContent : $(".js-product_content"),
			addToCartSel : ".js-add_to_cart",
			productAddToCartSubmitAddedClass : "b-product_add_to_cart-submit--added",
			showClass: 'h-show',
			isDebug: app.util.getParamFromUrl(window.location.href, 'debug') === "true",
			zoomIn: app.util.getParamFromUrl(window.location.href, 'zoom') !== "false",
			logEnabled: true,
			selectedPatches: {},
			productShareTextSel: '.js-product-share-text',
			productShareActiveClass: 'm-active',
			configuratorSaveBtnSel: '.js-product-configurator-save',
			itemsToRemoveToResetCofiguratorSel: '.b-product-configurator-loader, .b-product-configurator-overlay, .b-product-configurator-img-prev, .b-product-configurator-img-next, .js-product-configurator-side-preview',
			priceActionsSel: '.js-configurator-price-actions',
			loadIndicator: params.loadIndicator,
			activePatches: []
		};

		$cache.controllersBackAndCleanSel = $cache.controllersBackSel + ',' + $cache.controllersCleanSel;

		$cache.priceContainer = $($cache.priceContainerSel);
		$cache.pdpForm = $($cache.pdpFormSel);
		$cache.compareId = null;
		initVariantDataCache();
		$cache.showLandingModal = ($cache.currentProductId === $cache.masterId) || ($cache.promotions !== null);
		$cache.customizingProductsData = [];
		$cache.messageHeight = 0;
	}

	function initVariantDataCache() {
		$cache.variantsData = app.resources.CONF.VARIANTS;
		$cache.currentProductId = $cache.variantsData.currentProductId;
		$cache.customizingProducts = $cache.variantsData.customizingProducts;
		$cache.fontColor = $cache.variantsData.fontColor;
		$cache.tabsOrder = $cache.variantsData.tabsOrder;
		$cache.type = $cache.variantsData.type;
		$cache.defaultVariantId = $cache.variantsData.defaultVariantId;
		$cache.masterId = $cache.variantsData.masterId;
		$cache.realMasterId = ($cache.variantsData.realMasterId) ? $cache.variantsData.realMasterId : $cache.variantsData.masterId;
		$cache.localStorageKey = 'pdp_configurator' + $cache.masterId;
		$cache.compareKey = getCompareKey($cache.masterId);
		$cache.positions = $cache.variantsData.positions;
		$cache.variantsData = $cache.variantsData.variantsData;
		$cache.currentVariantData = null;
		$cache.customProducts = [];
	}
	
	function getCompareKey(masterId) {
		return 'pdp_configurator_compare' + masterId;
	}

	function Logger(type, text) {
		if (!!$cache && $cache.isDebug && $cache.logEnabled) {
			console[type || 'log'](text);
		}
	}

	/* 
	 * params: patch, board, type
	 * callbacks: ok, cancel
	 */
	function errorAction(params, callbacks){
		if (params && params.patch) {
			if (!$cache.selectedPatches[params.patch.uuid]) {
				$cache.selectedPatches[params.patch.uuid] = params.patch;
				params.patch.savePrevious();
			}
			if ($cache.selectedPatch !== $cache.selectedPatches[params.patch.uuid]) {
				$cache.selectedPatch = $cache.selectedPatches[params.patch.uuid];
				boardContainer.find($cache.popupButtonCancelSel).removeAttr('disabled');
			}
		}
		
		if (boardContainer.find($cache.configuratorPopupSel).length === 0) {
			confirmAction($.extend({}, { type: 'edit' }, params), callbacks);
		} else {
			updateActionsMessage($.extend({}, { type: 'edit' }, params));
		}

		var error = {};
		error.overlaps = false;
		$.each($cache.selectedPatches, function(i, patch){
			boardContainer.removeClass(patch.config.customConfiguration.type.toLowerCase());
			if (!patch.deleted) {
				error.overlaps = error.overlaps || (patch.editText && app.device.isMobileView() ? false : patch.getOverlaps() > 0);
			}
		});
		
		if(params.board){
			error.maxwriting = params.board.maxWritingSymbols;
		}
		boardContainer.addClass($cache.selectedPatch.config.customConfiguration.type.toLowerCase());

		if ($cache.selectedPatch) {
			$cache.selectedPatch.unselect = app.device.isMobileView() ? !($cache.selectedPatch.editText || $cache.action_rotate || $cache.action_resize) : true;
			boardContainer.toggleClass($cache.editTextCls, !!$cache.selectedPatch.editText);
			boardContainer.find($cache.configuratorPopupButtons).toggleClass($cache.fullWidthCls, !!$cache.selectedPatch.editText || $cache.action_rotate > '' || $cache.action_resize > '');
			$(settings.controllersConfig.scaleControlContainer.cssSel).toggleClass('disabled', !$cache.selectedPatch.isResizable);
			$(settings.controllersConfig.rotateControl.cssSel).toggleClass('disabled', !$cache.selectedPatch.isRotatable);
		}
		
		params.error = error.overlaps ? "overlaped" : (params.error !== "overlaped" ? params.error : null);
		params.error = params.error || (error.maxwriting ? 'MAXWRITINGSANDSYMBOLS' : null)
		
		if (!$.isEmptyObject(params.error)) {
			Logger('log', 'showError: ' + app.resources.get('CONF.' + params.error + '_ERROR'));
			boardContainer.find($cache.configuratorPopupSel).addClass($cache.errorCls);
			boardContainer.find('.' + $cache.infoMessageCls).height(0);
			if ( app.device.isMobileView() ) {
				boardContainer.find($cache.configuratorPopupErrorSel).addClass($cache.disableAnimationCls);
				boardContainer.find($cache.configuratorPopupErrorSel).html(app.resources.get('CONF.' + params.error + '_ERROR'));
				var errorHeight = boardContainer.find($cache.configuratorPopupErrorSel).outerHeight();
				boardContainer.find($cache.configuratorPopupErrorSel).css('height',errorHeight);
				boardContainer.find($cache.configuratorPopupErrorSel).html('');
			}
			setTimeout(function() {
				boardContainer.find($cache.configuratorPopupErrorSel).removeClass($cache.disableAnimationCls).css('height','auto');
				boardContainer.find($cache.configuratorPopupErrorSel).html(app.resources.get('CONF.' + params.error + '_ERROR'));
				boardContainer.find($cache.configuratorPopupSel).addClass($cache.reverseAnimateCls);
			}, 400);
		} else {
			boardContainer.find($cache.configuratorPopupSel).removeClass($cache.errorCls);
			boardContainer.find('.' + $cache.infoMessageCls).height($cache.messageHeight);
			setTimeout(function() {
				boardContainer.find($cache.configuratorPopupErrorSel).html('');
				boardContainer.find($cache.configuratorPopupSel).removeClass($cache.reverseAnimateCls);
			}, 400);
		}
	}

	/* 
	 * params: patch, board, type
	 * callbacks: ok, cancel
	 */
	function confirmAction(params, callbacks){
		if (params.type === 'back' || params.type === 'hide') {
			if (app.device.isMobileView()) {
				if ($cache.action_rotate) {
					boardContainer.find(settings.controllersConfig.rotateControl.cssSel)
						.val($cache.action_rotate).trigger('touchend').closest('.' + $cache.editToolCls)
						.find('.' + $cache.controlTitleCls)
						.click();
					return false;
				}
				if ($cache.action_resize) {
					boardContainer.find(settings.controllersConfig.scaleControl.cssSel)
						.val($cache.action_resize)
						.trigger('touchend').closest('.' + $cache.editToolCls)
						.find('.' + $cache.controlTitleCls)
						.click();
					return false;
				}
			}
			if ($cache.selectedPatch && $cache.selectedPatch.editText) {
				boardContainer.removeClass($cache.editTextCls);
				boardContainer.find($cache.configuratorPopupButtons).removeClass($cache.fullWidthCls);
			}
			if (boardContainer.find($cache.configuratorPopupSel).length > 0) {
				boardContainer.find($cache.configuratorPopupSel).remove();
				hideElements(null, false);

				if ($cache.selectedPatches) {
					if (params.type === 'back') {
						$.each($cache.selectedPatches, function(i, patch){
							if (patch.new) {
								patch.deleted = true;
								patch.removePatch(true);
							} else {
								patch.loadPrevious();
							}
							boardContainer.removeClass(patch.config.customConfiguration.type.toLowerCase());
						});
					}
					
					$cache.selectedPatches = {};
					$cache.selectedPatch = null;
				}
			}

			return true;
		}

		
		Logger('log', 'create popup');

		var actionsContainer = $('<div>', { class: 'b-product-configurator-popup js-product-configurator-popup' });
		var actionsText = $('<div>', { class: $cache.actionsTextCls });
		var actionsButtons = $('<div>', { class: 'b-product-configurator-popup-buttons js-product-configurator-popup-buttons' });
		var actionsCloseButton = $('<button>', { class: 'b-product-configurator-popup-cancel js-popup-button-cancel' });
		var actionsOkButton = $('<button>', { class: 'b-product-configurator-popup-confirm js-popup-button-confirm' });
		var errorText = $('<span>', { class: 'error-message js-configurator-popup-error' });

		actionsText.html($('<div>', { class: $cache.infoMessageCls })).append(errorText);

		if (app.device.isMobileView() && params.board && $cache.selectedPatch) {
			var rotateControlBox = $('<div>', { class: settings.controllersConfig.rotateControlContainer.cssClass + (!$cache.selectedPatch.isRotatable ? ' disabled' : ''), 'data-type': 'rotate' });
			var rotateControlTitle = $('<div>', { class: $cache.controlTitleCls }).text(app.resources.get('CONF.ROTATE_TITLE'));
			var rotateControl = $('<input>', {
				class: settings.controllersConfig.rotateControl.cssClass + ' control-input',
				type: 'range',
				min: 0,
				max: 359
			}).val(($cache.selectedPatch && $cache.selectedPatch.rotateAngle) || 0);
			if (!$cache.selectedPatch.isRotatable) {
				rotateControl.attr('disabled', 'disabled');
			}

			rotateControlBox.append(rotateControlTitle).append(rotateControl.wrap('<div class="control-content">').parent());

			var editingTools = $('<div>', { class: 'b-product-configurator-editing-tools js-configurator-editing-tools' });
			editingTools.append(rotateControlBox);
			rotateControl.on('change input', params.board.processRotate);
			rotateControl.touchend(params.board.processRotateEnd.bind(rotateControl));

			var scaleControlBox = $('<div>', { class: settings.controllersConfig.scaleControlContainer.cssClass + (!$cache.selectedPatch.isResizable ? ' disabled' : ''), 'data-type': 'resize' });
			var scaleControlTitle = $('<div>', { class: $cache.controlTitleCls }).text(app.resources.get('CONF.RESIZE_TITLE'));

			var scaleControl = $('<input>', {
				class: settings.controllersConfig.scaleControl.cssClass + ' control-input',
				type: 'range',
				min: $cache.selectedPatch && $cache.selectedPatch.config.customConfiguration
					&& $cache.selectedPatch.config.customConfiguration.decreasingReducingFactor
					? $cache.selectedPatch.config.customConfiguration.decreasingReducingFactor
					: settings.controllersConfig.scaleControl.defaultMinScale,
				max: $cache.selectedPatch && $cache.selectedPatch.config.customConfiguration
					&& $cache.selectedPatch.config.customConfiguration.increasingReducingFactor
					? $cache.selectedPatch.config.customConfiguration.increasingReducingFactor
					: settings.controllersConfig.scaleControl.defaultMaxScale
			}).val(($cache.selectedPatch && $cache.selectedPatch.scaleInPercents) || settings.controllersConfig.scaleControl.defaultScale);

			if (!$cache.selectedPatch.isResizable) {
				scaleControl.attr('disabled', 'disabled');
			}
			scaleControlBox.append(scaleControlTitle).append(scaleControl.wrap('<div class="control-content">').parent());
			scaleControl.on('change input', params.board.processScale);
			scaleControl.touchend(params.board.processScaleEnd.bind(scaleControl));

			editingTools.append(scaleControlBox);
		}

		actionsCloseButton.html(app.resources.get('CONF.' + params.type + '_CANCELBUTTON'));
		actionsCloseButton.click(function(){
			if (app.device.isMobileView()) {
				boardContainer.find('.' + $cache.editToolCls).find('.' + $cache.controlTitleCls).click();
				boardContainer.find('.' + $cache.editToolCls).find('.' + $cache.controlTitleCls).click();
				$cache.action_rotate = null;
				$cache.action_resize = null;
			}
			if (callbacks && typeof callbacks.cancel === 'function') {
				callbacks.cancel(params.type);
				if ($cache.selectedPatch) {
					boardContainer.removeClass($cache.selectedPatch.config.customConfiguration.type.toLowerCase());
				}
				$cache.selectedPatch = null;
			} else if ($cache.selectedPatch) {
				$cache.selectedPatches[$cache.selectedPatch.uuid].deleted = true;
				$cache.selectedPatch.removePatch(true);
				params.board.triggerUpdate();
				params.board.triggerUpdatePrice();
				
				var currentActivePatches = params.board.getCurrentActivePatches();
				if (currentActivePatches.length > 0) {
					var lastPatch = currentActivePatches[currentActivePatches.length-1];
					params.board.selectPatch(lastPatch);
					params.patch = lastPatch;
					params.error = isOverlapped() ? 'overlaped' : false;
					params.newPatch = false;
					errorAction(params, callbacks);
				} else {
					params.error = isOverlapped() ? 'overlaped' : false;
					errorAction(params, callbacks);
					actionsOkButtonHandler();
				}
			}
			
			Logger('log', 'popup cancel');
		});

		actionsOkButton.html(app.resources.get('CONF.' + params.type + '_OKBUTTON'));
		actionsOkButton.attr('data-type',params.type);
		actionsOkButton.click(function() {
			var customConfigurationType = params.patch && params.patch.config.customConfiguration.type;
			var isNewPatch = params.newPatch || (params.patch && params.patch.new);
			var patchId = params.patch && params.patch.patch_id;
			actionsOkButtonHandler(customConfigurationType, patchId, isNewPatch);
		});

		function actionsOkButtonHandler( patchType, patchId, isNewPatch ) {
			if (!boardContainer.find($cache.configuratorPopupSel).hasClass($cache.errorCls)) {
				if (app.device.isMobileView()) {
					$($cache.controllersContainerSel).find($cache.controllersBackAndCleanSel).removeClass($cache.hiddenCls);
					if ($cache.action_rotate) {
						$cache.action_rotate = boardContainer.find(settings.controllersConfig.rotateControl.cssSel).val();
						boardContainer.find(settings.controllersConfig.rotateControl.cssSel).trigger('touchend').closest('.' + $cache.editToolCls).find('.'+$cache.controlTitleCls).click();
						return false;
					}
					if ($cache.action_resize) {
						$cache.action_resize = boardContainer.find(settings.controllersConfig.scaleControl.cssSel).val();
						boardContainer.find(settings.controllersConfig.scaleControl.cssSel).trigger('touchend').closest('.' + $cache.editToolCls).find('.'+$cache.controlTitleCls).click();
						return false;
					}
					if ($cache.selectedPatch && $cache.selectedPatch.editText) {
						$cache.selectedPatch.editText = false;
						$cache.selectedPatch.unselect = true;
						boardContainer.removeClass($cache.editTextCls);
						boardContainer.find($cache.configuratorPopupButtons).removeClass($cache.fullWidthCls);
						errorAction(params, callbacks);
						return false;
					}
				}
				
				if (callbacks && typeof callbacks.ok === 'function') {
					callbacks.ok();
				} else {
					$.each($cache.selectedPatches, function(i, patch){
						if (!patch.deleted) {
							patch.savePrevious(false);
							patch.new = false;
						}
					});
					$cache.selectedPatches = {};
					params.board.triggerUpdate();
					params.board.triggerUpdatePrice();
					params.board.unselectPatch();
					params.board.showPatchesOnSide();
					params.board.zoomOut();
				}

				app.components.product.custom.beforeAddtocart($cache.formObject, null, $cache.currentVariantData, $cache.realMasterId);

				Logger('log', 'popup confirm');

				actionsContainer.remove();
				hideElements(params.type, false);

				if ($cache.selectedPatch) {
					boardContainer.removeClass($cache.selectedPatch.config.customConfiguration.type.toLowerCase());
				}
				$cache.selectedPatch = null;
				if ( isNewPatch ) {
					$cache.document.trigger('patchAdded',[patchType, patchId]);
				}
			}
		}

		actionsButtons.append(actionsCloseButton).append(actionsOkButton);

		if (app.device.isMobileView() && editingTools){
			editingTools.wrap('<div class="animating-wrap">')
				.before(actionsText).after(actionsButtons).parent().appendTo(actionsContainer);
		} else {
			actionsContainer.append(actionsText.wrap('<div class="animating-wrap">').parent().append(actionsButtons));
		}

		boardContainer.append(actionsContainer).show('slow');
		updateActionsMessage(params);

		$cache.messageHeight = $('.' + $cache.infoMessageCls).height();
		hideElements(params.type, true);
	}

	function updateActionsMessage(params) {
		var keyResource = params.type;
		if (params.type === 'edit' && $cache.selectedPatch) {
			if ($cache.selectedPatch.isRotatable) {
				keyResource += '_ROTATE';
			}
			if ($cache.selectedPatch.isResizable) {
				keyResource += '_RESIZE';
			}
			if ($cache.selectedPatch.isDraggable) {
				keyResource += '_MOVE';
			}
		}
		keyResource += '_TEXT';

		if (params.type === 'edit' && app.device.isMobileView()) {
			keyResource += '_MOBILE';
		}
		var $infoMessageBlock = $($cache.actionsTextSel).find($cache.infoMessageSel);
		var infoMessage = $cache.type !== 'bag' && (keyResource === 'edit_TEXT' || keyResource === 'edit_TEXT_MOBILE') ? '' : app.resources.get('CONF.' + keyResource);

		if ($infoMessageBlock.data('keyResource') !== keyResource) {
			$infoMessageBlock.html(infoMessage).data('keyResource', keyResource);
		}
	}

	function isOverlapped() {
		var overlaps = false;
		
		$.each($cache.selectedPatches, function(i, patch){
			if (!patch.deleted) {
				overlaps = overlaps || (patch.editText && app.device.isMobileView() ? false : patch.getOverlaps() > 0);
			}
		});
		
		return overlaps;
	}
	
	function hideElements(type, hide) {
		var filterClasses = boardContainer[0].className.match(/container_active_[\S]+/g);
		if (filterClasses) {
			boardContainer.removeClass(filterClasses.join(' '));
		}
		if (type) {
			boardContainer.toggleClass('container_active_'+type, hide);
		}
	}

	/**
	 * @private
	 * @function
	 * @description Collects the new variant data.
	 */
	function changeCurrentVariant(newPid) {
		/**
		 * We try to get the current variation images from the JSON print in the PDP (.js-configurator-variants)
		 */
		$cache.currentVariantData = $cache.variantsData.find(function( obj ) {
			return obj.id === newPid;
		});

		/**
		 * In the case we are in the Master product page we fall back to the default variant because
		 * the current product is not listed in the JSON
		 */
		if($.isEmptyObject($cache.currentVariantData)){
			$cache.currentVariantData = $cache.variantsData.find(function( obj ) {
				return obj.id === $cache.defaultVariantId;
			});
		}

		$cache.realMasterId = ($cache.currentVariantData.realMasterId) ? $cache.currentVariantData.realMasterId : $cache.realMasterId;
	}

	/**
	 * @private
	 * @function
	 * @description Initializes the DOM of the product configurator.
	 */
	function initializeConfigurator(params) {
		if (params.currentProductId) {
			$cache.currentProductId = params.currentProductId;
			$cache.showLandingModal = ($cache.currentProductId === $cache.masterId) || ($cache.promotions !== null);
		}

		changeCurrentVariant($cache.currentProductId);
		initLoader(params);
		updateCachedCustomProducts( true );
		$cache.document.trigger('configurator.keypartloaded');

		var customizingProductsToLoad = getMissingCustomProductsData();

		if( customizingProductsToLoad.length > 0 && !$.isEmptyObject($cache.currentVariantData)){

			var loaderTimeoutHelper = setInterval(function() {
				$cache.document.trigger('configurator.keypartloaded',[true]);
			}, 1000);

			loadCustomizingProducts( customizingProductsToLoad )
				.then(function( customizingProducts ){
					$cache.customProducts = $cache.customProducts.concat( customizingProducts );
					app.resources.CONF.PATCHES.customProducts = app.resources.CONF.PATCHES.customProducts.concat( customizingProducts );
					app.resources.CONF.loadedCustomizingProducts = app.resources.CONF.loadedCustomizingProducts.concat( customizingProductsToLoad );
					processConfiguratorInitialization( params );
				}).fail(function( message ){
					app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR'), message);
				}).always(function(){
					window.clearTimeout(loaderTimeoutHelper);
				});
		}
		else if ( $cache.customProducts.length ) {
			processConfiguratorInitialization( params );
		}
		else {
			app.components.fancybox.modal.open( app.resources.get('GENERAL_ERROR') );
		}
	}

	function loadCustomizingProducts( customizingProducts ) {
		var deferred = $.Deferred();
		/**
		 * We get all the patches from the category configured in the current product
		 */
		$.ajax({
			url : app.urls.getCustomProducts,
			type : 'GET',
			data : {'pids': customizingProducts.join(",")},
			dataType : 'JSON'
		}).done(function(data){
			if ( data && data.customProducts ) {
				deferred.resolve( data.customProducts );
			}
			else {
				deferred.reject( data.responseJSON.errorMessage );
			}
		}).fail(function(){
			deferred.reject( '' );
		});

		return deferred.promise();
	}

	function getMissingCustomProductsData() {
		var customizingProductsToLoad = [];
		// define missing customizing products
		for( var i = 0, len = $cache.customizingProducts.length; i < len; i++ ) {
			if ( app.resources.CONF.loadedCustomizingProducts.indexOf( $cache.customizingProducts[i] ) === -1 ) {
				customizingProductsToLoad.push( $cache.customizingProducts[i] );
			}
		}

		return customizingProductsToLoad;
	}

	function updateCachedCustomProducts( removePatchElements ) {
		$cache.customProducts = [];
		for( var i = 0, len = app.resources.CONF.PATCHES.customProducts.length; i < len; i++ ) {
			if ( $cache.customizingProducts.indexOf( app.resources.CONF.PATCHES.customProducts[i].productType ) !== -1 ) {
				$cache.customProducts.push( app.resources.CONF.PATCHES.customProducts[i] );
			}
		}

		if ( removePatchElements ) {
			for( var patchIndex in $cache.customProducts ) {
				delete $cache.customProducts[patchIndex]['element'];
			}
		}
	}

	function processConfiguratorInitialization( params ) {
		$cache.document.trigger('configurator.keypartloaded');

		var configuratorSettings = {
			autoInit: true,
			itemsContainer: $cache.itemsContainerSel,
			sidesContainer: $cache.sidesContainerSel,
			imageContainer: $cache.imageContainerSel,
			patchesContainer: $cache.patchesContainerSel,
			tabsContainer: $cache.tabsContainerSel,
			controllersContainer: $cache.controllersContainerSel,
			resizingFactors: $cache.resizingFactors,
			positions: $cache.positions,
			mode: settings.mode,
			beforeCallback: confirmAction,
			errorCallback: errorAction,
			activePatches: null,
			fitScreen: true,
			saveCallback: saveCallback,
			loadCallback: loadCallback,
			afterChooseCallback: afterChoose,
			isMobile: app.device.isMobileView(),
			fontColor: $cache.fontColor,
			tabsOrder: $cache.tabsOrder,
			typeCssClass: $cache.type,
			isDebug: $cache.isDebug,
			zoomIn: $cache.zoomIn,
			logger: Logger,
			translateFunction: function(text, replacements) {
				var str = app.resources.get('CONF.' + text);
				if (replacements) {
					var replacementsKeys = Object.keys(replacements);
					for (var i = 0, len = replacementsKeys.length; i < len; i++) {
						str = str.replace('{' + replacementsKeys[i] + '}', replacements[replacementsKeys[i]]);
					}
				}
				return str;
			}
		};

		var localStorageConfiguration = localStorage.getItem($cache.localStorageKey);
		var stored = [];
		if (localStorageConfiguration !== null) {
			try{
				stored = JSON.parse(localStorageConfiguration);
			}
			catch (e) {
				console.log("Cannot parse JSON of customization object from LocalStorage", e);
			}
		} else if ($cache.promotions){
			for(var key in $cache.promotions){
				if($cache.promotions[key].hasOwnProperty('preset')){
					stored = stored.concat($cache.promotions[key].preset);
				}
			}
		}
		$cache.document.trigger('configurator.keypartloaded');

		if (!$.isEmptyObject(stored)) {
			configuratorSettings.activePatches = stored;
		}

		if (params.activePatches) {
			configuratorSettings.activePatches = params.activePatches;
		}

		settings = $.extend({}, settings, configuratorSettings, params, $cache.currentVariantData, {'patches' : $cache.customProducts});

		$cache.board = $cache.boardContainer.configurator(settings);

		$cache.board.one('loaded',function(){
			$cache.document.trigger('configurator.keypartloaded');
		});

		$cache.board.changeLoaderState( true );

		var initialModalWrapperCssClasses = ['b-modal-wrapper'];

		if($cache.showLandingModal) {
			var title,
				content;

			if($cache.promotions) {
				var promoTitles = [];
				var promoContents = [];

				for(var key in $cache.promotions){
					if($cache.promotions[key].hasOwnProperty('name') && $cache.promotions[key].name !== null) {
						promoContents.push($cache.promotions[key].name);
					}

					if($cache.promotions[key].hasOwnProperty('calloutMsg') && $cache.promotions[key].calloutMsg !== null) {
						promoContents.push($cache.promotions[key].calloutMsg);
					}

					if($cache.promotions[key].hasOwnProperty('image') && $cache.promotions[key].image !== null) {
						promoContents.push('<img src="'+$cache.promotions[key].image+'" />');
					}

					if($cache.promotions[key].hasOwnProperty('details') && $cache.promotions[key].details !== null) {
						promoContents.push($cache.promotions[key].details);
					}

					if($cache.promotions[key].hasOwnProperty('cssClass')) {
						initialModalWrapperCssClasses.push($cache.promotions[key].cssClass)
					}
				}

				title = promoTitles.join(' ');
				content = promoContents.join(' ');
			}
			else{
				title = app.resources.get('CONF.CHOOSESIZEPATCHESBEGIN');
				content = app.resources.get('CONF.SNEAKERSPERSONALIZED');
			}

			app.components.fancybox.modal.open(
				title,
				content,
				{
					'modalWrapperCssClass': initialModalWrapperCssClasses.join(' '),
					'attachCloseButton' : false,
					'additionalButtons' : [
						{
							'cssClass' : 'b-modal-btn center',
							'text' : app.resources.get('CONF.OK'),
							'callbackFunction' : function(){ $.cookie($cache.localStorageKey, 1); app.components.fancybox.modal.close(); }
						}
					]
				}
			);
		}

		updatePrice(true);
		$cache.document.trigger('configurator.keypartloaded');
		$cache.document.on('firsttabloaded', function() {
			$cache.board.activatePatchesTab(0);
		});
		$cache.document.trigger('configurator.keypartloaded');
	}

	function initializeMobileTooltip() {
		$cache.document.on('tap', $cache.priceAlertSel, function() {
			var element = $($cache.priceAlertSpanSel);

			if(!element.hasClass($cache.showClass)){
				element.addClass($cache.showClass);

				$cache.document.one('tap', function() {
					element.removeClass($cache.showClass);
				});
			}
		});
	}

	function initializeMobileShare() {
		$($cache.productShareTextSel).one('click', function() {
			$(this).parent().addClass($cache.productShareActiveClass);
		});
	}

	/**
	 * @private
	 * @function
	 * @description Initializes main configurator loader
	 * Listens and counts 'configurator.keypartloaded' events
	 * Total number of events triggers should be equal to 'partsTotal' variable
	 */
	function initLoader(params){
		var partsTotal = params.loadIndicator.partsTotal;
		var localStorageConfiguration = localStorage.getItem($cache.localStorageKey);

		if ($cache.activePatches && $cache.activePatches.length) {
			partsTotal += $cache.activePatches.length;
		} else if (localStorageConfiguration !== null) {
			try {
				stored = JSON.parse(localStorageConfiguration);
				partsTotal += stored.length;
			}
			catch (e) {
				console.log("Cannot parse JSON of customization object from LocalStorage", e);
			}
		}

		if (partsTotal === 0) {
			return true;
		}

		var partsLoaded = 0,
			loadedPseudoParts = 0;
		var postLoadingDelay = params.loadIndicator.postLoadingDelay;
		if ($cache.board) {
			$cache.board.changeLoaderState(true);
		} else {
			// add one more part to total to handle board loading
			partsTotal += 1;
		}

		var selectorsToProcess = boardContainer.children().not( $cache.configuratorLoader ).not( $cache.topBarContainerSel );
		$cache.configuratorLoader.show();

		if (app.device.isMobileView()) {
			var partsLoadedInPercents = 0;
			var loaderText = '%';
			var loaderPercentage = $(document.createElement('div')).addClass($cache.percentCls).text(partsLoadedInPercents + loaderText);
			var loaderBar = $(document.createElement('div')).addClass('bar');
			var loaderBarLine = $(document.createElement('div')).addClass($cache.loadLineCls);

			selectorsToProcess.addClass(($cache.activePatches && $cache.activePatches.length) ? $cache.invisibleCls : $cache.styleBlurCls);
			loaderBar.html(loaderBarLine);
			$cache.configuratorLoader.children().empty().append(loaderPercentage).append(loaderBar);

			$cache.document.on('configurator.keypartloaded', function(e, isPseudo) {
				if (isPseudo) {
					var reallyLoadedParts = parseInt(partsLoaded * 100 / partsTotal);
					loadedPseudoParts++;
					if (reallyLoadedParts + loadedPseudoParts < ((partsLoaded+1) * 100 / partsTotal)) {
						partsLoadedInPercents = reallyLoadedParts + loadedPseudoParts + loaderText;
					}
				} else {
					partsLoaded++;
					partsLoadedInPercents = parseInt(partsLoaded * 100 / partsTotal) + loaderText;
				}
				$cache.configuratorLoader.find('.' + $cache.loadLineCls).css('width', partsLoadedInPercents);
				$cache.configuratorLoader.find('.' + $cache.percentCls).text(partsLoadedInPercents);

				if (partsLoaded === partsTotal) {
					$cache.configuratorLoader.delay(postLoadingDelay).queue(function() {
						$cache.configuratorLoader.dequeue();
						hideLoader();
					});
				}
			});
		} else {
			selectorsToProcess.addClass($cache.invisibleCls);
			$cache.document.on('configurator.keypartloaded', function(e, isPseudo) {
				if (!isPseudo) {
					partsLoaded++;
					if (partsLoaded === partsTotal) {
						hideLoader();
					}
				}
			});
		}

		/**
		 * @private
		 * @function
		 * @description Execute common actions to hide loader
		 */
		function hideLoader() {
			$cache.document.off('configurator.keypartloaded');
			partsLoaded = 0;
			$cache.configuratorLoader.hide();
			if ($cache.board) {
				$cache.board.changeLoaderState( false );
			}
			if (app.device.isMobileView()) {
				selectorsToProcess.removeClass(($cache.activePatches && $cache.activePatches.length) ? $cache.invisibleCls : $cache.styleBlurCls);
			} else {
				selectorsToProcess.removeClass($cache.invisibleCls);
			}
		}
	}

	/**
	 * @private
	 * @function
	 * @description Remove all events attached in initializeEvents()
	 */
	function deInitializeEvents() {
		$cache.boardContainer.off('boardUpdate');
		$cache.boardContainer.off('priceUpdate');
		$cache.document.off('click', '.' + $cache.controlTitleCls);
	}

	/**
	 * @private
	 * @function
	 * @description Initializes events on the product configurator
	 */
	function initializeEvents() {
		$cache.boardContainer.on('boardUpdate', function (e, data) {
			if(data.hasOwnProperty('activePatchesIndexes') && Array.isArray(data.activePatchesIndexes) && data.activePatchesIndexes.length > 0){
				localStorage.setItem($cache.localStorageKey, JSON.stringify(data.activePatchesIndexes));
			}
			else{
				localStorage.removeItem($cache.localStorageKey);
			}

			var doneIndicator = $('<span>', { class : $cache.doneCls }).text(app.resources.get('CONF.SIDE_DONE')),
				$topNavItem = $($cache.itemsContainerSel).find('.' + $cache.activeCls),
				$topNavItemTitle = $topNavItem.children('.topnav-item-title'),
				topNavItemHasPatch = $topNavItem.hasClass($cache.hasPatchCls),
				changeItemState = false;

			boardContainer.find('.'+$cache.hasPatchCls).removeClass($cache.hasPatchCls);
			for (var i in data.activePatchesIndexes) {
				var item = data.activePatchesIndexes[i].item;
				if ( $topNavItem.data('type') === item && !topNavItemHasPatch ) {
					changeItemState = true;
				}
				boardContainer.find('.'+item).addClass($cache.hasPatchCls);
			}

			if (changeItemState && data.source !== 'setActivePatches' && $topNavItem.hasClass($cache.hasPatchCls)) {
				$topNavItemTitle.css('opacity', '0');

				$topNavItem.append(doneIndicator);
				var $animatingHelper = $topNavItem.children('.' + $cache.doneCls);

				$topNavItemTitle.addClass('animate');
				$animatingHelper.animate({
					top: "23px"
				}, 900, function() {
					setTimeout(function() {
						$animatingHelper.remove();
						$topNavItemTitle.css('opacity', '1');
						$topNavItemTitle.removeClass('animate');
					}, 600);
				});
			}

			$cache.formObject = data.formObject;
			
			var formObj = {
				"version" : app.util.getConfig('configurator.activeJSONVersion'),
				"type": settings.type,
				"productId": $cache.currentProductId,
				"sides": $cache.formObject.sides,
				"activePatchesIndexes": data.activePatchesIndexes
			};

			$cache.customInput.val(JSON.stringify(formObj));
		});
		
		$cache.boardContainer.on('priceUpdate', function () {
			updatePrice();
		});

		var window_width = $cache.window.width();
		
		$cache.window.resize(function () {
			clearTimeout($cache.resizeTimeout);

			$cache.resizeTimeout = setTimeout(function () {
				if (app.device.isMobileView() && $cache.window.width() === window_width) {
					return false;
				}

				window_width = $cache.window.width();
				
				if ($cache.board.is(':visible')) {
					$cache.board.setValue('newHeight', {});
					$cache.board.adaptToScreenHeight();
				}
			}, 200);
		});

		$cache.document.on('click', '.' + $cache.controlTitleCls, function(){
			if ($(this).parent().hasClass('disabled')) {
				return false;
			}

			var $section = $(this),
				$container = $section.parent(),
				$popupInfoMessage = $container.closest($cache.editingToolsSel).prev(),
				$popupCancelButton = $container.closest($cache.editingToolsSel).next().find($cache.popupButtonCancelSel),
				$topActions = $($cache.controllersContainerSel).find($cache.controllersBackAndCleanSel),
				type = $container.data('type');

			if ($container.hasClass($cache.editToolOpenCls)) {
				$container.removeClass($cache.editToolOpenCls);
				$popupInfoMessage.find('.' + $cache.infoMessageCls).show();
				$container.siblings().show();
				$topActions.removeClass($cache.hiddenCls);
				if ($cache['action_'+type]) {
					$container.find('input').val($cache['action_'+type]).trigger('touchend');
					$cache['action_'+type] = null;
				}
				
				$($cache.configuratorPopupButtons).toggleClass($cache.fullWidthCls, !!$cache.selectedPatch.editText);

				$cache.selectedPatch.unselect = true;
			} else {
				$cache['action_'+type] = $container.find('input').val();
				$container.siblings().removeClass($cache.editToolOpenCls);
				$container.addClass($cache.editToolOpenCls);
				$popupInfoMessage.find('.' + $cache.infoMessageCls).hide();
				$($cache.configuratorPopupButtons).addClass($cache.fullWidthCls);
				$container.siblings().hide();
				$topActions.addClass($cache.hiddenCls);

				$cache.selectedPatch.unselect = !app.device.isMobileView();
			}
		});
	}

	function saveCallback(object) {
		if (!object || object.length === 0) {
			return { error: 'CHOOSEONEPATCH' };
		}

		var beforeAddToCartResult = app.components.product.custom.beforeAddtocart($cache.formObject, 'message', $cache.currentVariantData, $cache.realMasterId);
		if (!beforeAddToCartResult.success) {
			var errorMsg = beforeAddToCartResult.errorMessage
				? beforeAddToCartResult.errorMessage : true;
			return { error: errorMsg, errorParams: beforeAddToCartResult.errorParams };
		}

		object[0]['pid'] = $cache.currentProductId;
		object[0]['masterPid'] = $cache.realMasterId;
		
		var currentArray = [];
		if (localStorage.getItem($cache.compareKey) !== null) {
			try {
				currentArray = JSON.parse(localStorage.getItem($cache.compareKey));
			}
			catch (e) {
				console.log("Cannot parse JSON of compare object inside the LocalStorage", e);
			}
		}
		var patchInfo = app.components.product.configurator.utils.isSameExternalPatch(object, currentArray);
		if (!patchInfo.isEqual) {
			if ($cache.compareId > -1) {
				currentArray[$cache.compareId] = object;
			} else {
				currentArray.push(object);
			}
		}
		localStorage.setItem($cache.compareKey, JSON.stringify(currentArray));

		return currentArray;
	}

	function loadCallback(){
		try{
			return JSON.parse(localStorage.getItem($cache.compareKey));
		}
		catch (e) {
			console.log("Cannot parse JSON of compare object inside the LocalStorage", e);
			return [];
		}
	}

	function afterChoose() {
		app.fancybox.close();
	}

	function updatePrice(showInitPrice) {
		var data = $cache.customInput.val();
		if (!data && typeof showInitPrice === 'undefined' || typeof showInitPrice !== 'undefined' && !showInitPrice) {
			return;
		}

		clearTimeout($cache.updatePriceTimeout);
		$cache.updatePriceTimeout = setTimeout(function () {
			$.ajax({
				url : app.urls.getCustomPrice,
				type : 'GET',
				data : {'customProduct': data},
				dataType : 'JSON'
			}).done(function(data){
				if (data.formattedPrice) {
					app.components.product.configurator.priceObject = data;
					$cache.priceContainer = $($cache.priceContainerSel);
					$cache.priceContainer.html(app.resources.get('CONF.PRICE_TOTAL') + data.formattedPrice);

					var localStorageConfiguration = localStorage.getItem($cache.localStorageKey);
					var stored = [];
					if(localStorageConfiguration !== null){
						try {
							stored = JSON.parse(localStorageConfiguration);
							$.each(stored, function(i, item){
								item.price = {price: data.totalPrice, formatted: data.formattedPrice, currency: data.currencyCode}
							})
							localStorage.setItem($cache.localStorageKey, JSON.stringify(stored));
						}
						catch (e) {}
					}
					app.components.product.custom.beforeAddtocart($cache.formObject, 'message', $cache.currentVariantData, $cache.realMasterId);
				}
			});
		}, $cache.updatePriceTimeoutMs);
	}

	function saveProductCustomizationError() {
		var modalOptions = {
			closeButtonText: app.resources.get('CONF.CANCELRESET'),
			closeButtonCssClass: 'b-modal-btn center'
		};
		app.components.fancybox.modal.open(app.resources.get('CONF.NOITEMSSELECTED'), app.resources.get('CONF.CHOOSEONEPATCH'), modalOptions);
	}

	//TODO:PRCONF:V2:move saveProductCustomization to app.product.configurator.utils.js and update all related scripts
	function saveProductCustomization(callback, customization, additionalParameters) {
		if (typeof customization !== 'undefined') {
			var data = {'customization': customization};

			var isWishlist = false;
			if (additionalParameters && additionalParameters.isWishlist) {
				isWishlist = true;
				delete additionalParameters.isWishlist;
			}

			if (typeof additionalParameters !== 'undefined') {
				data = $.extend({}, additionalParameters, data);
			}

			$.ajax({
				url: app.urls.saveCustomization,
				data : data,
				type : 'POST',
				dataType : 'JSON'
			}).success(function (response) {
				if (isWishlist && response.success) {
					addCustomizationIDToStorage(customization, response.customizationID);
				}
				if (typeof callback === 'function') {
					callback(response);
				}
			}).fail(
				saveProductCustomizationError
			);
		}
	}

	/* Save customizationID to use in app.product.configurator.utils.js after page reload */
	function addCustomizationIDToStorage(customization, customizationID) {
		var compareKey;

		try {
			var customizationObject = JSON.parse(customization);
			var patchUUID = false;
			if (Object.prototype.hasOwnProperty.call(customizationObject, 'activePatchesIndexes') && customizationObject.activePatchesIndexes.length > 0) {
				patchUUID = customizationObject.activePatchesIndexes[0].uuid;
				compareKey = $cache ? localStorage.getItem($cache.compareKey) : getCompareKey(customizationObject.activePatchesIndexes[0].masterPid);
			}
		}
		catch (e) {
			Logger('log', 'Cannot get patchUUID from customization JSON');
			return false;
		}

		if (compareKey !== null) {
			try {
				var currentArray = JSON.parse(compareKey);
				for( var i = 0, len = currentArray.length; i < len; i++ ) {
					if (currentArray[i][0].uuid === patchUUID) {
						currentArray[i][0].wl_customizationID = customizationID;
						localStorage.setItem($cache.compareKey, JSON.stringify(currentArray));
					} else {
						delete currentArray[i][0].wl_customizationID;
					}
				}
			}
			catch (e) {
				Logger('log', 'Cannot parse JSON of compare object inside the LocalStorage');
			}
		}
		return false;
	}

	function updateFormObj(data) {
		$cache.formObject = data;
	}
	
	function setActivePatches(id, data) {
		setCompareID( id );
		if ($cache && $cache.board && $cache.board.length > 0) {
			boardContainer.find('.' + $cache.hasPatchCls).removeClass($cache.hasPatchCls);
			for (var i in data) {
				var item = data[i].item;
				boardContainer.find('.'+item).addClass($cache.hasPatchCls);
			}
			$cache.board.setActivePatches(data);
			$cache.activePatches = data;
			return true;
		}
		return false;
	}

	function setCompareID( id ) {
		$cache.compareId = id;
	}
	
	function inited() {
		return $cache && $cache.board && $cache.board.length > 0;
	}

	function reset() {
		initVariantDataCache();
		deInitializeEvents();
		$cache.board.setValue('id',null);
		$cache.board.setValue('items',[]);
		$cache.board.setValue('price',null);
		$cache.board.setValue('size',null);
		$cache.board.off('loaded');
		$($cache.itemsContainerSel).empty();
		$($cache.sidesContainerSel).empty();

		$cache.board.setValue('patches',[]);
		$($cache.tabsContainerSel).empty();
		$($cache.patchesContainerSel).empty();

		$($cache.imageContainerSel).empty();
		$($cache.boardContainer).find( $cache.configuratorSaveBtnSel ).remove();
		$($cache.boardContainer).find( $cache.priceContainerSel ).empty();
		$($cache.boardContainer).find( $cache.itemsToRemoveToResetCofiguratorSel ).remove();
		$($cache.controllersContainerSel).children(':not('+$cache.priceActionsSel+')').remove();

		for( var vIndex in $cache.variantsData ) {
			for( var itemIndex in $cache.variantsData[vIndex].items ) {
				if ( typeof $cache.variantsData[vIndex].items[itemIndex]['element'] !== 'undefined' ) {
					delete $cache.variantsData[vIndex].items[itemIndex]['element'];
				}
			}
		}

		settings['activePatches'] = [];
		$cache.currentVariantData = null;
		$cache.board = null;
	}
	
	function reinit(params) {
		var tmpLoadIndicator = {
			loadIndicator : {
				partsTotal: 0,
				postLoadingDelay: $cache.loadIndicator.postLoadingDelay
			}
		};
		initLoader( tmpLoadIndicator );
		changeCurrentVariant(params.id);
		updateCachedCustomProducts();
		if ( $cache.board ) {
			$cache.board.setValue('id', params.id);
			$cache.board.setValue('price', params.price);
			$cache.board.setValue('size', params.size);
			var activeItem = $cache.board.activateItem(0);
			for (var sideId in activeItem.sides) {
				if (sideId === activeItem.sidesPositions[0]) {
					activeItem.sides[sideId].activate();
				}
			}
			$cache.board.activatePatchesTab(0);
			$cache.board.refresh();
			$cache.board.adaptToScreenHeight();
		}
		$cache.currentProductId = params.id;
		$cache.showLandingModal = ($cache.currentProductId === $cache.masterId) || ($cache.promotions !== null);
	}

	function changeConfiguratorType( type ) {
		settings.type = type;
	}

	function getRealMasterID() {
		return $cache.realMasterId;
	}

	/** ************* app.product.configurator public object ************** */
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.configurator = {
		init: function(params) {
			if(isConfigurable){
				initializeCache( params );

				if(app.device.isMobileView()) {
					initializeMobileTooltip();
					initializeMobileShare();
				}

				$cache.document.on('configurator.init', function(e, data){
					if (!$cache.board || $cache.board.length === 0) {
						initVariantDataCache();
						if (typeof data === 'object') {
							data = $.extend({}, data, params);
						}

						$cache.activePatches = (data.activePatches) ? data.activePatches : [];
						initializeConfigurator(data);
						initializeEvents();
					}
				});
				if (app.components.product.configurator.utils) {
					app.components.product.configurator.utils.init();
				}
			}
		},

		setBeforeAddtocartFunction : function (func) {
			settings.beforeAddtocartFunction = func;
		},

		saveProductCustomization : saveProductCustomization,

		saveProductCustomizationError : saveProductCustomizationError,

		isConfigurable : isConfigurable,

		isFreePurchasable : freePurchasable,
		
		setActivePatches : setActivePatches,
		
		updateFormObj: updateFormObj,
		
		inited : inited,
		
		reinit: reinit,

		hideElements: hideElements,

		reset: reset,

		reInitVariantDataCache: initVariantDataCache,

		changeConfiguratorType: changeConfiguratorType,

		setCompareID: setCompareID,

		logger: Logger,

		getRealMasterID: getRealMasterID
	};

})((window.app = window.app || {}), jQuery);
