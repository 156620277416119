/**
 * lib.product.configurator.js
 *
 */
(function ( $ ) {

	$.fn.configurator = function( options ) {

		var board,
			deviceRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? Math.floor(window.devicePixelRatio) : 1,
			preciseDeviceRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? window.devicePixelRatio : 1,
			htmlBoardElement = this,
			formObject = {},
			$window = $(window),
			mouseMoveTimeout = null,
			compareData = [],
			dragEnabled = typeof interact !== 'undefined',
			settings = $.extend({}, {
				"mode" : "click",
				patchClickEvent: Modernizr.touchevents ? 'touchstart' : 'click',
				"autoInit" : false,
				"configuratorContainer" : ".js-configurator",
				"configuratorPrice" : ".js-configurator-price",
				"configuratorPriceActions" : ".js-configurator-price-actions",
				"itemsContainer" : ".js-configurator-items",
				"sidesContainer" : ".js-configurator-sides",
				"imageContainer" : ".js-configurator-images",
				"imageWrapper" : ".js-configurator-content-wrapper",
				"patchesContainer" : ".js-configurator-patches",
				"tabsContainer" : ".js-configurator-tabs",
				"patchesLine" : ".js-configurator-line",
				"patchesWrap" : ".js-configurator-patches-tabs",
				"controllersContainer" : ".js-configurator-controllers",
				"configuratorPopupSel": ".js-product-configurator-popup",
				"infoMessageClass": "info-message",
				"imageWrapperTabletClass": "b-product-configurator-content-tablet",
				"freeTextInputName" : "free-text-input",
				"adaptSizeToElement" : null,
				"resizingFactors" : null,
				"positions" : null,
				"translateFunction" : null,
				"beforeCallback" : null,
				"errorCallback" : null,
				"fitScreen" : false,
				"activePatches" : null,
				"saveCallback": null,
				"loadCallback": null,
				"afterChooseCallback": null,
				"isMobile": false,
				"maxElements": 21,
				"powLimit": 12,
				"approximation": Math.ceil($(window).height() / 160),
				"fontColor": "black",
				"tabsOrder": null,
				"typeCssClass": null,
				"isDebug": false,
				"specialSymbolsHeight": ".,!$();:I",
				"side": {},
				"sideHeight": {}
			}, options );

		var saveEnabled = typeof settings.saveCallback === "function" && typeof settings.loadCallback === "function";

		/**
		 * @function
		 * @description Generates an unique ID
		 * @return {String}
		 */
		var uuid = function() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}
			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		};

		function getXY(event, parentNode, forceX, forceY){
			var target = event.target,
				targetViewportOffset = target.getBoundingClientRect(),
				parent = typeof parentNode !=='undefined' ? parentNode : target.parentNode,
				parentViewportOffset = parent.getBoundingClientRect(),
				x = (parseFloat(target.getAttribute('data-x')) || (typeof forceX !== 'undefined' ? forceX : targetViewportOffset.left) + (target.width/2) / deviceRatio - parentViewportOffset.left) + (typeof forceX !== 'undefined' ? 0 : event.dx),
				y = (parseFloat(target.getAttribute('data-y')) || (typeof forceY !== 'undefined' ? forceY : targetViewportOffset.top) + (target.height/2) / deviceRatio - parentViewportOffset.top) + (typeof forceY !== 'undefined' ? 0 : event.dy),
				px = Math.round(x / parent.offsetWidth * 100),
				py = Math.round(y / parent.offsetHeight * 100);

			return {px: px, py: py, x: x, y: y};
		}
		
		/**
		 * Function is used to update canvas coordinates in order to calculate overlapping
		 */
		updateCanvasBounding = function(fabricObject, canvasMgr) {
			var center = fabricObject.getCenterPoint();
			var heigth = canvasMgr.containerHeight;
			var width = canvasMgr.containerWidth;
			var result = {};
			result.right = center.x + width / deviceRatio / 2;
			result.bottom = center.y + heigth / deviceRatio / 2;
			result.left =  center.x - width / deviceRatio / 2;
			result.top = center.y - heigth / deviceRatio / 2;

			canvasMgr.bounding = result;
		}
		
		
		/**
		 * Used for getting images or text data in memory, without attaching to a page
		 * This data is required to check overlapping
		 */
		var CanvasMgr = function (config) {
			// lookup tables for marching directions
			var borderDx = [1, 0, 1, 1,-1, 0,-1, 1,0, 0,0,0,-1, 0,-1,NaN],
				borderDy = [0,-1, 0, 0, 0,-1, 0, 0,1,-1,1,1, 0,-1, 0,NaN],
				refreshTimeout = null;

			function getBorderStartPoint(grid) {
				var x = 0,
					y = 0;

				// search for a starting point; begin at origin
				// and proceed along outward-expanding diagonals
				while (true) {
					if (grid(x,y)) {
						return [x,y];
					}
					if (x === 0) {
						x = y + 1;
						y = 0;
					} else {
						x = x - 1;
						y = y + 1;
					}
				}
			}

			this.getImageBorders = function(grid, start, approximation) {
				var s = start || getBorderStartPoint(grid), // starting point
					c2 = [],    // contour polygon
					x = s[0],  // current x position
					y = s[1],  // current y position
					dx = 0,    // next x direction
					dy = 0,    // next y direction
					pdx = NaN, // previous x direction
					pdy = NaN, // previous y direction
					i = 0,
					minX = Number.MAX_SAFE_INTEGER || 100000, // IE support
					minY = Number.MAX_SAFE_INTEGER || 100000, // IE support
					maxX = 0,
					maxY = 0,
					count = 0, 
					canvasSize = {
						width: config.container.width() * deviceRatio,
						height:config.container.height() * deviceRatio
					};

				do {
					// determine marching squares index
					i = 0;
					if (grid(x-1, y-1)) i += 1;
					if (grid(x,   y-1)) i += 2;
					if (grid(x-1, y  )) i += 4;
					if (grid(x,   y  )) i += 8;

					// determine next direction
					if (i === 6) {
						dx = pdy === -1 ? -1 : 1;
						dy = 0;
					} else if (i === 9) {
						dx = 0;
						dy = pdx === 1 ? -1 : 1;
					} else {
						dx = borderDx[i];
						dy = borderDy[i];
					}

					// update contour polygon
					if(!c2.hasOwnProperty(x)){
						c2[x] = [{'max': y, 'min': y}];
						count++;
					}
					else{
						if(y > c2[x][0].max){
							c2[x][0].max = y;
						}
						if(y < c2[x][0].min){
							c2[x][0].min = y;
						}
					}
					if(y > maxY){
						maxY = y;
					}
					if(y < minY){
						minY = y;
					}
					if(x > maxX){
						maxX = x;
					}
					if(x < minX){
						minX = x;
					}
					pdx = dx;
					pdy = dy;

					x += dx;
					y += dy;
				} while ((s[0] !== x || s[1] !== y) && count <= canvasSize.width);

				//TODO:PRCONF:V2 Can be improved
				// once we get non-transparent area, check if we have transparent blocks inside it
				var prevX = 0;
				for( var x in c2 ) {
					x = parseInt(x);
					if (isNaN(x) || x - prevX < approximation) {
						continue;
					}
					prevX = x;
					for( var y=c2[x][0].min; y < c2[x][0].max; y = y+approximation ) {
						// check min/max with approximation to have smooth angles
						if (!grid(x,parseInt(y)) && y < (c2[x][0].max - approximation) && y > (c2[x][0].min + approximation) ) {
							c2[x] = splitPoints( x, c2[x][0].min, c2[x][0].max );
							break;
						}
					}
				}

				return {'points': c2, 'minX': minX, 'minY': minY, 'maxX': maxX, 'maxY': maxY};

				/**
				 * @function
				 * @description Split points on y axis to handle empty areas inside canvas block
				 */
				function splitPoints( x, min, max ) {
					var result = [],
						nonTransparentPixels = [],
						currentMin = 0;

					for( var y=min; y <= max; y++ ) {
						if ( grid( x, y ) ) {
							nonTransparentPixels.push( y );
						}
					}

					currentMin = nonTransparentPixels[0];
					for( var i = 0, len = nonTransparentPixels.length; i < len; i++ ) {
						// check if next pixel is transparent
						if ( nonTransparentPixels[i]+1 !== nonTransparentPixels[i+1] || i === len-1 ) {
							result.push({ 'min': currentMin, 'max': nonTransparentPixels[i]});
							currentMin = nonTransparentPixels[i+1];
						}
					}

					return result;
				}
			};

			/**
			 * @function
			 * @description Resets the canvas image and its sizes
			 */
			this.refresh = function(wresFactor, hresFactor, fontSize){
				var isSourceAttached = true;
				if ('source' in config && !$.contains(document, config.source)) {
					isSourceAttached = false;
				}

				if(!isSourceAttached){
					config.container.append($(config.source));
				}

				var	containerWidth,
					containerHeight,
					width,
					height,
					top = 0,
					left = 0;
				
				if(wresFactor && hresFactor){
					this.wresFactor = wresFactor;
					this.hresFactor = hresFactor;
				}
				
				if(fontSize){
					this.fontSize = fontSize;
				}

				if(this.wresFactor && this.hresFactor){
					width = this.wresFactor;
					height = this.hresFactor;
				}
				else if('source' in config) {
					width = $(config.source).width();
					height = $(config.source).height();
				}
				else if(this.sourceText){
					this.canvasContext.font = this.fontSize * deviceRatio + "px " + config.font;
					this.canvasContext.textAlign = 'center';
					this.canvasContext.textBaseline = 'middle';
					if(this.sourceText.length === 1){
						width = this.canvasContext.measureText(this.sourceText).width * 2;
					}
					else{
						width = this.canvasContext.measureText(this.sourceText).width;
					}
					var userSymbolsArr = this.sourceText.split(''),
						isIncreaseHeightNeeded = false;
					for(var i=0, len=userSymbolsArr.length; i<len; i++){
						if(settings.specialSymbolsHeight.indexOf(userSymbolsArr[i]) !== -1){
							isIncreaseHeightNeeded = true;
							break;
						}
					}
					if(isIncreaseHeightNeeded){
						width = width * 3;
					}
					height = width;
				}

				if(!this.sourceText) {
					width = width * deviceRatio;
					height = height * deviceRatio;
				}

				containerWidth = Math.round(width);
				containerHeight = Math.round(height);

				if(this.fabricObj){
					var sizes = this.getSizes();
					containerWidth = sizes.width * deviceRatio;
					containerHeight = sizes.height * deviceRatio;
				}
				
				this.containerWidth = containerWidth;
				this.containerHeight = containerHeight;

				if(config.center && !this.sourceText){
					top = -height/2;
					left = -width/2;
				}

				this.canvas.get(0).setAttribute('width', containerWidth);
				this.canvas.get(0).setAttribute('height', containerHeight);

				if(!("noSize" in config && config.noSize)){
					this.canvas.css('width', containerWidth / deviceRatio);
					this.canvas.css('height', containerHeight / deviceRatio);
				}

				this.canvasContext.clearRect(0, 0, containerWidth, containerHeight);
				this.canvasContext.save();
				if(config.center){
					this.canvasContext.translate(containerWidth/2, containerHeight/2);
				}
				if(this.rotateAngle) {
					this.canvasContext.rotate(this.rotateAngle * Math.PI / 180);
				}
				if('source' in config){
					this.canvasContext.drawImage($(config.source).get(0), left, top, width, height);
				}
				else if(this.sourceText){
					this.canvasContext.fillStyle = settings.fontColor;
					this.canvasContext.globalAlpha = 1;
					this.canvasContext.font = this.fontSize * deviceRatio + "px " + config.font;
					this.canvasContext.textAlign = 'center';
					this.canvasContext.textBaseline = 'middle';
					this.canvasContext.fillText(this.sourceText, left, 0);
				}
				this.canvasContext.restore();

				if(!isSourceAttached) {
					$(config.source).detach();
				}

				if('source' in config || this.sourceText){

					var defineNonTransparent;

					if(this.sourceText){
						var canvasContext = this.canvasContext;
						var canvasSize = {
							width: config.container.width() * deviceRatio,
							height:config.container.height() * deviceRatio
						};
						var imageData = null;
						
						if (canvasSize.width > 0 && canvasSize.height > 0) {
							var imageData = this.canvasContext.getImageData(0, 0, canvasSize.width, canvasSize.height);
						}
						
						defineNonTransparent = function(x, y) {
							if (imageData) {
								//TODO: find out where this formula comes from and why it looks like this
								//+ 3: imageData.data gives us array where 1 pixel is 4 items from that array
								//going 1 by 1, 0 - R, 1 - G, 2 - B, 3 - A. We add 3 to the pixel position to take Alpha from RGBA
								return imageData.data[(y * imageData.width + x) * 4 + 3] > 0;
							}
							return canvasContext.getImageData(x, y, 1, 1).data[3] > 0;
						};
					}
					else{
						var imgData = this.canvasContext.getImageData(0, 0, containerWidth, containerHeight),
							data = imgData.data,
							cw = containerWidth;

						defineNonTransparent = function(x, y){
							//TODO: find out where this formula comes from and why it looks like this
							//+ 3: imageData.data gives us array where 1 pixel is 4 items from that array
							//going 1 by 1, 0 - R, 1 - G, 2 - B, 3 - A. We add 3 to the pixel position to take Alpha from RGBA
							return data[(y * cw + x) * 4 + 3] > 20;
						};
					}

					var approximation = ('approximation' in config && config.approximation !== null) ? config.approximation : settings.approximation,
						found = [],
						wIncrement,
						hIncrement;
					this.points = [];
					this.sizes = {};

					if((containerWidth / approximation) < 2 || (containerHeight / approximation) < 2){
						hIncrement = Math.floor(containerHeight / 2) - 1;
						wIncrement = Math.floor(containerWidth / 2) - 1;
					}
					else{
						wIncrement = approximation;
						hIncrement = approximation;
					}

					for(var ih = hIncrement; ih <= containerHeight - hIncrement; ih = ih + hIncrement){
						for(var iw = wIncrement; iw <= containerWidth - wIncrement; iw = iw + wIncrement){

							var avoid = found.find(function( obj ) {
								return iw >= obj.minX && iw <= obj.maxX && ih >= obj.minY && ih <= obj.maxY || null;
							});

							if(!avoid && defineNonTransparent(iw, ih)){

								var si = iw;
								do{
									si--;
								}
								while (defineNonTransparent(si, ih));
								si++;

								var border = this.getImageBorders(defineNonTransparent, [si, ih], approximation);
								found.push({
									'maxX': border.maxX,
									'maxY': border.maxY,
									'minX': border.minX,
									'minY': border.minY
								});

								for(var key in border.points){
									if(!this.points.hasOwnProperty(key)){
										this.points[key] = border.points[key];
									} else {
										for( var pointsIndex in border.points[key] ) {
											if (border.points[key].hasOwnProperty(pointsIndex)) {
												this.points[key] = this.points[key].concat(border.points[key][pointsIndex]);
											}
										}
									}
									if ( !$.isEmptyObject(this.sizes) ) {
										for( var pointsIndex in this.points[key] ) {
											if (this.points[key].hasOwnProperty(pointsIndex)) {
												border.minX = Math.min(key, this.sizes.minX);
												border.maxX = Math.max(key, this.sizes.maxX);
												border.minY = Math.min(this.points[key][pointsIndex].min, this.sizes.minY);
												border.maxY = Math.max(this.points[key][pointsIndex].max, this.sizes.maxX);
											}
										}
									}
								}
								
								this.sizes = {
									'width': this.containerWidth,
									'height': this.containerHeight,
									'maxX': border.maxX,
									'maxY': border.maxY,
									'minX': border.minX,
									'minY': border.minY
								}

								if(settings.isDebug){
									this.canvasContext.fillStyle = "rgba(255, 0, 0, 255)";

									for( var x in this.points ) {
										for( var yIndex in this.points[x] ) {
											this.canvasContext.fillRect( x, this.points[x][yIndex].min, 2, 2 );
											this.canvasContext.fillRect( x, this.points[x][yIndex].max, 2, 2 );
										}
									}
								}
							}

							if(settings.isDebug){
								this.canvasContext.fillStyle = "rgba(0, 255, 0, 255)";
								this.canvasContext.fillRect( iw, ih, 1, 1 );
							}
						}
					}
				}
			};
			
			this.getSizes = function(){
				var min = {
					x: Math.min(this.fabricObj.aCoords.bl.x, this.fabricObj.aCoords.br.x, this.fabricObj.aCoords.tl.x, this.fabricObj.aCoords.tr.x),
					y: Math.min(this.fabricObj.aCoords.bl.y, this.fabricObj.aCoords.br.y, this.fabricObj.aCoords.tl.y, this.fabricObj.aCoords.tr.y)
				}
				var max = {
					x: Math.max(this.fabricObj.aCoords.bl.x, this.fabricObj.aCoords.br.x, this.fabricObj.aCoords.tl.x, this.fabricObj.aCoords.tr.x),
					y: Math.max(this.fabricObj.aCoords.bl.y, this.fabricObj.aCoords.br.y, this.fabricObj.aCoords.tl.y, this.fabricObj.aCoords.tr.y)
				}
				return {width: Math.round(max.x - min.x), height: Math.round(max.y - min.y), min: min, max: max}
			}

			this.append = function(){
				if (config.attachCanvas || (settings.isDebug && !config.ignoreDebug)) {
					config.container.append(this.canvas);
				}
				
			};

			this.rotateAngle = 'rotateAngle' in config ? config.rotateAngle : null;
			this.wresFactor = 'w' in config ? config.w : null;
			this.hresFactor = 'h' in config ? config.h  : null;
			this.fontSize = config.fontSize;
			this.canvas = document.createElement('canvas');
			this.canvasContext = this.canvas.getContext('2d');
			this.canvas = $(this.canvas);
			this.sourceText = 'sourceText' in config ? config.sourceText : null;
			if(config.hide){
				this.canvas.css('opacity', 0);
			}

			this.append();
				
			if('cssClass' in config){
				this.canvas.addClass(config.cssClass);
			}

			$window.resize(function() {
				deviceRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? Math.floor(window.devicePixelRatio) : 1;
				preciseDeviceRatio = window.devicePixelRatio && window.devicePixelRatio > 1 ? window.devicePixelRatio : 1;
				clearTimeout(refreshTimeout);
				refreshTimeout = setTimeout(function () {
					this.refresh();
				}.bind(this), 500);
			}.bind(this));

			this.refresh();

			/**
			 * @function
			 * @description Checks if a canvas is fully contained in another canvas
			 * @param {CanvasMgr} containingCanvas
			 * @return {Boolean}
			 */
			this.isContained = function (containingCanvas) {
				if (!this.bounding ) {
					return;
				}
				if (containingCanvas.sizes.minX === containingCanvas.sizes.maxX ||
					containingCanvas.sizes.minY === containingCanvas.sizes.maxY) {
					containingCanvas.refresh();
				}
				var sourceBounding = this.bounding,
					destinationBounding = {right: 0, bottom: 0, left: 0, top: 0}; // do not have bounding have width and height of the container

				var lDistance = Math.floor(sourceBounding.left - destinationBounding.left) * deviceRatio,
					yDistance = Math.floor(sourceBounding.top - destinationBounding.top) * deviceRatio;

				for(var point in this.points){
					var pointWidthDistance = lDistance + parseInt(point);

					// TEMPORARY IE FIX TODO: understand
					if(typeof this.points[point] === 'object'){
						if ( !containingCanvas.points.hasOwnProperty(pointWidthDistance) || !isPointInContainingCanvas(this.points[point], containingCanvas.points[pointWidthDistance], yDistance, 'contains' ) ) {
							return false;
						}
					}
				}

				return true;
			};

			/**
			 * @function
			 * @description Checks intersection between source and destination canvas
			 * @param {CanvasMgr} compairingCanvas
			 * @return {Boolean}
			 */
			this.doesIntersects = function (compairingCanvas) {
				if (!this.bounding || !compairingCanvas.bounding) {
					return;
				}
				var sourceBounding = this.bounding, 
					destinationBounding = compairingCanvas.bounding;

				var lDistance = Math.floor(sourceBounding.left - destinationBounding.left) * deviceRatio,
					yDistance = Math.floor(sourceBounding.top - destinationBounding.top) * deviceRatio;

				for(var point in this.points){
					var pointWidthDistance = lDistance + parseInt(point);

					if ( pointWidthDistance >= 0 && 
						compairingCanvas.points.hasOwnProperty(pointWidthDistance) &&
						isPointInContainingCanvas(this.points[point], compairingCanvas.points[pointWidthDistance], yDistance, 'intersects' ) ) {
						return true;
					}
				}

				return false;
			};

			/**
			 * @function
			 * @description Checks points on X axis in two canvases
			 * @param {array} canvasPoints
			 * @param {array} containinCanvasPoints
			 * @param {int} yDistance
			 * @param {string} checkType - possible values 'contains', 'intersects'
			 * @return {Boolean}
			 */
			function isPointInContainingCanvas( canvasPoints, containinCanvasPoints, yDistance, checkType ) {
				var canvasPointsArray = [],
					containinCanvasPointsArray = [];
				for( var pointIndex in canvasPoints ) {
					var pointMin = canvasPoints[pointIndex].min + yDistance;
					var pointMax = canvasPoints[pointIndex].max + yDistance;
					for( var y=pointMin; y<=pointMax; y++ ) {
						canvasPointsArray.push(y);
					}
				}
				for( var pointIndex in containinCanvasPoints ) {
					var pointMin = containinCanvasPoints[pointIndex].min;
					var pointMax = containinCanvasPoints[pointIndex].max;
					for( var y=pointMin; y<=pointMax; y++ ) {
						containinCanvasPointsArray.push(y);
					}
				}
				if ( checkType === 'contains' ) {
					return canvasPointsArray.every(function(val) {
						return containinCanvasPointsArray.indexOf(val) >= 0; 
					});
				}
				else if ( checkType === 'intersects' ) {
					for( var i=0, len = canvasPointsArray.length; i<len; i++ ) {
						if ( containinCanvasPointsArray.indexOf(canvasPointsArray[i]) !== -1 ) {
							return true;
						}
					}
				}

			}
		};


		/**
		 * @function
		 * @description Returns the position for the given Side
		 * @param {Side} side
		 * @return {Boolean}
		 */
		var getPosition = function(side){
			if(settings.positions){
				return settings.positions.find(function( obj ) {
					return obj.side === side;
				});
			}
			else{
				return null;
			}
		};
		
		/**
		 * @function
		 * @description Returns object from array of objects by object's key
		 */
		var getObjectByValueFromArray = function(array, findInProprtyId, propertyValue){
			if(array === 'getSettingsPatches'){
				array = settings.patches;
			}
			if(array === 'getSettingsActivePatches'){
				array = settings.activePatches;
			}
			if(array && findInProprtyId && propertyValue) {
				return array.find(function(obj){
					return obj[findInProprtyId] === propertyValue;
				});
			}
			return null;
		};

		/**
		 * @function
		 * @description Calculates the resize factor for given values
		 * @param {INT} dimension
		 * @param {INT} resFactor
		 * @return {Boolean}
		 */
		var calcResizeFactor = function(dimension, resFactor){
			return dimension * (resFactor / 100);
		};


		/**
		 * @function
		 * @description Returns the resizing factor for the given size
		 * @param {INT} dimension
		 * @param {INT} size
		 * @return {Boolean}
		 */
		var getSizeResizingFactor = function(dimension, size) {
			if (typeof settings.resizingFactors[size] !== "undefined") {
				return calcResizeFactor(dimension, settings.resizingFactors[size]);
			} else {
				return dimension;
			}
		};

		/**
		 * @function
		 * @description Returns the resizing factor for the given parameters
		 * @param {INT} dimension
		 * @param {INT} size
		 * @param {INT} resFactor1
		 * @param {INT} resFactor2
		 * @return {Boolean}
		 */
		var calcFinalResizeFactors = function (dimension, size, resFactor1, resFactor2) {
			var ret = getSizeResizingFactor(dimension, size);
			ret = calcResizeFactor(ret, resFactor1);
			ret = calcResizeFactor(ret, resFactor2);
			return Math.round(ret);
		};

		/**
		 * @function
		 * @description Translates the given message with the given resource callback
		 * @param {String} text
		 * @return {String}
		 */
		var translate = function(text, params) {
			if(typeof settings.translateFunction === "function") {
				return settings.translateFunction(text, params);
			}
			else{
				return text;
			}
		};

		/**
		 * Main Renderer class that renders simple html elements
		 */
		var Renderer = function(id, config, container, cssClass, analyticsClass){
			this.id = id;
			this.span = null;
			this.forceRender = false;
			this.htmlElement = null;
			this.active = false;
			this.elementType = typeof config.elementType !== "undefined" ? config.elementType : "div";
			this.attachName = true;
			this.enableClick = true;
			this.container = container;
			this.cssClass = cssClass;
			this.analyticsClass = analyticsClass;
		};

		/**
		 * @function
		 * @description Deactivates the standard html element
		 */
		Renderer.prototype.deactivate = function(){
			if(this.htmlElement){
				this.htmlElement.removeClass('active');
			}
			this.active = false;

			if(this.hasOwnProperty('afterDeactivate') && typeof this.afterDeactivate === 'function'){
				this.afterDeactivate();
			}

			return this;
		};

		/**
		 * @function
		 * @description Activates the standard html element
		 */
		Renderer.prototype.activate = function () {
			this.htmlElement.addClass('active');

			return this;
		};

		/**
		 * @function
		 * @description Appends the standard html element to the DOM
		 */
		Renderer.prototype.append = function () {
			this.container.append(this.htmlElement);

			return this;
		};

		/**
		 * @function
		 * @description Removes the standard html element from the DOM
		 */
		Renderer.prototype.unappend = function () {
			this.htmlElement.remove();

			return this;
		};

		/**
		 * @function
		 * @description Disables the current element
		 */
		Renderer.prototype.disable = function () {
			this.active = false;
			this.unappend();

			return this;
		};

		/**
		 * @function
		 * @description Renders the current element using the options provided inside the constructor
		 */
		Renderer.prototype.render = function () {
			if(this.forceRender || this.htmlElement === null){
				if(!this.span){
					this.span = $(document.createElement('span')).addClass('topnav-item-title');
				}
				this.htmlElement = document.createElement(this.elementType);

				if(this.enableClick){
					this.htmlElement.onclick = function(){
						this.activate(function(){
							board.adaptToScreenHeight();
						});
					}.bind(this);
				}

				this.htmlElement = $(this.htmlElement);

				if(typeof this.cssClass !== "undefined"){
					this.htmlElement.addClass(this.cssClass + " " + this.id.toLowerCase());
					if(this.analyticsClass){
						this.htmlElement.addClass(this.analyticsClass).attr('data-type', this.id.toLowerCase());
					}
				}
				else{
					this.htmlElement.addClass(this.id);
				}
				if(this.attachName){
					this.span.text(translate(this.id));
				}

				this.htmlElement.html(this.span);

				this.forceRender = false;
			}

			return this;
		};

		/**
		 * Side class, each Item has various sides, for example: Shoe back side, shoe front side..
		 */
		var Side = function(id, config, container, imageContainer, changeSideCallback, parent) {
			Renderer.call(this, id, config, container, settings.sidesConfig.cssClass);

			this.imageElement = false;
			this.coverImageElement = false;
			this.canvas = false;
			this.fabric = false;
			this.naturalWidth = null;
			this.loaded = false;
			this.attachedPatches = [];
			this.item = parent;
			this.shape = config.shape;
			this.zoomCoefficient = config.zoomCoefficient || settings.zoomConfig.zoomCoefficient;
			
			settings.side[id] = this;

			this.getResizingFactor = function(){
				return (this.imageElement.width() / this.naturalWidth) * 100 / deviceRatio;
			};

			/**
			 * @function
			 * @description Called by the callback when a Patch is added to the current side
			 * This function is because the Side must be aware of the patches added on it to re-attach each one of them when changing sides or item
			 * @param {Patch} patch
			 */
			this.addPatch = function (patch) {
				if(this.attachedPatches.length === 0){
					this.htmlElement.addClass('attached');
				}
				this.attachedPatches.push(patch);

				return this;
			};

			/**
			 * @function
			 * @description Called by the callback when a Patch is removed from the current side
			 * @param {Patch} patch
			 */
			this.removePatch = function (patch) {
				var index = this.attachedPatches.indexOf(patch);
				if (index > -1) {
					this.attachedPatches.splice(index, 1);
				}
				if(this.attachedPatches.length === 0){
					this.htmlElement.removeClass('attached');
				}
			};

			/**
			 * @function
			 * @description Appends the main side image
			 * We need the callback because we calculate the image sizes on the FE and so during the boot up if we have to re-compose
			 * an old configuration or the local storage each patch must be added only after all previous images are loaded
			 * so we added a callback on all Side and Patch images releated function to use it in the "asyncLoop" inside the function
			 * Board.setActivePatches
			 * @param {Function} callbackFunction
			 */
			this.appendImage = function (callbackFunction) {
				if(!this.loaded){
					/*
					* Main Image
					* */
					this.imageElement = document.createElement('img');
					this.imageElement.src = config.image;

					imageContainer.html('');
					board.loading();
					var originalElement = this.imageElement;
					this.imageElement = $(this.imageElement);
					this.imageElement.addClass(config.imageCssClass);

					this.imageElement.on('load error', function () {
						this.naturalWidth = originalElement.naturalWidth;

						imageContainer.append(this.imageElement);
						this.mainImage = new CanvasMgr({
							source: this.imageElement[0],
							approximation: config.approximation,
							container: imageContainer,
							noSize: true,
							center: false,
							cssClass: config.imageCssClass,
							attachCanvas : false,
							ignoreDebug: true
						});
						
						if(typeof callbackFunction === 'function'){
							callbackFunction();
						}
						board.loaded();
					}.bind(this));

					/*
					* Cover Image ( used for transparency calculation )
					* */
					this.coverImageElement = document.createElement('img');
					this.coverImageElement.src = config.coverImage;
					this.coverImageElement = $(this.coverImageElement);
					this.coverImageElement.addClass(config.imageCssClass);
					this.coverImageElement.css('visibility', 'hidden');

					board.loading();
					this.coverImageElement.on('load error', function () {
						imageContainer.append(this.coverImageElement);
						this.canvas = new CanvasMgr({
							source: this.coverImageElement,
							approximation: config.approximation,
							container: imageContainer,
							noSize: true,
							center: false,
							hide: true,
							cssClass: config.coverImageCssClass,
							attachCanvas : true
						});

						board.loaded();
					}.bind(this));

					this.loaded = true;
				}
				else{
					imageContainer.html(this.imageElement);
					imageContainer.append(this.canvas.canvas);

					if(typeof callbackFunction === 'function'){
						callbackFunction();
					}
				}

				return this;
			};

			this.refreshImage = function (callbackFunction) {
				var sidePreviewContainerSel =  '.' + settings.sidesConfig.sidePreview.container.jsClass;
				
				this.imageElement = $(this.imageElement);

				if(typeof callbackFunction === 'function'){
					callbackFunction();
				}

				// set Fabric size in according to main image size
				this.imageElementWidth = this.imageElement.width();
				this.imageElementHeight = this.imageElement.height();
				
				this.fabric.fabricCanvas.setHeight(this.imageElementHeight);
				this.fabric.fabricCanvas.setWidth(this.imageElementWidth);

				if (this.canvas) {
					this.canvas.canvas.css({
						'height': this.imageElementHeight,
						'width': this.imageElementWidth
					});
					this.canvas.refresh();
				}
				
				if(app.device.currentDevice() === 'desktop') {
					$(sidePreviewContainerSel).find('.' + settings.sidesConfig.sidePreview.prev.jsClass + ',.' + settings.sidesConfig.sidePreview.next.jsClass).css('height', this.imageElementHeight);
				}
			}

			this.getPreviewSidePosition = function(direction) {
				var direction  = direction || 'next';
				
				var sidePosition = this.item.sidesPositions.indexOf(this.id);
				var currentSidePosition = sidePosition;
				
				if(direction === 'next') {
					if(currentSidePosition < this.item.sidesPositions.length-1){
						sidePosition++;
					} else {
						sidePosition = 0;
					}
					
				} else {
					if(currentSidePosition > 0){
						sidePosition--;
					} else {
						sidePosition = this.item.sidesPositions.length-1;
					}
				}
				
				return sidePosition;
			}

			this.getSidePreview = function(direction) {
				var direction = direction || 'next';
				var currentVariantId = app.resources.CONF.VARIANTS.defaultVariantId;
				var variantsData = app.resources.CONF.VARIANTS.variantsData;
				var currentVariantsDataIndex = 0;
				var previewSidePosition = this.getPreviewSidePosition(direction);
			
				while(+currentVariantId !== +variantsData[currentVariantsDataIndex].id) {
					currentVariantsDataIndex++;
				}

				var previewSideSettings = variantsData[currentVariantsDataIndex]['items'][this.item.id]['sides'][this.item.sidesPositions[previewSidePosition]];

				var previewSide = {
					side: this.item.sidesPositions[previewSidePosition], 
					imgUrl: previewSideSettings.image ? previewSideSettings.image : '',
					transformation: previewSideSettings.transformation ? $.trim(previewSideSettings.transformation) : '',
					shape: previewSideSettings.shape ? $.trim(previewSideSettings.shape) : ''
				};

				return previewSide;
			}

			this.setPreviewSideCss = function(direction, previewSide) {
				var previewSideBlockSel = '.'  + settings.sidesConfig.sidePreview[direction].jsClass;
				var transformationPreviewSideClass = previewSide.transformation;
				var shapePreviewSideClass = previewSide.shape;

				$(previewSideBlockSel).removeClass(settings.sidesConfig.sidePreview.transformationClasses + ' ' + settings.sidesConfig.sidePreview.shapeClasses);
				
				if(transformationPreviewSideClass) {
					$(previewSideBlockSel).addClass('m-' + transformationPreviewSideClass);
				}

				if(shapePreviewSideClass) {
					$(previewSideBlockSel).addClass('m-' + shapePreviewSideClass);
				}

				$(previewSideBlockSel)
					.find('.' + settings.sidesConfig.sidePreview.imgContainerClass.jsClass)
					.css('backgroundImage', 'url(' + previewSide.imgUrl + ')');
				
				return this;
			}
			
			this.buildPrevNextSidePreviewImages = function() {
				var previewPrevSide = this.getSidePreview('prev');
				var previewNextSide = this.getSidePreview('next');
				
				this.setPreviewSideCss('prev', previewPrevSide);
				this.setPreviewSideCss('next', previewNextSide);
				
				return this;
			}
			
			/**
			 * @function
			 * @description Activates a Side ( if its Item parent is not active it will be activated too )
			 * We need the callback because we calculate the image sizes on the FE and so during the boot up if we have to re-compose
			 * an old configuration or the local storage each patch must be added only after all previous images are loaded
			 * so we added a callback on all Side and Patch images releated function to use it in the "asyncLoop" inside the function
			 *
			 * @param {Function} callbackFunction
			 * @param {Boolean} dontAttach
			 */
			this.activate = function (callbackFunction, dontAttach) {
				if(!this.active){
					if (board.activeItem && board.activeItem.activeSide) {
						board.updateHistory('changeSide', board.activeItem.activeSide, board.activeItem.activeSide.activate.bind(board.activeItem.activeSide), true);
					}
					
					if(typeof dontAttach === 'undefined'){
						dontAttach = false;
					}

					if(!this.item.active){
						this.item.activate(false);
					}

					changeSideCallback(this);
					var imageWrapper = $(settings.imageWrapper);

					if (this.id) {
						imageWrapper.addClass('m-side-' + this.id);
					}

					if (this.shape) {
						imageWrapper.addClass('m-side-' + this.shape);
					}

					if (settings.type) {
						imageWrapper.addClass('m-side-type-' + settings.type);
					}
					if (app.device.isTabletUserAgent()) {
						imageWrapper.addClass(settings.imageWrapperTabletClass);
					}
					imageWrapper.toggleClass(settings.imageWrapperTabletClass, app.device.isTabletUserAgent());
					this.appendImage(callbackFunction);
					
					// recreate fabric
					var fabricObjects = [];
					if(this.fabric.fabricCanvas) {
						fabricObjects = this.fabric.fabricCanvas.getObjects();
					} 
					
					// init, reinit fabric on the side 
					var defaultWidth = settings.defaultWidth;
					var defaultheight = settings.defaultHeight;
					this.fabric = new FabricWrapper(
						imageContainer,
						{width: this.imageElementWidth || defaultWidth, height: this.imageElementHeight || defaultheight}
					);
					
					this.fabricelements = [];
					
					if(!dontAttach){
						$.each(this.attachedPatches, function (key, elm) {
							elm.appendDestination();
						}.bind(this));
						
						fabricObjects.forEach(function(obj) {
							this.fabric.fabricCanvas.add(obj);
						}.bind(this));
						this.fabric.fabricCanvas.renderAll();
					}

					this.htmlElement.addClass('active');
					this.active = true;
					
					if(app.device.currentDevice() === 'desktop') {
						this.buildPrevNextSidePreviewImages();
					}
				}
				else if(typeof callbackFunction === "function"){
					callbackFunction();
				}

				return this;
			};

			this.afterDeactivate = function() {
				$(settings.imageWrapper).removeClass(function(index, classes) {
					return (classes.match (/m-side\S+/g) || []).join(' ');
				});

				$.each(this.attachedPatches, function (key, patch) {
					patch.sourceElementDisable();
				});
			}
		};

		Side.prototype = Object.create(Renderer.prototype);

		/**
		 * Item class, represents the items on where attach the patches. For example: Right shoe, Left shoe
		 */
		var Item = function(id, config, container, sidesContainer, imageContainer, changeItemCallback) {
			Renderer.call(this, id, config, container, settings.itemsConfig.cssClass, settings.itemsConfig.analyticsClass);

			this.forceRenderSides = false;
			this.sides = {};
			this.sidesPositions = [];
			this.activeSide = null;

			/**
			 * @function
			 * @description Adds the patch to the current active side
			 * TODO check if is still used and needed
			 * @param {Patch} patch
			 */
			this.addPatch = function (patch) {
				this.activeSide.addPatch(patch);

				return this;
			};

			/**
			 * @function
			 * @description Function called by the Side when activated to disable the current active Side
			 * @return {Item}
			 */
			this.changeSideCallback = function (side) {
				board.tabChangedSide = board.getCurrentBoardSidekey();
				if(this.activeSide){
					this.activeSide.deactivate();
				}
				this.activeSide = side;
				setTimeout(function() {
					board.patchSidesMatcher(side);

					// Update tabs status
					board.updatePatchesTabsState();
				}, 0);

				return this;
			};

			/**
			 * @function
			 * @description Renders the sides from the current Item and, if there is no active side by config, activates the first one
			 * @return {Item}
			 */
			this.renderSides = function () {
				if(this.forceRenderSides || $.isEmptyObject(this.sides.length)){
					var index = 0;

					if(this.activeSide){
						this.activeSide.activate(function(){
							board.adaptToScreenHeight();
						});
					}

					$.each(config.sides, function (key, side) {
						side = $.extend({}, side, settings.sidesConfig);
						if(typeof this.sides[key] === "undefined"){
							this.sides[key] = side.element = new Side(key, side, sidesContainer, imageContainer, this.changeSideCallback.bind(this), this);
							this.sidesPositions.push(key);
							this.sides[key].render();
						}
						if(this.active){
							this.sides[key].append();

							if(!this.activeSide && index === 0){
								this.sides[key].activate(function(){
									board.adaptToScreenHeight();
								});
							}
						}
						index++;
					}.bind(this));
				}

				return this;
			};

			/**
			 * @function
			 * @description Appends the sides from the current Item
			 * @return {Item}
			 */
			this.appendSides = function () {
				$.each(config.sides, function (key) {
					this.sides[key].append();
				}.bind(this));

				return this;
			};

			/**
			 * @function
			 * @description Activate the item and attaches to the DOM its sides
			 * @param {Boolean} renderSides | defines if we need to render all the sides contained inside the current Item ( if we already rendered it )
			 * @return {Item}
			 */
			this.activate = function (renderSides) {
				if(!this.active){
					if (board.activeItem) {
						board.updateHistory('changeItem', board.activeItem, board.activeItem.activate.bind(board.activeItem), true);
					}

					if(typeof renderSides === 'undefined'){
						renderSides = true;
					}
					this.htmlElement.addClass('active');
					changeItemCallback(this);

					this.active = true;

					if(renderSides){
						this.renderSides();
					}
					else{
						this.appendSides();
					}
				}

				return this;
			};

			/**
			 * @function
			 * @description Disables the current item and remove its sides from the DOM
			 * @return {Item}
			 */
			this.disable = function () {
				$.each(this.sides, function (key, elm) {
					elm.unappend();
				}.bind(this));

				this.active = false;

				return this;
			};
			
			this.animateChangingSidePreview = function(direction, position) {
				var sidePreviewSettings = settings.sidesConfig.sidePreview;
				var sidePreviewBlock = (direction === 'next') ?  $('.' + sidePreviewSettings.next.jsClass) : $('.' + sidePreviewSettings.prev.jsClass);

				if(position === 'start') {
					sidePreviewBlock.addClass(sidePreviewSettings.sideLoadingClass).removeClass(sidePreviewSettings.hoverClass);
				} else {
					sidePreviewBlock.removeClass(sidePreviewSettings.sideLoadingClass).addClass(sidePreviewSettings.hoverClass);
				}

				return this;
			}

			/**
			 * @function
			 * @description Activates the next side starting from the currently active one.
			 * @return {Item}
			 */
			this.activateNextSide = function(){
				var nextSidePosition;
				var currentSidePosition = nextSidePosition = this.sidesPositions.indexOf(this.activeSide.id);

				if(app.device.currentDevice() === 'desktop') {
					this.animateChangingSidePreview('next', 'start');
				}

				if(currentSidePosition < this.sidesPositions.length-1){
					nextSidePosition++;
				}
				else{
					nextSidePosition = 0;
				}
				
				this.sides[this.sidesPositions[nextSidePosition]].activate(function() {
					board.adaptToScreenHeight();
				});

				if(app.device.currentDevice() === 'desktop') {
					this.animateChangingSidePreview('next', 'end');
				}

				return this;
			};
			
			/**
			 * @function
			 * @description Activates the previous side starting from the currently active one.
			 * @return {Item}
			 */
			this.activatePrevSide = function(){
				var prevSidePosition;
				var currentSidePosition = prevSidePosition = this.sidesPositions.indexOf(this.activeSide.id);
				
				if(app.device.currentDevice() === 'desktop') {
					this.animateChangingSidePreview('prev', 'start');
				}
				
				if(currentSidePosition > 0){
					prevSidePosition--;
				}
				else{
					prevSidePosition = this.sidesPositions.length-1;
				}

				this.sides[this.sidesPositions[prevSidePosition]].activate(function() {
					board.adaptToScreenHeight();
				});

				if(app.device.currentDevice() === 'desktop') {
					this.animateChangingSidePreview('prev', 'end');
				}
				
				return this;
			};

			this.init = function() {
				this.render();
				this.renderSides();
				container.append(this.htmlElement);

				return this;
			};
		};

		Item.prototype = Object.create(Renderer.prototype);

		/**
		 * Patch class, represents the patch to attach on each Item side
		 */
		var Patch = function(id, config, patchesContainer, imageContainer, addPatchCallback, patchTab) {
			this.id = id;
			this.patch_id = config.id;
			this.sourceElement = null;
			this.sourceElementContainer = null;
			this.sourceAppended = false;
			this.destinationElements = {};
			this.customConfiguration = config.customConfiguration;
			this.bannedSizes = typeof this.customConfiguration.bannedSizes !== 'undefined' ? this.customConfiguration.bannedSizes : [];
			this.visible = true;
			this.patchTab = patchTab;
			this.renderer = 'renderer' in this.customConfiguration ? this.customConfiguration.renderer : null;
			this.isDraggable = (typeof this.customConfiguration.noDrag !== 'undefined') ? !this.customConfiguration.noDrag : true;
			this.isRotatable = (typeof this.customConfiguration.noRotate !== 'undefined') ? !this.customConfiguration.noRotate : true;
			if ((this.customConfiguration.decreasingReducingFactor && this.customConfiguration.increasingReducingFactor
			&& this.customConfiguration.decreasingReducingFactor === this.customConfiguration.increasingReducingFactor)
			|| this.customConfiguration.noResize) {
				this.isResizable = false;
			} else {
				this.isResizable = true;
			}

			/**
			 * @function
			 * @description Adds the current patch to the current active side.
			 * Since from the version 3 we have the possibility to add more then one patch per Side we had to create another element called
			 * DestinationPatch so this function became a link to the new object
			 * @param {Function} callback
			 * @param {Int} x
			 * @param {Int} y
			 * @param {Int} rotateAngle
			 * @param {String} text
			 * @return {DestinationPatch}
			 */
			this.addPatch = function (callback, x, y, rotateAngle, text, attachCanvas) {
				return this.destinationElements[board.getCurrentBoardkey()] = new DestinationPatch({
					addPatchCallback: addPatchCallback,
					customConfiguration: config.customConfiguration,
					id: this.id,
					patch_id: this.patch_id,
					imageContainer: imageContainer,
					imageURL: config.imageURL,
					sourceElement: this,
					x: x,
					y: y,
					rotateAngle: rotateAngle,
					side: board.activeItem.activeSide,
					renderer: this.renderer,
					text: text,
					setAsActive: this.setAsActive,
					attachCanvas : attachCanvas
				}).addPatch(callback);
			};

			/**
			 * @function
			 * @description During the version 3 development we decided to provide a support to add the same patch more then one time per side so we need
			 * this function to temporarily make the click on an active Patch remove it.
			 * @return {Patch}
			 */
			this.togglePatch = function (callback) {
				this.setAsActive = true;
				this.addPatch(callback);
				return this;
			};

			/**
			 * @function
			 * @description removes the patch from the specified side.
			 * @return {Patch}
			 */
			this.removePatchFromSide = function (boardKey) {
				delete this.destinationElements[boardKey];

				//To remove if we will have the possibility to have two patches from the same source on the same side
				if(boardKey === board.getCurrentBoardkey()){
					this.sourceElementContainer.removeClass('active');
				}

				if(this.renderer === 'freetext'){
					this.sourceElement.val('');
				}

				return this;
			};

			this.refreshText = function (value, inputElement, activeObjects) {
				var seletedDetinationPatch = activeObjects.destinationPatch;
				
				// if fabric text selected, update, else create new
				if(value || inputElement.is(':focus')) {
					if (seletedDetinationPatch && seletedDetinationPatch.isFreeText) {
						seletedDetinationPatch.refreshText(value);
					} else {
						this.setAsActive = true;
						var patch = this.addPatch(undefined, undefined, undefined, undefined, value);
						/* TODO:PRCONF:V1 overlaping */
						if (patch) {
							patch.editText = true;
							patch.new = true;
							settings.errorCallback({
								patch: patch, 
								board: board,
								error: patch.getOverlaps() > 0 ? 'overlaped' : null
							});
						}
					}
				} else if(seletedDetinationPatch && !seletedDetinationPatch.canvas.sourceText) {
					seletedDetinationPatch.removePatch(true);
					board.zoomOut();
					settings.beforeCallback({type: 'hide'});
					this.patchTab.scrollFunction();
					inputElement.blur();
					board.activeItem.activeSide.fabric.fabricCanvas.forEachObject(function(obj) {
						obj.set('selectable', true);
						obj.set('opacity', 1);
						obj.set('hoverCursor', 'move');
					});
				} else {
					inputElement.val("");
				}
			};

			this.stopEditingMode = function () {
				if (board.getCurrentActivePatches().length > 0){
					board.activateControllers();
				}
			};

			/**
			 * @function
			 * @description Renders the patch source element ( the object that the user clicks to add the patch on the current Side )
			 * and attaches to it the onclick function.
			 * In the version 3 in order to speed up the boot up we used a smaller image for the Patch source: "thumbnailURL", the bigger one is given
			 * to the DestinationPatch only when the patch is attached on a Side.
			 * @return {Patch}
			 */
			this.renderSource = function () {
				if(!this.sourceElement && !this.sourceElementContainer){
					this.sourceElementContainer = $(document.createElement('div'));
					var $this = this;

					if(this.renderer === 'freetext'){
						this.sourceElement = document.createElement('input');
						this.sourceElement = $(this.sourceElement);
						this.sourceElementContainer.addClass(config.sourceElementCssClassFreeText);
						this.sourceElement.attr({
							type: 'text',
							placeHolder: translate('TYPE_HERE'),
							name: settings.freeTextInputName
						});

						this.sourceElement.on('focus', function() {
							$(this).attr('placeholder', "");
						});

						this.sourceElement.on('blur', function() {
							$(this).attr('placeholder', translate('TYPE_HERE'));
						});

						this.sourceElement.on('keypress', function(event) {
							var inputValue = event.which;
							if(inputValue >= 254 || inputValue === 248 || inputValue === 247 || inputValue === 230 || inputValue === 222 ||
									inputValue === 216 || inputValue === 215 || inputValue === 208 ||
									inputValue === 198 || inputValue === 197 || (inputValue >= 188 && inputValue <= 191) ||
									(inputValue >= 182 && inputValue <= 186) || (inputValue >= 172 && inputValue <= 180) ||
									(inputValue >= 160 && inputValue <= 170) || (inputValue >= 123 && inputValue <= 127) ||
									(inputValue >= 93 && inputValue <= 96 ) || inputValue === 91 || (inputValue >= 60 && inputValue <= 62) ||
									inputValue === 39 || (inputValue >= 1 && inputValue <= 31 ) || inputValue === 0)
							{
								event.preventDefault();
							}
						}.bind(this));

						//Prevent paste on free text area
						this.sourceElement.on("paste",function(e) {
							e.preventDefault();
						});

						var blurEventBinded = false;

						this.sourceElement.on('input focus', function() {
							var fabric = board.activeItem.activeSide.fabric;
							var activeObjects = fabric.getActiveObjects();
							
							var $elm = $(this);
							var value = $elm.val().toUpperCase();
							value = app.util.removeEmojis(value);
							
							if(!blurEventBinded){
								blurEventBinded = true;
								$elm.one('blur', function () {
									blurEventBinded = false;
									value = $elm.val().toUpperCase();
									if(value === ""){
										activeObjects = fabric.getActiveObjects();
										$this.refreshText(value, $elm, activeObjects);
									} else {
										$this.stopEditingMode();
									}
								});
							}

							$elm.val(value);
							$this.refreshText(value, $elm, activeObjects);
						});

						this.sourceElementContainer.html(this.sourceElement);
						patchTab.addWidth(100);
					}
					else {
						var canClick = true,
							isMoving = false;
						
						var tapToggle = function() {
							canClick = false;
							settings.logger('log', 'patch click');
							$this.togglePatch(function(destinationElement) {
								destinationElement.new = true;
								canClick = true;
							});
						};
						
						this.sourceElement = document.createElement('img');
						this.sourceElement = $(this.sourceElement);

						this.sourceElement.one('load error', function () {
							patchTab.addWidth(this.sourceElementContainer.outerWidth());
						}.bind(this));
						
						this.sourceElement.on(settings.patchClickEvent, function () {
							if (settings.patchClickEvent === 'click' && canClick) {
								tapToggle();
							}
						});
						
						if (settings.patchClickEvent === 'touchstart') {
							this.sourceElement.on('touchend', function() {
								if (!isMoving && canClick) {
									tapToggle();
								}
								isMoving = false;
							});
						}

						if (dragEnabled && !settings.isMobile && settings.patchClickEvent === 'click' && this.isDraggable) {
							var canvasClone,
								destinationElement;

							var fakePrevent = function(e){
								if(isMoving){
									e.preventDefault();
									e.stopPropagation();
								}
							};
							$this.sourceElement.on('touchmove', fakePrevent);
							
							var moveEnd = function(){
								var sideFabric = board.activeItem.activeSide.fabric.fabricCanvas;
								var fabricObj = destinationElement && destinationElement.fabricObj;
								
								destinationElement.new = true;
								if(fabricObj) {
									fabricObj.setPositionByOrigin(
										{
											x : sideFabric.width / 100 * destinationElement.x,
											y : sideFabric.height / 100 * destinationElement.y
										},
										'center',
										'center'
									);
									fabricObj.setCoords();
									var positions = destinationElement.getOutLimits({
										left: sideFabric.width / 100 * destinationElement.defaultPositions.left,
										top: sideFabric.height / 100 * destinationElement.defaultPositions.top
									});
									if (positions) {
										if (positions.changed) {
											fabricObj.setPositionByOrigin(
												{
													x : positions.left,
													y : positions.top
												},
												'center',
												'center'
											);

											destinationElement.x = positions.left / sideFabric.width * 100;
											destinationElement.y = positions.top / sideFabric.height * 100;
											
											fabricObj.setCoords();
											fabricObj.parent.canvasRefresh();
										}
											
										sideFabric.setActiveObject(fabricObj);
										sideFabric.renderAll();
										
										if (!settings.isDebug) {
											destinationElement.canvas.canvas.remove();
										}
										updateCanvasBounding(fabricObj, destinationElement.canvas);

										destinationElement.dragEndHandler(destinationElement.canvas.canvas.get(0));
									} else {
										return;
									}
								}
								canClick = true;
							}

							interact(this.sourceElement[0])
								.draggable({
									inertia: true,
									hold: settings.isMobile ? 500 : false,
									restrict: {
										restriction: settings.configuratorContainer,
										endOnly: true,
										elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
									},
									onstart: function (event) {
										settings.logger('log', 'create patch movestart');
										board.hideLoader = true;
										isMoving = true;
										canClick = false;

										board.activeItem.activeSide.canvas.canvas.css('opacity', 1);
										var callback = function(destinationPatch){
												destinationElement = destinationPatch;
												canvasClone = destinationPatch.canvas;
												if (!isMoving) {
													moveEnd();
												}
											},
											startPos = getXY(event, imageContainer.get(0));

										/*
										We add the destination patch passing as callback a function that will set the first position to the new element
										When the destination will be ready it will be put below the mouse giving it the first position
										We use this method to be able to use the standard dragEndHandler method from the destination element.
										 */
										$this.addPatch(callback, startPos.px, startPos.py, undefined, undefined, true);

										canvasClone = {};

									},
									// call this function on every dragmove event
									onmove: function (event) {
										/*
										We wait the canvasClone ( DestinationElement canvas ) to be ready and then we update its position
										 */

										if('canvas' in canvasClone){
											var target,
												pos;
											target = canvasClone.canvas.get(0);
											if(!target.getAttribute('data-x') && !target.getAttribute('data-y')){
												pos = getXY({target: target, dx: event.dx, dy: event.dy}, target.parentNode, event.clientX - (target.width/2/deviceRatio) , event.clientY - (target.height/2/deviceRatio));
												target.setAttribute('data-x', pos.x);
												target.setAttribute('data-y', pos.y);
											}
											else{
												pos = getXY({target: target, dx: event.dx, dy: event.dy});
											}

											destinationElement.x = pos.px;
											destinationElement.y = pos.py;
											target.style.left = pos.px + '%';
											target.style.top = pos.py + '%';

											target.setAttribute('data-x', pos.x);
											target.setAttribute('data-y', pos.y);
										}

									},

									// call this function on every dragend event
									onend: function () {
										settings.logger('log', 'create patch moveend');
										isMoving = false;
										board.hideLoader = false;
										
										if (destinationElement) {
											moveEnd();
										}
									}
								});
						}
						else {
							// fallback to handle touchmove event
							$this.sourceElement.on('touchmove', function() {
								isMoving = true;
							});
						}

						this.sourceElement.attr('src', config.thumbnailURL);
						this.sourceElementContainer.addClass(config.sourceElementCssClass);
						this.sourceElementContainer.html(this.sourceElement);
					}
				}

				return this;
			};

			/**
			 * @function
			 * @description Hides the patch source from the DOM ( the object that the user clicks to add the patch on the current Side )
			 * @return {Patch}
			 */
			this.hide = function () {
				if(this.visible){
					this.sourceElementContainer.hide();
					this.visible = false;
					patchTab.subWidth(this.renderer === "freetext" ? 100 : this.sourceElementContainer.outerWidth());
				}

				return this;
			};

			/**
			 * @function
			 * @description Shows the patch source on the DOM ( the object that the user clicks to add the patch on the current Side )
			 * @return {Patch}
			 */
			this.show = function () {
				if(!this.visible){
					this.sourceElementContainer.show();
					this.visible = true;
					patchTab.addWidth(this.sourceElementContainer.outerWidth());
				}

				return this;
			};

			/**
			 * @function
			 * @description Appends the patch source to the DOM ( the object that the user clicks to add the patch on the current Side )
			 * @return {Patch}
			 */
			this.appendSource = function(){
				if(!this.sourceAppended){
					patchesContainer.append(this.sourceElementContainer);
					this.sourceAppended = true;
				}

				return this;
			};

			/**
			 * @function
			 * @description Removes the patch source from the DOM ( the object that the user clicks to add the patch on the current Side )
			 * @return {Patch}
			 */
			this.unAppendSource = function(){
				if(this.sourceAppended){
					this.sourceElementContainer.remove();
					this.sourceAppended = false;
				}
			};

			this.init = function() {
				this.renderSource();
				this.appendSource();

				return this;
			};

			this.init();
		};

		/**
		 * DestinationPatch class, represents a copy o Patch that is attached on a Side.
		 * We created it because from the version 3 we have the possibility to add more then one patch per Side.
		 */
		var DestinationPatch = function (config) {
			this.uuid = uuid();
			this.id = config.id;
			this.config = config;
			this.patch_id = config.patch_id;
			this.setAsActive = config.setAsActive;
			this.canvas = null;
			this.destinationImg = null;
			this.tempPos = {};
			this.x = null;
			this.y = null;
			this.rotateAngle = typeof config.rotateAngle !== 'undefined' ? config.rotateAngle : 0;
			this.boardKey = board.getCurrentBoardkey();
			this.isDraggable = config.sourceElement.isDraggable;
			this.isRotatable = config.sourceElement.isRotatable;
			this.isResizable = config.sourceElement.isResizable;
			var refreshTimeout = null;
			var $this = this;

			/**
			 * @function
			 * @description Adds the patch to the current side
			 * We need the callback because we calculate the image sizes on the FE and so during the boot up if we have to re-compose
			 * an old configuration or the local storage each patch must be added only after all previous images are loaded
			 * so we added a callback on all Side and Patch images releated function to use it in the "asyncLoop" inside the function
			 * Board.setActivePatches
			 * @return {DestinationPatch}
			 */
			this.addPatch = function (callback) {
				var funcToCall = function(){
					this.appendDestination(function () {
						config.addPatchCallback(this);
						var savedPathData = getObjectByValueFromArray('getSettingsActivePatches', 'x', this.x);

						if(savedPathData && savedPathData.scaleOriginalWidth){
							this.scaleOriginalWidth = savedPathData.scaleOriginalWidth;
						}
						if(savedPathData && savedPathData.scaleOriginalHeight){
							this.scaleOriginalHeight = savedPathData.scaleOriginalHeight;
						}
						if(savedPathData && savedPathData.scaleInPercents){
							this.scaleInPercents = savedPathData.scaleInPercents;
						} else {
							this.scaleInPercents = 100;
						}
						this.font = {
							feSize : calcFinalResizeFactors(config.customConfiguration.fontSize, settings.size, 100, board.activeItem.activeSide.getResizingFactor()) * deviceRatio
						}

						this.updateFormObject();

						if(typeof callback === 'function'){
							callback(this);
						}

						if(board.getCurrentActivePatchesCount() >= settings.maxElements){
							board.maxWritingSymbols = true;
							settings.errorCallback({error: 'MAXWRITINGSANDSYMBOLS'});
						} else {
							board.maxWritingSymbols = false;
						}
						
						afterDrag = false;
					}.bind(this));
				}.bind(this);

				if(board.isLoading){
					htmlBoardElement.one('loaded', funcToCall);
				}
				else{
					funcToCall();
				}

				return this;
			};

			this.getOverlaps = function () {
				var intersectCount = 0;
				var elementsToRemove = [];
				if (!this.canvas.isContained(board.activeItem.activeSide.canvas)) {
					intersectCount++;
				} 
				$.each(board.getCurrentActivePatches(), function(key, elm) {
					if(elm.canvas !== this.canvas){
						if(this.canvas.doesIntersects(elm.canvas)){
							if (!elm.isDraggable) {
								elementsToRemove.push(elm);
							} else {
								intersectCount++;
							}
						}
					}
				}.bind(this));

				for (var i = 0, len = elementsToRemove.length; i < len; i++) {
					elementsToRemove[i].removePatch(true);
				}

				return intersectCount;
			};
			
			this.getOutLimits = function(config) {
				if (!this.fabricObj.canvas) {
					this.removePatch(true);
					settings.beforeCallback({type: 'hide'});
					return false;
				}
				updateCanvasBounding(this.fabricObj, this.canvas);
				var result = {
					left: this.canvas.bounding.left,
					top: this.canvas.bounding.top,
					changed: false
				}
				if(!this.canvas.isContained(board.activeItem.activeSide.mainImage)) {
					result = {
						left: config.left,
						top: config.top,
						changed: true
					}
				}
				
				return result;
			}

			/**
			 * @function
			 * @description Refreshes the canvas and updates the formObject
			 * @return {DestinationPatch}
			 */
			this.refresh = function(){
				var changedSide = false;
				var oldSide = null;
				if(config.side !== board.activeItem.activeSide){
					oldSide = board.activeItem.activeSide;
					config.side.activate();
					changedSide = true;
				}

				this.canvasRefresh();
				this.updateFormObject(true);

				if(changedSide){
					oldSide.activate();
				}

				return this;
			};

			/**
			 * @function
			 * @description Updates the formObject ( used by the addToCart function ) and the activePatches used inside the localStorage and the compare functions
			 * Both formObject and activePatches are also saved on BE side in all needed cases ( share, orders, etc...)
			 * @return {DestinationPatch}
			 */
			this.updateFormObject = function() {
				var customProduct = {
						"uuid" : this.uuid,
						"id" : config.patch_id,
						'text': this.canvas.sourceText,
						'renderer': config.renderer,
						'font': config.customConfiguration.font,
						'fontSize':  this.font && this.font.fontSize || config.customConfiguration.fontSize,
						'FEfontSize': this.canvas.fontSize || this.font && this.font.fontSize,
						'fontColor': settings.fontColor,
						"type": config.customConfiguration.type,
						'resizingFactor': config.customConfiguration.resizingFactor,
						"customProdWidth" : this.canvas.wresFactor,
						"customProdHeight" : this.canvas.hresFactor,
						"x": this.x,
						"y": this.y,
						"rotateAngle": this.rotateAngle,
						"scaleOriginalWidth" : this.scaleOriginalWidth,
						"scaleOriginalHeight" : this.scaleOriginalHeight,
						"scaleInPercents" : this.scaleInPercents
					},
					activeSideIndex = null;

				if(!formObject.hasOwnProperty("sides")){
					formObject.sides = [];
				} else {
					for(var sideEl in formObject.sides) {
						if(formObject.sides[sideEl].item === config.side.item.id && formObject.sides[sideEl].side === config.side.id){
							activeSideIndex = sideEl;
							break;
						}
					}
				}

				if(activeSideIndex !== null){
					var objectToUpdate = formObject.sides[sideEl].customProducts.find(function( obj ) {
						return obj.uuid === this.uuid;
					}.bind(this));

					if(typeof objectToUpdate !== 'undefined'){
						objectToUpdate.customProdWidth = customProduct.customProdWidth;
						objectToUpdate.customProdHeight = customProduct.customProdHeight;
						objectToUpdate.fontSize = customProduct.fontSize;
						objectToUpdate.FEfontSize = customProduct.FEfontSize;
						objectToUpdate.resizingFactor = customProduct.resizingFactor;
						objectToUpdate.text = customProduct.text;
						objectToUpdate.x = customProduct.x;
						objectToUpdate.y = customProduct.y;
						objectToUpdate.rotateAngle = customProduct.rotateAngle;
					}
					else{
						formObject.sides[activeSideIndex].customProducts.push(customProduct);
					}

					formObject.sides[activeSideIndex].mainProdWidth = config.side.imageElement.width();
					formObject.sides[activeSideIndex].mainProdHeight = config.side.imageElement.height();
					formObject.sides[activeSideIndex].resizingFactor = config.side.getResizingFactor();
					formObject.sides[activeSideIndex].sizeResizingFactor = settings.resizingFactors[settings.size];
				}
				else{
					var side = {
						"key" : this.boardKey,
						"side": config.side.id,
						"item": config.side.item.id,
						"mainProdWidth": config.side.imageElement.width(),
						"mainProdHeight": config.side.imageElement.height(),
						"resizingFactor": config.side.getResizingFactor(),
						"sizeResizingFactor": settings.resizingFactors[settings.size],
						"customProducts": [customProduct]
					};
					formObject.sides.push(side);
				}

				if(!board.activePatches.hasOwnProperty(this.boardKey)){
					board.activePatches[this.boardKey] = [];
				}

				var index = board.activePatches[this.boardKey].indexOf(this);
				if(index === -1){
					board.activePatches[this.boardKey].push(this);
				}

				board.activePatchesIndexes = board.activePatchesIndexes.filter(function( obj ) {
					return obj.uuid !== this.uuid;
				}.bind(this));
				
				var font = {};
				if (customProduct.font) {
					font = {
						font: customProduct.font,
						color: customProduct.fontColor,
						size: customProduct.fontSize,
						feSize: customProduct.FEfontSize
					}
				}

				board.activePatchesIndexes.push({
					"uuid" : this.uuid,
					'boardKey': this.boardKey,
					'item': config.side.item.id,
					'side': config.side.id,
					'patch_id': this.patch_id,
					'text': this.canvas.sourceText,
					'x': this.x,
					'y': this.y,
					'rotateAngle': this.rotateAngle,
					'size': settings.size,
					'resizingFactor': config.customConfiguration.resizingFactor,
					'font': font,
					'scaleOriginalWidth': customProduct.scaleOriginalWidth,
					'scaleOriginalHeight': customProduct.scaleOriginalHeight,
					'scaleInPercents' : customProduct.scaleInPercents,
					'patchWidth': customProduct.customProdWidth,
					'patchHeight': customProduct.customProdHeight,
					'sideWidth': config.side.imageElementWidth,
					'sideHeight': config.side.imageElementHeight
				});

				return this;
			};

			this.removeFormObject = function(){
				var activeSideIndex = null;
				for(var sideEl in formObject.sides) {
					if(formObject.sides[sideEl].item === config.side.item.id && formObject.sides[sideEl].side === config.side.id){
						activeSideIndex = sideEl;
						break;
					}
				}
				if(activeSideIndex !== null){
					formObject.sides[activeSideIndex].customProducts = formObject.sides[activeSideIndex].customProducts.filter(function( obj ) {
						return obj.uuid !== this.uuid;
					}.bind(this));

					if(formObject.sides[activeSideIndex].customProducts.length === 0){
						formObject.sides.splice(activeSideIndex, 1);
					}
				}

				board.activePatchesIndexes = board.activePatchesIndexes.filter(function( obj ) {
					return obj.uuid !== this.uuid;
				}.bind(this));

				var index = board.activePatches[this.boardKey].indexOf(this);
				if(index >= 0){
					board.activePatches[this.boardKey].splice(index, 1);
				}
			};

			/**
			 * @function
			 * @description Removes the patch from the current Side
			 * @param {Side} side
			 * @param {Boolean} silent Used to avoid showing the standard "removePatch" message
			 * @param {Boolean} noFormUpdate Used to avoid showing the standard "removePatch" message
			 * @return {DestinationPatch}
			 */
			this.removePatch = function (silent) {
				if(typeof silent === 'undefined'){
					silent = false;
				}
				if(!silent){
					board.showMessage(translate('PATCHREMOVED'));
				}

				config.side.removePatch(this);
				config.side.fabric.fabricCanvas.remove(this.fabricObj)

				if(this.canvas){
					this.canvas.canvas.remove();
				}

				this.removeFormObject();

				config.sourceElement.removePatchFromSide(this.boardKey);
				
				if(board.getCurrentActivePatchesCount() >= settings.maxElements){
					board.maxWritingSymbols = true;
					settings.errorCallback({error: 'MAXWRITINGSANDSYMBOLS'});
				} else {
					board.maxWritingSymbols = false;
				}

				return this;
			};

			this.canvasRefresh = function(){
				var positions = this.getCanvasPosition();
				this.scaleInPercents = this.scaleInPercents || 100;
				if (this.fabricObj) {
					if(config.renderer !== 'freetext'){
						var scaledResizingFactor = this.scaleInPercents ? (this.scaleInPercents * config.customConfiguration.resizingFactor) / 100 : config.customConfiguration.resizingFactor;
						
						this.canvas.wresFactor = calcFinalResizeFactors(this.naturalWidth, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
						this.canvas.hresFactor = calcFinalResizeFactors(this.naturalHeight, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
	
						this.fabricObj.set(
							{
								scaleX: this.canvas.wresFactor / this.fabricObj.naturalWidth,
								scaleY: this.canvas.hresFactor / this.fabricObj.naturalHeight,
								relatedCanvasWidth: this.canvas.wresFactor,
								relatedCanvasHeight: this.canvas.hresFactor
							}
						);
					
					} else {
						var fontSize = calcFinalResizeFactors(config.customConfiguration.fontSize, settings.size, 100, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
						this.fabricObj.set(
							{
								scaleX: this.scaleInPercents / 100,
								scaleY: this.scaleInPercents / 100,
								text: this.canvas.sourceText,
								fontSize: fontSize
							}
						);
						this.fontSize = fontSize * this.scaleInPercents / 100;
					}
					this.fabricObj.setPositionByOrigin(
						{
							x : board.activeItem.activeSide.imageElementWidth / 100 * positions.x,
							y : board.activeItem.activeSide.imageElementHeight / 100 * positions.y
						},
						'center',
						'center'
					);
					this.fabricObj.setCoords();
					board.activeItem.activeSide.fabric.fabricCanvas.renderAll();
				}
				var width = this.fabricObj.getScaledWidth(),
					height = this.fabricObj.getScaledHeight();
				this.canvas.refresh(width, height, this.fontSize);
				this.setCanvasPosition();

				return this;
			};

			/**
			 * @function
			 * @description Sets the canvas size
			 */
			this.setCanvasPosition = function(){
				var positions = getPosition(board.activeItem.activeSide.id);

				this.defaultPositions = positions;
				
				if(this.x === null && this.y === null){
					if(typeof config.x !== 'undefined' && typeof config.x !== 'undefined'){
						this.x = config.x;
						this.y = config.y;
					}
					else if(typeof positions !== 'undefined'){
						this.x = positions.left;
						this.y = positions.top;
					}
					else{
						this.x = 50;
						this.y = 50;
					}
				}

				this.canvas.canvas.css('left', this.x + '%');
				this.canvas.canvas.css('top', this.y + '%');
				this.canvas.canvas.css('margin-left', "-" + (this.canvas.canvas.width()/2) + "px");
				this.canvas.canvas.css('margin-top', "-" + (this.canvas.canvas.height()/2) + "px");
			};

			
			this.getCanvasPosition = function(){
				var positions = getPosition(board.activeItem.activeSide.id);

				this.defaultPositions = positions;
				
				if(this.x === null && this.y === null){
					if(typeof config.x !== 'undefined' && typeof config.y !== 'undefined'){
						this.x = config.x;
						this.y = config.y;
					}
					else if(typeof positions !== 'undefined'){
						this.x = positions.left;
						this.y = positions.top;
					}
					else{
						this.x = 50;
						this.y = 50;
					}
				}
				
				return {x : this.x, y : this.y}
			};
			

			this.setRotation = function(value){
				this.rotateAngle = value;
				this.canvas.rotateAngle = this.rotateAngle;
				this.canvas.refresh();
				this.setCanvasPosition();

				return this;
			};

			this.dragEndHandler = function (target) {
				/* TODO:PRCONF:V1 overlaping */
				$this.updateFormObject.call($this, true);
				settings.errorCallback({
					patch: $this, 
					board: board,
					error: $this.getOverlaps() > 0 ? 'overlaped' : null
				});
				settings.logger('log', 'patch dragend');
			};
			
			this.savePrevious = function (clear) {
				if (clear === false) {
					this.previous = null;
				} else if (!this.previous) {
					this.scaleInPercents = this.scaleInPercents || 100;
					this.previous = {
						boardKey: this.boardKey,
						hresFactor: this.hresFactor,
						wresFactor: this.wresFactor,
						rotateAngle: this.rotateAngle,
						scaleInPercents: this.scaleInPercents,
						fontSize: this.fabricObj.fontSize * this.scaleInPercents / 100,
						text: this.fabricObj.text,
						x: this.x,
						y: this.y
					}
					settings.logger('log', 'create previous object:');
					settings.logger('log', this.previous);
				}
				return this;
			}
			
			this.loadPrevious = function () {
				var $this = this;
				if ($this.previous) {
					$this.boardKey = $this.previous.boardKey;
					$this.hresFactor = $this.previous.hresFactor;
					$this.wresFactor = $this.previous.wresFactor;
					$this.rotateAngle = $this.previous.rotateAngle;
					$this.scaleInPercents = $this.previous.scaleInPercents;
					$this.font = {feSize : $this.previous.fontSize}
					$this.fontSize = $this.previous.fontSize
					$this.x = $this.previous.x;
					$this.y = $this.previous.y;

					$this.fabricObj.set({
						angle: $this.rotateAngle
					});
					
					if ($this.deleted) {
						$this.destinationImg = null;
						$this.canvas = null;
						$this.addPatch(function(){
							$this.canvas.sourceText = $this.previous.text;
							$this.canvas.rotateAngle = $this.rotateAngle;
							$this.canvasRefresh();
							$this.previous = null;
						});
						$this.deleted = false;
					} else {
						$this.canvas.rotateAngle = $this.rotateAngle;
						$this.canvasRefresh();
						$this.previous = null;
					}
				}

				board.unselectPatch();
				board.showPatchesOnSide();
				settings.logger('log', 'loaded previous object');
				return $this;
			}

			/**
			 * @function
			 * @description Appends the patch destination to the DOM once the user adds the patch to the current side
			 * We need the callback because we calculate the image sizes on the FE and so during the boot up if we have to re-compose
			 * an old configuration or the local storage each patch must be added only after all previous images are loaded
			 * so we added a callback on all Side and Patch images releated function to use it in the "asyncLoop" inside the function
			 * Board.setActivePatches
			 * @return {DestinationPatch}
			 */
			if(config.renderer === 'freetext'){
				this.isFreeText = true;
				this.appendDestination = function (callbackFunction) {
					if (!this.canvas) {

						var _this = this;
						var positions = this.getCanvasPosition();

						if(settings.activePatches !== null){
							var savedActivePatchConfig = getObjectByValueFromArray('getSettingsActivePatches', 'x', positions.x);
						}
						var fontSize = calcFinalResizeFactors(config.customConfiguration.fontSize, settings.size, 100, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
						var scaleInPercents = savedActivePatchConfig ? savedActivePatchConfig.scaleInPercents : 100;
						this.fontSize = fontSize * scaleInPercents / 100;
						this.canvas = new CanvasMgr({
							approximation: 'approximation' in config.customConfiguration ? config.customConfiguration.approximation : null,
							font: config.customConfiguration.font,
							fontSize: fontSize * scaleInPercents / 100,
							container: config.imageContainer,
							sourceText: config.text,
							center: true,
							rotateAngle: this.rotateAngle,
							hide: false,
							cssClass: settings.patchesConfig.appliedCssClass,
							attachCanvas : this.attachCanvas
							
						});
						
						var fabric = board.activeItem.activeSide.fabric;
						var sideFabric = fabric.fabricCanvas;
						if (sideFabric) {
							fabric.addText({
								text: config.text,
								font: config.customConfiguration.font,
								fontSize: fontSize,
								scaleX: scaleInPercents / 100,
								scaleY: scaleInPercents / 100,
								destinationPatch: _this,
								positions: positions,
								fontColor: settings.fontColor
							});
						}
						
						this.setCanvasPosition();
						
					}
					else{
						this.canvas.append();
					}
					
					this.sourceElement = config.sourceElement;
					
					if (typeof callbackFunction === 'function'){
						callbackFunction();
					}
					this.sourceElementEnable();

					return this;
				}
			}
			else{
				this.appendDestination = function (callbackFunction) {
					if(!this.destinationImg && !this.canvas){
						this.destinationImg = document.createElement('img');
						this.destinationImg = $(this.destinationImg);

						board.loading();

						this.destinationImg.one('load error', function () {
							this.naturalWidth = this.destinationImg.get(0).naturalWidth;
							this.naturalHeight = this.destinationImg.get(0).naturalHeight;

							var positions = this.getCanvasPosition();

							if(settings.activePatches !== null){
								var savedActivePatchConfig = getObjectByValueFromArray('getSettingsActivePatches', 'x', positions.x);
							}
							
							var scaledResizingFactor = savedActivePatchConfig && savedActivePatchConfig.scaleInPercents ? (savedActivePatchConfig.scaleInPercents * config.customConfiguration.resizingFactor) / 100 : config.customConfiguration.resizingFactor;
							
							this.wresFactor = calcFinalResizeFactors(this.naturalWidth, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
							this.hresFactor = calcFinalResizeFactors(this.naturalHeight, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;

							this.canvas = new CanvasMgr({
								approximation: 'approximation' in config.customConfiguration ? config.customConfiguration.approximation : null,
								source: this.destinationImg,
								container: config.imageContainer,
								center: true,
								hide: false,
								rotateAngle: this.rotateAngle,
								cssClass: settings.patchesConfig.appliedCssClass,
								w: this.wresFactor,
								h: this.hresFactor,
								attachCanvas : config.attachCanvas
							});
							
							// TODO:PRCONF:V1 move this part to the method
							var fabric = board.activeItem.activeSide.fabric;
							var sideFabric = fabric.fabricCanvas;
							var width  = this.wresFactor;
							var height = this.hresFactor;

							var scaleX = width / this.naturalWidth;
							var scaleY = height / this.naturalHeight;
							
							if (sideFabric) {
								// add patch
								fabric.addImage({
									destinationPatch: this,
									scaleX: scaleX,
									scaleY: scaleY,
									positions: positions,
									callback: callbackFunction
								});
							}
							
							this.setCanvasPosition();

							board.loaded();

						}.bind(this));

						this.destinationImg.attr('src', config.imageURL);
					}
					else{
						this.canvas.append();
						if (typeof callbackFunction === 'function'){
							callbackFunction();
						}
					}
					this.sourceElementEnable();

					return this;
				};
			}

			this.sourceElementEnable = function () {
				config.sourceElement.sourceElementContainer.addClass('active');

				return this;
			};

			this.sourceElementDisable = function () {
				config.sourceElement.sourceElementContainer.removeClass('active');

				if(config.renderer === 'freetext'){
					config.sourceElement.sourceElement.val("");
				}

				return this;
			};

			var lastGoodText = "";

			this.refreshText = function (text, noCheck) {
				this.canvas.sourceText = text;

				clearTimeout(refreshTimeout);
				this.editText = true;
				refreshTimeout = setTimeout(function () {
					this.canvasRefresh();

					if (text !== '') {
						if(typeof noCheck === 'undefined' || noCheck === false){
							this.updateFormObject(true);
							var patch = this;
							patch.editText = true;
							updateCanvasBounding(patch.fabricObj, patch.canvas);
							settings.errorCallback({
								patch: patch, 
								board: board,
								error: patch.getOverlaps() > 0 ? 'overlaped' : null
							});
						}
					}
				}.bind(this), 250);
			};

			this.isBannedSize = function(size){
				size += "";
				if(config.sourceElement.bannedSizes.indexOf(size) !== -1){
					return true;
				}
				else{
					return false;
				}
			};

			$window.resize( function () {
				var canvas = this.canvas.canvas.get(0);
				canvas.setAttribute('data-x', null);
				canvas.setAttribute('data-y', null);
			}.bind(this));

		};
		
		/**
		 * fabric container wrapper 
		 */
		var FabricWrapper = function(imageContainer, config) {
			this.canvas = document.createElement('canvas');
			this.canvas = $(this.canvas);
			this.canvas.attr('id', 'fabricCanvas');
			imageContainer.append(this.canvas);
			
			this.fabricCanvas = new fabric.Canvas(
				'fabricCanvas'
			);
			this.fabricCanvas.selection = false; //disable group selection
			this.fabricCanvas.uniScaleTransform = false;  // only proportional selection
			this.fabricCanvas.uniScaleKey = null;  // only proportional selection
			this.fabricCanvas.setHeight(config.height);
			this.fabricCanvas.setWidth(config.width);

			this.fabricCanvas.on('selection:cleared', function(obj) {
				if (obj.e && obj.deselected[0]) {
					this.setActiveObject(obj.deselected[0]);
				} else {
					board.activeItem.activeSide.canvas.canvas.css('opacity', 0);
				}
			});
			
			this.fabricCanvas.on('before:selection:cleared', function() {
				var activeObjects = this.getActiveObjects();
				var destinationPatch = activeObjects.destinationPatch;
				if (destinationPatch && destinationPatch.isFreeText && destinationPatch.unselect) {
					destinationPatch.sourceElement.sourceElement.val('');
				}
			}.bind(this));
			
			
			// fabric js is not supporting text base line "middle", only alphabetic 
			// but middle position is required, that is why vertical position of the text is managed by increasing TopOffset value
			// the original function body is "return - this.height / 2";
			//https://github.com/fabricjs/fabric.js/issues/2320
			fabric.Text.prototype._getTopOffset = function() {
				return - this.height / 2.4;
			};
			
			this.processSelection = function(obj) {
				if (obj.e && obj.deselected && !obj.deselected[0].parent.unselect) {
					this.fabricCanvas.setActiveObject(obj.deselected[0]);
					return this;
				}
				// cover image show
				board.activeItem.activeSide.canvas.canvas.css('opacity', 1);
				var activeObjects = this.getActiveObjects();
				var activeObject = activeObjects.activeObject;
				var destinationPatch = activeObjects.destinationPatch;
				var patchID = (destinationPatch && destinationPatch.patch_id) || (activeObject.parent && activeObject.parent.patch_id);
				var fabricCanvas = this.fabricCanvas;
				
				if (obj.e && obj.target) {
					activeObject.pointer = {
						left: obj.e.layerX - obj.target.left,
						top: obj.e.layerY - obj.target.top,
						zoomed: imageContainer.data('scale') || null
					}
				}
				
				if(patchID){
					var patchConfig = getObjectByValueFromArray('getSettingsPatches', 'id', patchID);
				}

				activeObject.set('opacity', 1);
				activeObject.setControlsVisibility({
					mt: false, // middle top disable
					mb: false,
					ml: false,
					mr: false
				});

				if (settings.isMobile) {
					activeObject.setControlsVisibility({
						bl: false, // corners disable
						br: false,
						tl: false,
						tr: false,
						mtr: false
					});
				}

				fabricCanvas.forEachObject(function(obj) {
					if (activeObject.uuid !== obj.uuid) {
						obj.set('opacity', 0.17);
					}
				});
				fabricCanvas.renderAll();

				if (destinationPatch && destinationPatch.isFreeText && destinationPatch.sourceElement) {
					destinationPatch.sourceElement.sourceElement.val(activeObject.text);
					if ( !destinationPatch.editText ) {
						destinationPatch.sourceElement.patchTab.controllerHtmlElement.click();
						if (!app.device.isMobileView()) {
							destinationPatch.sourceElement.sourceElement.focus();
						}
					}
				}

				var element = destinationPatch || activeObject.parent;
				if (element) {
					// need to update other canvas bounding for all other patches
					if ( board.activePatches[element.boardKey] ) {
						for( var i = 0, len = board.activePatches[element.boardKey].length; i < len; i++ ) {
							var otherPatch = board.activePatches[element.boardKey][i];
							if(element.canvas !== otherPatch.canvas) {
								updateCanvasBounding(otherPatch.fabricObj, otherPatch.canvas);
							}
						}
					}

					updateCanvasBounding(activeObject, element.canvas);
					/* TODO:PRCONF:V1 overlaping */
					settings.errorCallback({
						patch: element,
						board: board,
						error: element.getOverlaps() > 0 ? 'overlaped' : null,
						newPatch: !destinationPatch
					});
				}

				board.zoomIn(function(){
					var element = destinationPatch || activeObject.parent;
					if (element) {
						// need to update other canvas bounding for all other patches
						if ( board.activePatches[element.boardKey] ) {
							for( var i = 0, len = board.activePatches[element.boardKey].length; i < len; i++ ) {
								var otherPatch = board.activePatches[element.boardKey][i];
								if(element.canvas !== otherPatch.canvas) {
									updateCanvasBounding(otherPatch.fabricObj, otherPatch.canvas);
								}
							}
						}

						updateCanvasBounding(activeObject, element.canvas);
						/* TODO:PRCONF:V1 overlaping */
						settings.errorCallback({
							patch: element,
							board: board,
							error: element.getOverlaps() > 0 ? 'overlaped' : null,
							newPatch: !destinationPatch
						});
					}
				});
			}.bind(this);

			this.fabricCanvas.on('selection:updated', this.processSelection);

			this.fabricCanvas.on('selection:created', this.processSelection);

			this.fabricCanvas.on('mouse:down', function(e){
				this.mouseDown = e;
				if (e.target && e.target.pointer) {
					var scale = imageContainer.data('scale');
					if (!e.target.pointer.zoomed) {
						e.target.pointer.left = e.target.pointer.left / scale;
						e.target.pointer.top = e.target.pointer.top / scale;
						e.target.pointer.zoomed = scale;
						e.transform.offsetX = e.target.pointer.left;
						e.transform.offsetY = e.target.pointer.top;
					}
				}
			});
			this.fabricCanvas.on('mouse:move', function(e){
				if (this.mouseDown && e.target && e.target.pointer) {
					var scale = imageContainer.data('scale');
					if (!e.target.pointer.zoomed) {
						e.target.pointer.left = e.target.pointer.left / scale;
						e.target.pointer.top = e.target.pointer.top / scale;
						e.target.pointer.zoomed = scale;
						e.transform.offsetX = e.target.pointer.left;
						e.transform.offsetY = e.target.pointer.top;
					}
				}
			});
			this.fabricCanvas.on('mouse:up', function(mouseUp){
				if (this.mouseDown.pointer.x === mouseUp.pointer.x &&
					this.mouseDown.pointer.y === mouseUp.pointer.y &&
					mouseUp.target && mouseUp.target.text) {
					mouseUp.target.parent.editText = true;
					settings.errorCallback({
						patch: mouseUp.target.parent, 
						board: board,
						error: mouseUp.target.parent.getOverlaps() > 0 ? 'overlaped' : null
					});
				}
				this.mouseDown = null;
			});
			
			this.fabricCanvas.on('object:moved', function(obj) {
				var positions = obj.target.parent.getOutLimits({left: obj.transform.original.left, top: obj.transform.original.top});
				if (positions.changed) {
					obj.target.set('left', positions.left);
					obj.target.set('top', positions.top);
					obj.target.parent.canvasRefresh();
				}
			});
			
			// modify the patch position
			this.fabricCanvas.on('object:modified', function(obj) {
				var object = obj.target;
				if (obj.transform) {
					var positions = obj.target.parent.getOutLimits({left: obj.transform.original.left, top: obj.transform.original.top});
					if (positions.changed) {
						obj.target.set('left', positions.left);
						obj.target.set('top', positions.top);
						obj.target.parent.canvasRefresh();
					}
				}
				var center = object.getCenterPoint();

				var x = center.x / this.fabricCanvas.getWidth() * 100;
				var y = center.y / this.fabricCanvas.getHeight() * 100;
				var element = object.parent;
				if (!element) console.log('error');
				element.x = x;
				element.y = y;
				element.setRotation(object.angle);
				element.updateFormObject.call(element, true);
				// update object after the all activities
				updateCanvasBounding(object, element.canvas);
				
				element.dragEndHandler();
			}.bind(this));
			
			/*
			 * Get an object that contains active fabric object and destinationPatch for that object
			 */
			this.getActiveObjects = function () {
				var result = {
					activeObject : null,
					destinationPatch : null
				}
				var destinationPatch;
				var activeObject = this.fabricCanvas.getActiveObject();
				if (!activeObject) {
					return result;
				}
				$.each(board.getCurrentActivePatches(), function (key, element) {
					if (activeObject.uuid === element.uuid) {
						destinationPatch = element;
					}
				});
				result.activeObject = activeObject;
				result.destinationPatch = destinationPatch;
				
				return result;
			}

			this.onObjectScaled = function(event) {
				var object = event.target;
				var element = object.parent;

				if (element) {
					var scaledWidth = object.rangedScaledWidth || object.getScaledWidth();
					var scaledHeight = object.rangedScaledHeight || object.getScaledHeight();
					var patchConfig = getObjectByValueFromArray('getSettingsPatches', 'id', element.patch_id);
					var increasingReducingFactor = patchConfig.customConfiguration && 
						patchConfig.customConfiguration.increasingReducingFactor ? 
						patchConfig.customConfiguration.increasingReducingFactor : 
						settings.controllersConfig.scaleControl.defaultMaxScale;
					var decreasingReducingFactor = patchConfig.customConfiguration && 
						patchConfig.customConfiguration.decreasingReducingFactor ? 
						patchConfig.customConfiguration.decreasingReducingFactor : 
						settings.controllersConfig.scaleControl.defaultMinScale;
					var originalFontSize = null;
					var scaledFontSize = null;
					var originalWidth = 0;
					var originalHeight = 0;
					var scalingInPercents = 0;
					
					if (object.text) {
						originalWidth = object.width;
						originalHeight = object.height;
						originalFontSize = object.fontSize;
						scaledFontSize = object.rangedScaledFontSize || (originalFontSize * object.scaleX).toFixed(0);
					} else {
						originalWidth = object.relatedCanvasWidth;
						originalHeight = object.relatedCanvasHeight;
					}
					
					if (element.scaleOriginalWidth) {
						originalWidth = element.scaleOriginalWidth;
					}
					if (element.scaleOriginalHeight) {
						originalHeight = element.scaleOriginalHeight;
					}
					
					if (object.text) {
						scalingInPercents = scaledWidth * 100 / originalWidth;
					} else {
						scalingInPercents = scaledWidth * 100 * deviceRatio / originalWidth;
					}
					
					if (object.text) {
						scaledFontSize = (originalFontSize * scalingInPercents) / 100;
					}
					if (scalingInPercents >= increasingReducingFactor) {
						scaledWidth = originalWidth * increasingReducingFactor / 100 / deviceRatio;
						scaledHeight = originalHeight * increasingReducingFactor / 100 / deviceRatio;
						scalingInPercents = increasingReducingFactor;
						if (object.text) {
							scaledFontSize = (originalFontSize * increasingReducingFactor) / 100;
						}
					} else if(scalingInPercents <= decreasingReducingFactor) {
						scaledWidth = originalWidth * decreasingReducingFactor / 100 / deviceRatio;
						scaledHeight = originalHeight * decreasingReducingFactor / 100 / deviceRatio;
						scalingInPercents = decreasingReducingFactor;
						if (object.text) {
							scaledFontSize = (originalFontSize * decreasingReducingFactor) / 100;
						}
					}
					
					object.rangedScaledWidth = null; // clearing values from input
					object.rangedScaledHeight = null;

					
					if (object.text) {
						element.fontSize = scaledFontSize;
						object.scaleX = scalingInPercents / 100;
						object.scaleY = scalingInPercents / 100;
					} else {
						object.scaleX = scaledWidth / object.naturalWidth;
						object.scaleY = scaledHeight / object.naturalHeight;
					}
					element.scaleOriginalWidth = originalWidth;
					element.scaleOriginalHeight = originalHeight;
					element.scaleInPercents = object.text ? scalingInPercents : scalingInPercents / deviceRatio;
					
					element.canvas.refresh(scaledWidth, scaledHeight, scaledFontSize);
					element.updateFormObject.call(element, true);
					
					object.setCoords();
					this.fabricCanvas.setActiveObject(object);
				}
			}.bind(this);
			
			this.fabricCanvas.on('object:scaled', this.onObjectScaled);
			
			this.addText = function (config) {
				var destinationPatchConfig = config.destinationPatch;
				var text =  new fabric.Text(config.text, {
					fontFamily: config.font, 
					fontSize: config.fontSize,
					uuid: destinationPatchConfig.uuid,
					angle: destinationPatchConfig.rotateAngle,
					textAlign: 'center',
					originX: 'center',
					originY: 'center',
					left: this.fabricCanvas.width / 100 * config.positions.x,
					top: this.fabricCanvas.height / 100 * config.positions.y,
					fill: settings.fontColor,
					x: config.positions.x,
					y: config.positions.y,
					minScaleLimit: 0.7,
					lockScalingFlip: true,
					scaleX: config.scaleX,
					scaleY: config.scaleY,
					centeredScaling: false,
					parent: destinationPatchConfig,
					hasBorders: settings.isMobile ? false : true,
					transparentCorners: settings.fabricBordersConfig.transparentCorners,
					borderColor: settings.fabricBordersConfig.borderColor,
					cornerSize: settings.fabricBordersConfig.cornerSize,
					cornerColor: settings.fabricBordersConfig.cornerColor,
					rotatingPointOffset: settings.fabricBordersConfig.rotatingPointOffset,
					cornerStyle: settings.fabricBordersConfig.cornerStyle,
					cornerStrokeColor: settings.fabricBordersConfig.cornerStrokeColor
				});
				if (!destinationPatchConfig.isDraggable) {
					text.lockMovementX = true;
					text.lockMovementY = true;
					text.centeredScaling = true;
					text.hoverCursor = 'default';
				}
				if (!destinationPatchConfig.isRotatable) {
					text.hasRotatingPoint = false;
					text.lockRotation = true;
				}
				if (!destinationPatchConfig.isResizable) {
					text.lockScalingX = true;
					text.lockScalingY = true;
					text.setControlsVisibility({
						bl: false, // corners disable
						br: false,
						tl: false,
						tr: false
					});
				}
				this.fabricCanvas.add(text);
				destinationPatchConfig.fabricObj = text;
				destinationPatchConfig.canvas.fabricObj = text;
				if (destinationPatchConfig.setAsActive) {
					this.fabricCanvas.setActiveObject(text);
					destinationPatchConfig.setAsActive = false;
				}
				updateCanvasBounding(text, destinationPatchConfig.canvas);
			}

			this.addImage = function(config) {
				var destinationPatch = config.destinationPatch;
				var _this = this;
				fabric.Image.fromURL(
					destinationPatch.destinationImg.get(0).src,
					function (oImg) {
						var oImgConfig = {
							angle: destinationPatch.rotateAngle,
							scaleX: config.scaleX,
							scaleY: config.scaleY,
							uuid: destinationPatch.uuid,
							naturalWidth: destinationPatch.naturalWidth,
							naturalHeight : destinationPatch.naturalHeight,
							relatedCanvasHeight : destinationPatch.canvas.containerHeight,
							relatedCanvasWidth : destinationPatch.canvas.containerWidth,
							x: config.positions.x,
							y: config.positions.y,
							minScaleLimit: 0.025,
							lockScalingFlip: true,
							parent: config.destinationPatch,
							hasBorders: settings.isMobile ? false : true,
							transparentCorners: settings.fabricBordersConfig.transparentCorners,
							borderColor: settings.fabricBordersConfig.borderColor,
							cornerSize: settings.fabricBordersConfig.cornerSize,
							cornerColor: settings.fabricBordersConfig.cornerColor,
							rotatingPointOffset: settings.fabricBordersConfig.rotatingPointOffset,
							cornerStyle: settings.fabricBordersConfig.cornerStyle,
							cornerStrokeColor: settings.fabricBordersConfig.cornerStrokeColor
						};
						if (!destinationPatch.isDraggable) {
							oImgConfig.lockMovementX = true;
							oImgConfig.lockMovementY = true;
							oImgConfig.centeredScaling = true;
							oImgConfig.hoverCursor = 'default';
						}
						if (!destinationPatch.isRotatable) {
							oImgConfig.hasRotatingPoint = false;
							oImgConfig.lockRotation = true;
						}
						if (!destinationPatch.isResizable) {
							oImgConfig.lockScalingX = true;
							oImgConfig.lockScalingY = true;
						}
						oImg.set(oImgConfig);
						if (!destinationPatch.isResizable) {
							oImg.setControlsVisibility({
								bl: false, // corners disable
								br: false,
								tl: false,
								tr: false
							});
						}
						// filter that prevent image quality degradation during the resize
						//https://github.com/kangax/fabric.js/issues/1741
						oImg.filters.push(new fabric.Image.filters.Resize({
							scaleX: 0.5,
							scaleY: 0.5, 
							resizeType: 'sliceHack'
						}));
						
						oImg.applyFilters();
						_this.fabricCanvas.add(oImg); 
						destinationPatch.fabricObj = oImg;
						destinationPatch.canvas.fabricObj = oImg;
						oImg.setPositionByOrigin(
							{
								x : _this.fabricCanvas.width / 100 * config.positions.x,
								y : _this.fabricCanvas.height / 100 * config.positions.y
							},
							'center',
							'center'
						);
						oImg.setCoords();
						if (config.destinationPatch.setAsActive) {
							_this.fabricCanvas.setActiveObject(oImg);
							destinationPatch.setAsActive = false;
						}
						updateCanvasBounding(oImg, destinationPatch.canvas);

						if(typeof config.callback === 'function'){
							config.callback();
						}
					}
				);
			} 
		};

		/**
		 * PatchTab class, represents the patch container. It have to working modes: Click and Scroll
		 */
		var PatchTab = function(id, config, container, tabsContainer) {
			Renderer.call(this, id, config, container, config.containerCssClass, settings.tabsConfig.analyticsClass);
			this.enableClick = false;
			this.elementType = typeof config.containerElementType !== "undefined" ? config.containerElementType : "div";
			this.controllerElementType = typeof config.elementType !== "undefined" ? config.elementType : "div";
			this.attachName = false;
			this.controllerHtmlElement = null;
			this.width = 1;
			this.isVisible = true;

			/**
			 * @function
			 * @description Renders the tabs links
			 * @return {PatchTab}
			 */
			this.renderController = function () {
				this.controllerHtmlElement = $(document.createElement(this.controllerElementType));
				if(typeof config.cssClass !== "undefined"){
					this.controllerHtmlElement.addClass(config.cssClass);
				}
				this.controllerHtmlElement.text(translate(id));

				if(settings.mode === 'click') {
					this.controllerHtmlElement.click(this.clickFunction.bind(this));
				}
				else if(settings.mode === 'scroll') {
					this.controllerHtmlElement.click(this.scrollFunction.bind(this));
				}
				if(id.toLowerCase() === "freetext"){
					this.controllerHtmlElement.click(this.clickFreeTextFunction.bind(this));
				}

				return this;
			};

			/**
			 * @function
			 * @description Appends the controller to the DOM
			 * @return {PatchTab}
			 */
			this.appendController = function () {
				tabsContainer.append(this.controllerHtmlElement);

				return this;
			};

			/**
			 * @function
			 * @description Activates the choosen Tab
			 * @return {PatchTab}
			 */
			this.activate = function () {
				this.htmlElement.addClass('active');
				this.controllerHtmlElement.addClass('active');

				return this;
			};

			/**
			 * @function
			 * @description Function used when the scrollMode is enabled
			 * @return {PatchTab}
			 */
			this.scrollFunction = function(animationSpeed) {
				animationSpeed = typeof animationSpeed === "number" ? animationSpeed : settings.tabsConfig.defaultAnimationSpeed;
				var scrollTo = this.htmlElement.offset().left - container.offset().left + container.scrollLeft();
				
				container.animate({'scrollLeft': scrollTo}, animationSpeed);
				
				return this;
			};

			/**
			 * @function
			 * @description Function used when the clickMode is enabled
			 * @return {PatchTab}
			 */
			this.clickFunction = function(){
				this.htmlElement.siblings().removeClass('active');
				this.htmlElement.addClass('active');
				this.controllerHtmlElement.siblings().removeClass('active');
				this.controllerHtmlElement.addClass('active');

				return this;
			};
			
			/**
			 * @function
			 * @description Function with handler for "FreeText" tab
			 * @return {PatchTab}
			 */
			this.clickFreeTextFunction = function(){
				var freetextInput = this.htmlElement.find("input[name=" + settings.freeTextInputName + "]");
				freetextInput.trigger('focus');
				if(app.device.isMobileView()){
					freetextInput.trigger('blur');
				}
				return this;
			};

			/**
			 * @function
			 * @description Since in scroll Mode we need all the Patches on one line and we don't know the size of each Patch we need to resize
			 * the PatchTab each time one Patch is fully loaded
			 * @return {PatchTab}
			 */
			this.addWidth = function (width) {
				var notHideItem = 0;
				this.width += width;
				this.htmlElement.width(this.width + 'px');

				if(this.width > 1 && this.htmlElement.is(":hidden")) {
					this.htmlElement.show();
					this.controllerHtmlElement.css('display', '');
					this.isVisible = true;
					this.allHidden = false;
					board.setVisibleTabsCount();

					if (board.getVisiblePatchesTabsNames()[0] === this.id) {
						$(document).trigger('firsttabloaded');
					}
				}

				return this;
			};

			/**
			 * @function
			 * @description same as the previous but removes the width instead of adding it
			 * @return {PatchTab}
			 */
			this.subWidth = function (width) {
				this.width -= width;
				this.htmlElement.width(this.width + 'px');

				if(this.width <= 1 && this.htmlElement.is(":visible")){
					this.isVisible = false;

					this.htmlElement.hide();
					this.controllerHtmlElement.css('display', 'none');
					board.setVisibleTabsCount();
				}

				return this;
			};

			this.render().append().renderController().appendController();
		};
		PatchTab.prototype = Object.create(Renderer.prototype);

		/**
		 * Board class, represents that controls all other Objects
		 */
		var Board = function(itemsContainer, sidesContainer, imageContainer, imageWrapper, patchesContainer, tabsContainer, controllersContainer){
			this.activePatches = {};
			this.activePatchesIndexes = [];
			this.activeItem = null;
			this.items = [];
			this.patchesTabs = {};
			this.rotatingElement = null;
			this.cleanButton = null;
			this.backButton = null;
			this.prevButton = null;
			this.nextButton = null;
			//this.saveContainer = null;
			this.saveButton = null;
			this.closeButton = null;
			//this.compareButton = null;
			//this.compareCounter = null;
			this.overlayElement = null;
			this.overlayMsg = null;
			this.overlayClose = null;
			this.restartButton = null;
			this.history = [];
			this.disableHistory = false;
			this.loader = null;
			this.tabChangedSide = false;
			this.hideLoader = true;
			this.isLoading = false;
			this.eventsEnabled = false;
			this.loadingElements = 0;

			board = this;

			/**
			 * @function
			 * @description Returns an Array representing all the Patches attached to the current active side
			 * @return {Array}
			 */
			this.getCurrentActivePatches = function(){
				if(this.activePatches.hasOwnProperty(this.getCurrentBoardkey())){
					return this.activePatches[this.getCurrentBoardkey()];
				}
				else{
					return [];
				}
			};
			
			this.findPatchByUUID = function(uuid){
				var activeElement = null;
				$.each(this.getCurrentActivePatches(), function (key, element) {
					if (uuid === element.uuid) {
						activeElement = element;
					}
				});
				return activeElement;
			};

			/**
			 * @function
			 * @description Returns an Int representing the number of applied patches
			 * @return {Array}
			 */
			this.getCurrentActivePatchesCount = function(){
				var count = 0;
				for(var i in this.activePatches){
					if(Array.isArray(this.activePatches[i])){
						count += this.activePatches[i].length;
					}
				}

				return count;
			};

			/**
			 * @function
			 * @description Removes all the Patches attached to the current active side
			 * @return {Board}
			 */
			this.removeCurrentActivePatches = function(){
				delete this.activePatches[this.getCurrentBoardkey()];
				this.activePatchesIndexes = this.activePatchesIndexes.filter(function(element) {
					return element.boardKey !== this.getCurrentBoardkey();
				}.bind(this));

				return this;
			};

			/**
			 * @function
			 * @description Update patches Tabs state. It mainly starts at beginning and when a side has been selected.
			 * It ensures that the scroll for patches is always aligned with the current active tab. If the active one
			 * is invisible, the next visible tab will be chosen.
			 * @return {Board}
			 */
			this.updatePatchesTabsState = function () {
				var goTo = null;

				// Find the first visible active tab first
				$.each(this.patchesTabs, function (index, tab) {
					if ( tab.isVisible && tab.htmlElement.hasClass('active')) {
						goTo = tab;
						return false; // Break the loop
					}
				});

				// If no tab has been found, find the first visible
				if ( goTo === null) {
					$.each(this.patchesTabs, function (index, tab) {
						if ( tab.isVisible) {
							goTo = tab;
							return false; // Break the loop
						}
					});
				}

				board.setVisibleTabsCount();

				if ( goTo !== null) {
					// Run Click and Scroll Function
					goTo.clickFunction();
					goTo.scrollFunction(0);
				}

				return this;
			};

			/**
			 * @function
			 * @description Set data attribute for wrapper for correct styling
			 */
			this.setVisibleTabsCount = function() {
				var visibleTabsCount = $(settings.patchesWrap).children(':visible').length;
				if ( $(settings.patchesWrap).attr( 'data-visibleTabsCount') !== visibleTabsCount ) {
					$(settings.patchesWrap).attr( 'data-visibleTabsCount', visibleTabsCount );
				}
			};

			/**
			 * @function
			 * @description Shows an alert
			 * @return {Board}
			 */
			this.showMessage = function(msg){
				if (typeof msg === 'string') {
					this.overlayMsg.html(msg);
					this.overlayElement.addClass('active');
				}

				return this;
			};

			/**
			 * @function
			 * @description Gets the current boardKey composed by the Item ID plus the Side ID
			 * @return {String}
			 */
			this.getCurrentBoardkey = function(){
				return this.activeItem.id + "_" + this.activeItem.activeSide.id
			};
			
			/**
			 * @function
			 * @description Gets current side ID of board
			 * @return {String}
			 */
			this.getCurrentBoardSidekey = function() {
				return this.activeItem && this.activeItem.activeSide && this.activeItem.activeSide.id;
			}

			/**
			 * @function
			 * @description This function is called by each Item when enabled to make the Board disable the old one
			 * @param {Item} item New item to be enabled
			 * @return {Board}
			 */
			this.changeItemCallback = function (item) {
				$(settings.imageWrapper).toggleClass(settings.sidesConfig.singleSideClass, Object.keys(item.sides).length === 1);

				if(this.activeItem){
					this.activeItem.activeSide.deactivate();
					this.activeItem.deactivate();
					this.activeItem.disable();
				}
				this.activeItem = item;

				return this;
			};

			/**
			 * @function
			 * @description Adds a record inside the history
			 * TODO CHeck if we can remove the "object" param
			 * @param {String} action | A string representing the performed action
			 * @param {String} object | The "this" object of the function
			 * @param {Function} resetFunction | callback to launch when we need to reset
			 * @return {Board}
			 */
			this.updateHistory = function (action, object, resetFunction, combineWithLastCommand) {
				if (!this.disableHistory) {
					this.disableHistory = true;
					
					if (combineWithLastCommand) {
						if (this.history.length > 0) {
							if ($.isArray(this.history[this.history.length-1])) {
								this.history[this.history.length-1].push({
									"action" : action,
									"object" : object,
									"resetFunction" : resetFunction
								});
							}
							else {
								var lastHistoryCommand = this.history[this.history.length-1];
								this.history[this.history.length-1] = [lastHistoryCommand];
								this.history[this.history.length-1].push({
									"action" : action,
									"object" : object,
									"resetFunction" : resetFunction
								});
							}
						}
					}
					else {
						this.history.push({
							"action" : action,
							"object" : object,
							"resetFunction" : resetFunction
						});
					}
					
					this.history.length > 0 && this.activateControllers();
					this.disableHistory = false;
				}
				
				return this;
			};

			/**
			 * @function
			 * @description Returns a specific reset point
			 * @return {Object}
			 */
			this.getResetPoint = function(index){
				if(typeof this.history[index] !== 'undefined'){
					return this.history[index];
				}
				else{
					return null;
				}
			};

			/**
			 * @function
			 * @description Resets the history to a specific point or to the last one if no index is provided
			 * @param {Int} index
			 * @return {Object}
			 */
			this.resetToHistory = function() {
				this.disableHistory = true;
				
				if (this.history.length > 0) {
					var index = this.history.length - 1;
					
					if (this.getResetPoint(index)) {
						if (typeof this.getResetPoint(index).resetFunction === "function") {
							this.getResetPoint(index).resetFunction();
						}
						else if ($.isArray(this.getResetPoint(index))) {
							while (this.getResetPoint(index).length > 0) {
								var command = this.getResetPoint(index).pop();
								
								if (typeof command.resetFunction === "function") {
									command.resetFunction();
								}
							}
						}
					}
					
					this.history.splice(index, 1);
					if (this.history.length === 0) {
						this.disableControllers();
					}
				}
				
				this.disableHistory = false;
				
				return this;
			};

			/**
			 * @function
			 * @description Shows the controllers ( clean, reset, ... )
			 * @return {Object}
			 */
			this.activateControllers = function(){
				this.restartButton.addClass('active');
				this.saveButton.show();

				return this;
			};

			/**
			 * @function
			 * @description Hides the controllers ( clean, reset, ... )
			 * @return {Object}
			 */
			this.disableControllers = function(){
				this.restartButton.removeClass('active');

				return this;
			};

			/**
			 * @function
			 * @description This function is called by each Patch when enabled to make the Board disable the old one
			 * @param {Patch} patch
			 * @return {Board}
			 */
			this.addPatchCallback = function (patch) {
				this.activeItem.activeSide.addPatch(patch);
				this.updateHistory('addPatch', patch, patch.removePatch.bind(patch, this.activeItem.activeSide), false);

				return this;
			};

			this.triggerUpdate = function ( source ){
				if(this.eventsEnabled){
					htmlBoardElement.trigger('boardUpdate', {"formObject": formObject, "settings":settings, 'activePatchesIndexes' : this.activePatchesIndexes, 'source': source });
				}
			};

			this.triggerUpdatePrice = function (){
				if(this.eventsEnabled){
					htmlBoardElement.trigger('priceUpdate');
				}
			};
			
			this.isZoomInOverlaps = function() {
				var deferred = $.Deferred();
				var elementToCheck = app.device.isMobileUserAgent() === 'mobile' ? $(settings.patchesContainer)
					: $(settings.configuratorPopupSel);
				var sideImage = this.activeItem.activeSide.imageElement;
				var mainImageSizes = this.activeItem.activeSide.mainImage.sizes;
				var configuratorPopup = $(settings.configuratorPopupSel);
				var notOverlapping = true;
				var currentSide = settings.side[this.getCurrentBoardSidekey()];
				if (configuratorPopup.length && sideImage.length) {
					app.components.global.images.imageLoaded(sideImage).then(function () {
						var elementToCheckOffset = elementToCheck.offset();
						var elementToCheckHeight = elementToCheck.outerHeight();
						var elementToCheckWidth = elementToCheck.outerWidth();
						var elementToCheckDistanceTop = elementToCheckOffset.top + elementToCheckHeight;
						var elementToCheckDistanceLeft = elementToCheckOffset.left + elementToCheckWidth;
						
						var imageOffset = sideImage.offset();
						var imageHeight = (mainImageSizes.maxY - mainImageSizes.minY) / deviceRatio;
						var imageWidth = (mainImageSizes.maxX - mainImageSizes.minX) / deviceRatio;
						imageOffset.top += (mainImageSizes.minY / deviceRatio);
						imageOffset.left += (mainImageSizes.minX / deviceRatio);
						var imageDistanceTop = imageOffset.top + imageHeight;
						var imageDistanceLeft = imageOffset.left + imageWidth;
						
						notOverlapping = ( elementToCheckDistanceTop < imageOffset.top || elementToCheckOffset.top > imageDistanceTop
								|| elementToCheckDistanceLeft < imageOffset.left || elementToCheckOffset.left > imageDistanceLeft );
						
						var thresholdConfig = settings.zoomConfig.overlapThreshold;
						var deltaHeight = imageDistanceTop - elementToCheckOffset.top;
						var threshold;
						if (currentSide.shape && thresholdConfig.hasOwnProperty(currentSide.shape)) {
							threshold = thresholdConfig[currentSide.shape];
						} else {
							threshold = thresholdConfig['default'];
						}
						threshold /= 100;
						
						if (deltaHeight <= imageHeight * threshold) {
							notOverlapping = true;
						}
						
						deferred.resolve(!notOverlapping);
					});
				} else {
					deferred.resolve(!notOverlapping);
				}
				
				return deferred.promise();
			}

			this.zoomIn = function(callback, pow){
				pow = pow != null ? pow : 1;
				if (!settings.zoomIn) {
					if (callback === true) {
						this.refresh();
					} else if (typeof callback === "function"){
						callback(scale);
					}
					return false; 
				}
				var scale = imageContainer.data('scale');
				var activeObject = this.activeItem.activeSide.fabric.fabricCanvas.getActiveObject();
				
				if (scale && callback !== true && pow === 1) {
					return false;
				}
				
				if ((!scale && callback !== true) || scale) {
					imageWrapper.addClass(settings.zoomConfig.zoomedCssClass);
					var sizes = this.activeItem.activeSide.canvas.sizes;
					if (sizes.minX === sizes.maxX ||
						sizes.minY === sizes.maxY) {
						this.activeItem.activeSide.canvas.refresh();
						this.activeItem.activeSide.mainImage.refresh();
						sizes = this.activeItem.activeSide.canvas.sizes;
					}
					var scaleX = (sizes.maxX - sizes.minX) / sizes.width;
					var scaleY = (sizes.maxY - sizes.minY) / sizes.height;
					var scale = Math.max(scaleX, scaleY) * Math.pow(this.activeItem.activeSide.zoomCoefficient,Math.exp(pow/settings.powLimit*2));
					
					imageContainer.css({
						'min-width': imageWrapper.width() / scale,
						'margin-left': (sizes.width - sizes.maxX / scale - sizes.minX / scale) * imageWrapper.width() / (2 * sizes.width)
					}).data('scale', scale);
					
					if (!settings.isMobile) {
						var marginTop = (sizes.height - sizes.maxY / scale - sizes.minY / scale) * imageWrapper.height() / (2 * sizes.height);

						imageContainer.css({
							'margin-top': Math.min(marginTop,0)
						});
					}

					this.refresh();
					if (this.activeItem.activeSide.canvas) {
						this.activeItem.activeSide.mainImage.refresh();
					}
					if (activeObject) {
						this.activeItem.activeSide.fabric.fabricCanvas.setActiveObject(activeObject.parent.refresh().fabricObj);
					}
					
					if (typeof callback === "function"){
						callback(scale);
					}
					
					this.isZoomInOverlaps().then(function (currentCallback, isZoomInOverlaps) {
						if (isZoomInOverlaps && pow < settings.powLimit) {
							pow++;
							this.zoomIn(currentCallback, pow);
						} else {
							this.refresh();
							if (activeObject) {
								this.activeItem.activeSide.fabric.fabricCanvas.setActiveObject(activeObject.parent.refresh().fabricObj);
							}
							
							if (typeof currentCallback === "function"){
								currentCallback(scale);
							}
						}
					}.bind(this, callback));
					
					callback = false;
				}
				if (callback === true) {
					this.refresh();
					if (this.activeItem.activeSide.mainImage) {
						this.activeItem.activeSide.mainImage.refresh();
					}
					if (activeObject) {
						this.activeItem.activeSide.fabric.fabricCanvas.setActiveObject(activeObject.parent.refresh().fabricObj);
					}
				}
			};
			
			this.zoomOut = function(){
				if (!settings.zoomIn) return false; 
				imageWrapper.removeClass(settings.zoomConfig.zoomedCssClass);
				imageContainer.css({
					'min-width': 'auto',
					'margin-left': 'auto',
					'margin-top': 'auto'
				}).data('scale', null);
				this.showPatchesOnSide();
				this.refresh();

				// TODO: looks like that after reset we don't have canvas
				if ( this.activeItem.activeSide.canvas ) {
					this.activeItem.activeSide.canvas.refresh();
				}
				if ( this.activeItem.activeSide.mainImage ) {
					this.activeItem.activeSide.mainImage.refresh();
				}
			}
			
			this.showPatchesOnSide = function() {
				this.activeItem.activeSide.fabric.fabricCanvas.forEachObject(function(obj) {
					obj.set('selectable', true);
					obj.set('opacity', 1);
					if (obj.parent.isDraggable) {
						obj.set('hoverCursor', 'move');
					}
				});
				this.activeItem.activeSide.fabric.fabricCanvas.requestRenderAll();
			}
			
			this.selectPatch = function(patch) {
				this.activeItem.activeSide.fabric.fabricCanvas.setActiveObject(patch.fabricObj);
				this.activeItem.activeSide.fabric.processSelection(patch.fabricObj);
				this.activeItem.activeSide.fabric.fabricCanvas.requestRenderAll();
			}
			
			this.unselectPatch = function (){
				this.activeItem.activeSide.fabric.fabricCanvas.discardActiveObject();
				this.activeItem.activeSide.fabric.fabricCanvas.requestRenderAll();
			};
			
			this.back = function () {
				if ( settings.beforeCallback({type: 'back'}) ) {
					this.zoomOut();
				}
				return this;
			};

			/**
			 * @function
			 * @description Cleans the current side removing the patch applied on it
			 * @return {Board}
			 */
			this.clean = function () {
				if(this.getCurrentActivePatches().length > 0){
					var historyUpdateCounter = 0;
					for (var j = this.activePatches[board.getCurrentBoardkey()].length; j >= 0; j--){
						if(j in this.activePatches[board.getCurrentBoardkey()]){
							var resetFunction = this.activePatches[board.getCurrentBoardkey()][j].addPatch.bind(this.activePatches[board.getCurrentBoardkey()][j], this.activeItem.activeSide);
							this.updateHistory('addPatch', {}, resetFunction, historyUpdateCounter > 0);
							this.activePatches[board.getCurrentBoardkey()][j].removePatch(true);
							historyUpdateCounter++;
						}
					}
				}

				if(this.getCurrentActivePatches().length === 0) {
					board.saveButton.addClass('disable');
				}
				
				board.showPatchesOnSide();
				board.triggerUpdate();
				board.triggerUpdatePrice();
				
				settings.beforeCallback({type: 'hide'});
				
				this.zoomOut();
				return this;
			};

			/**
			 * @function
			 * @description Resets the board to the original state
			 * @return {Board}
			 */
			this.reset = function () {
				this.disableHistory = true;

				for (var boardKey in this.activePatches){
					for (var j = this.activePatches[boardKey].length; j >= 0; j--){
						if(j in this.activePatches[boardKey]){
							this.activePatches[boardKey][j].removePatch(true);
							
						}
					}
				}

				this.history = [];
				this.disableControllers();

				this.disableHistory = false;
				
				settings.beforeCallback({type: 'hide'});
				
				this.zoomOut();
				return this;
			};

			/**
			 * @function
			 * @description Handles the click on the reset button
			 * @return {Board}
			 */
			this.handleRestart = function () {
				if(typeof settings.beforeCallback === "function"){
					settings.beforeCallback({type: 'reset'}, {ok: function(){
						board.reset();
						$(document).trigger('configurator.restart');
					}, cancel: function(actiontype){
						board.cancelHelper(actiontype);
					} });
				}
				else{
					board.reset();
					$(document).trigger('configurator.restart');
				}
			};

			/**
			 * @function
			 * @description Handles the click on the reset button
			 * @return {Board}
			 */
			this.handleClose = function () {
				if(typeof settings.beforeCallback === "function"){
					settings.beforeCallback({type: 'close'}, {ok: function(){
						board.reset();
						$(document).trigger('configurator.canceled');
					}, cancel: function(actiontype){
						board.cancelHelper(actiontype);
					} });
				}
				else{
					board.reset();
					$(document).trigger('configurator.canceled');
				}
			};
			
			/**
			 * @function
			 * @description Helper function to process cancellation for Close and Reset buttons 
			 */
			this.cancelHelper = function (actiontype){
				if(actiontype !== undefined){
					app.components.product.configurator.hideElements(actiontype, false)
					$(settings.configuratorPopupSel).remove();
				}
			};
			
			/**
			 * @function
			 * @description Handles the click on the next button
			 * @return {Board}
			 */
			this.next = function () {
				this.activeItem.activateNextSide();
				
				return this;
			};

			this.showNextSlideImgPreview = function () {
				$('.' + settings.sidesConfig.sidePreview.next.jsClass).addClass(settings.sidesConfig.sidePreview.hoverClass);
				
				return this;
			};
			
			this.hideNextSlideImgPreview = function () {
				$('.' + settings.sidesConfig.sidePreview.next.jsClass).removeClass(settings.sidesConfig.sidePreview.hoverClass);
				
				return this;
			};
			
			this.showPrevSlideImgPreview = function () {
				$('.' + settings.sidesConfig.sidePreview.prev.jsClass).addClass(settings.sidesConfig.sidePreview.hoverClass);
				
				return this;
			};
			
			this.hidePrevSlideImgPreview = function () {
				$('.' + settings.sidesConfig.sidePreview.prev.jsClass).removeClass(settings.sidesConfig.sidePreview.hoverClass);
				
				return this;
			};
			
			/**
			 * @function
			 * @description Handles the click on the prev button
			 * @return {Board}
			 */
			this.prev = function () {
				this.activeItem.activatePrevSide();

				return this;
			};

			/**
			 * @function
			 * @description Called by the Item when changeSideCallback is fired to check if the current side is
			 * compatible with each patch listed in the category choosen by the Store Manager. Only compatible patches will be appended
			 * @param {Side} side
			 * @return {Board}
			 */
			this.patchSidesMatcher = function(side){
				$.each(settings.patches, function (key, elm) {
					if(
						(elm.customConfiguration.hasOwnProperty(this.activeItem.id) &&
							elm.customConfiguration[this.activeItem.id].sides.indexOf(side.id) !== -1) &&
						elm.element.bannedSizes.indexOf(settings.size) === -1
					){
						elm.element.show();
					}
					else{
						elm.element.hide();
					}
				}.bind(this));

				return this;
			};

			/**
			 * @function
			 * @description Inits all the Board's controllers
			 * @return {Board}
			 */
			this.initControllers = function (){
				this.price = $(settings.configuratorPrice);
				
				this.backButton = $(document.createElement(settings.controllersConfig.back.elementType));
				this.backButton.addClass(settings.controllersConfig.back.cssClass);
				this.backButton.addClass('active');
				this.backButton.html($(document.createElement('span')).text(translate(settings.controllersConfig.back.name)));
				this.backButton.click(this.back.bind(this));
				controllersContainer.prepend(this.backButton);

				this.cleanButton = $(document.createElement(settings.controllersConfig.clean.elementType));
				this.cleanButton.addClass(settings.controllersConfig.clean.cssClass);
				this.cleanButton.html($(document.createElement('span')).text(translate(settings.controllersConfig.clean.name)));
				this.cleanButton.click(this.clean.bind(this));
				controllersContainer.prepend(this.cleanButton);

				this.restartButton = $(document.createElement(settings.controllersConfig.restart.elementType));
				this.restartButton.addClass(settings.controllersConfig.restart.cssClass);
				this.restartButton.html($(document.createElement('span')).text(translate(settings.controllersConfig.restart.name)));
				this.restartButton.click(this.handleRestart.bind(this));
				controllersContainer.prepend(this.restartButton);
				
				this.closeButton = $(document.createElement(settings.controllersConfig.close.elementType));
				this.closeButton.addClass(settings.controllersConfig.close.cssClass);
				this.closeButton.addClass('active');
				this.closeButton.html($(document.createElement('span')).text(translate(settings.controllersConfig.close.name)));
				this.closeButton.click(this.handleClose.bind(this));
				controllersContainer.prepend(this.closeButton);

				this.prevButton = $(document.createElement(settings.controllersConfig.prev.elementType));
				this.prevButton.addClass(settings.controllersConfig.prev.cssClass);
				this.prevButton.click(this.prev.bind(this));
				this.prevButton.mouseover(this.showPrevSlideImgPreview.bind(this));
				this.prevButton.mouseleave(this.hidePrevSlideImgPreview.bind(this));
				htmlBoardElement.append(this.prevButton);

				this.nextButton = $(document.createElement(settings.controllersConfig.next.elementType));
				this.nextButton.addClass(settings.controllersConfig.next.cssClass);
				this.nextButton.click(this.next.bind(this));
				this.nextButton.mouseover(this.showNextSlideImgPreview.bind(this));
				this.nextButton.mouseleave(this.hideNextSlideImgPreview.bind(this));
				htmlBoardElement.append(this.nextButton);
				
				if(saveEnabled){
					this.saveButton = $(document.createElement(settings.controllersConfig.save.elementType));
					this.enableSave();
					this.saveButton.addClass(settings.controllersConfig.save.cssClass);
					this.saveButton.html($(document.createElement('span')).text(translate(settings.controllersConfig.save.name)));
					this.saveButton.click(this.save.bind(this));

					$(settings.configuratorPriceActions).append(this.saveButton);
				}

				return this;
			};

			this.processRotate = function(){
				var fabric = board.activeItem.activeSide.fabric;
				var object = fabric.getActiveObjects().activeObject;
				var angle = parseInt(this.value);
				var center = object.getCenterPoint();
				
				object.set({
					angle: angle
				}).setPositionByOrigin(center, 'center', 'center');
				
				fabric.fabricCanvas.requestRenderAll();
				settings.logger('log', 'mobile rotate');
			};

			this.processRotateEnd = function(){
				var fabric = board.activeItem.activeSide.fabric;
				var element = fabric.getActiveObjects().destinationPatch;
				var object = fabric.getActiveObjects().activeObject;
				var angle = parseInt(this.val());
				
				element.fabricObj.set({
					angle: angle
				});
				
				element.fontSize = (object.fontSize * object.scaleX).toFixed(0) / deviceRatio;
				
				element.canvas.rotateAngle = this.rotateAngle || angle;
				element.canvasRefresh();
				
				fabric.fabricCanvas.trigger('object:modified', {target: object});
				settings.logger('log', 'mobile rotate end');
			};
			
			this.processScale = function(){
				var fabric = board.activeItem.activeSide.fabric;
				var object = fabric.getActiveObjects().activeObject;
				var scalePercents = parseInt(this.value);
				var center = object.getCenterPoint();
				var config = object.parent.config;

				if(!object.text){
					var scaledResizingFactor = (scalePercents * config.customConfiguration.resizingFactor) / 100;
					
					var wresFactor = calcFinalResizeFactors(object.naturalWidth, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;
					var hresFactor = calcFinalResizeFactors(object.naturalHeight, settings.size, scaledResizingFactor, board.activeItem.activeSide.getResizingFactor()) * deviceRatio;

					object.set(
						{
							scaleX: wresFactor / object.naturalWidth,
							scaleY: hresFactor / object.naturalHeight
						}
					);
				
				} else {
					object.set(
						{
							scaleX: scalePercents / 100,
							scaleY: scalePercents / 100
						}
					);
				}
				
				object.setPositionByOrigin(center, 'center', 'center');;
				fabric.fabricCanvas.requestRenderAll();
				settings.logger('log', 'mobile scale');
			};
			
			this.processScaleEnd = function(){
				var fabric = board.activeItem.activeSide.fabric;
				var object = fabric.getActiveObjects().activeObject;
				var element = fabric.getActiveObjects().destinationPatch;
				var scalePercents = parseInt(this.val());
				var originalWidth, originalHeight;

				object.set(
					{
						scaleX: scalePercents / 100,
						scaleY: scalePercents / 100
					}
				);

				if (object.text) {
					element.fontSize = (object.fontSize * object.scaleX).toFixed(0) / deviceRatio;
					originalWidth = object.width;
					originalHeight = object.height;
				} else {
					originalWidth = object.relatedCanvasWidth;
					originalHeight = object.relatedCanvasHeight;
				}
				
				element.scaleOriginalWidth = originalWidth;
				element.scaleOriginalHeight = originalHeight;
				element.scaleInPercents = scalePercents;

				element.canvasRefresh();
				element.updateFormObject.call(element, true);
				
				
				fabric.fabricCanvas.trigger('object:modified', {target: object});
				settings.logger('log', 'mobile scale end');
			};

			this.rotate = function (e) {
				if(this.rotatingElement === null){
					this.showMessage(translate('NOROTATINGELEMENT'));
				}
				else{
					this.rotatingElement.setRotation(e.target.value);
				}
			};

			if(saveEnabled) {
				htmlBoardElement.on('boardUpdate', function () {
					this.enableSave();
				}.bind(this));

				this.disableSave = function(){
					this.saveButton.text(translate(settings.controllersConfig.save.activeName));
					$(document).trigger('configurator.saved')
				};

				this.enableSave = function(){
					this.saveButton.text(translate(settings.controllersConfig.save.name));
				};

				this.save = function () {
					this.showPatchesOnSide();
					this.triggerUpdate();
					var saved = settings.saveCallback(this.activePatchesIndexes);
					if (saved.error) {
						this.showMessage(translate(saved.error, saved.errorParams));
					} else {
						this.disableSave();
					}

					return this;
				};
			}

			this.initPatches = function(){
				tabsContainer.addClass(settings.mode);
				patchesContainer.addClass(settings.mode);

				if(settings.tabsOrder){
					settings.patches.sort(function (a, b) {
						return settings.tabsOrder.indexOf(a.customConfiguration.type) - settings.tabsOrder.indexOf(b.customConfiguration.type);
					})
				}

				$.each(settings.patches, function (key, elm) {
					if(typeof elm.element === "undefined"){
						if(typeof this.patchesTabs[elm.customConfiguration.type] === "undefined"){
							this.patchesTabs[elm.customConfiguration.type] = new PatchTab(elm.customConfiguration.type, settings.tabsConfig, patchesContainer, tabsContainer);
						}
						
						var patchConfig = $.extend(true, {}, settings.patchesConfig, elm);

						elm.element = new Patch(key, patchConfig, this.patchesTabs[elm.customConfiguration.type].htmlElement, imageContainer, this.addPatchCallback.bind(this), this.patchesTabs[elm.customConfiguration.type]);
					}
				}.bind(this));
				
				this.activatePatchesTab(0);
				
				return this;
			};

			this.initItems = function () {
				var index = 0,
					itemsCount = Object.keys(settings.items).length;
				$.each(settings.items, function (key, elm) {
					if(typeof elm.element === "undefined"){
						var item = new Item(key, elm, itemsContainer, sidesContainer, imageContainer, this.changeItemCallback.bind(this));
						this.items.push(item);
						elm.element = item;
					}
					elm.element.init();
					if ( itemsCount === 1 ) {
						elm.element.htmlElement.hide();
					}
					if(index === 0){
						elm.element.activate();
					}

					index++;
				}.bind(this));

				return this;
			};
			
			this.activateItem = function(index) {
				return this.items[index].activate();
			}

			this.initEvents = function () {
				patchesContainer.on('scroll', function () {
					$.each(this.patchesTabs, function (key, elm) {
						if(elm.htmlElement.position().left <= (patchesContainer.width()/2)){
							$cache.document.off('firsttabloaded');
							elm.clickFunction();
						}
					}.bind(this));
				}.bind(this));

				this.eventsEnabled = true;

				return this;
			};

			this.initLoader = function () {
				this.loader = $(document.createElement("div"));
				this.loader.addClass(settings.loaderConfig.cssClass);
				htmlBoardElement.append(this.loader);

				if(app.device.currentDevice() === 'desktop') {
					this.initSideImgPreviewBlock();
				}
				
				return this;
			};

			this.initAdditionalItems = function(){
				this.overlayElement = $(document.createElement('div'));
				this.overlayElement.addClass(settings.overlayConfig.cssClass);
				
				this.overlayClose = $(document.createElement('span'))
				this.overlayClose.addClass(settings.overlayConfig.closeCssClass);
				this.overlayClose.click(function(){
					$(this).parent().removeClass('active');
				});
				this.overlayElement.append(this.overlayClose);

				this.overlayMsg = $(document.createElement('div'));
				this.overlayElement.append(this.overlayMsg);

				htmlBoardElement.append(this.overlayElement);

				return this;
			};
			
			this.activatePatchesTab = function(index, animationSpeed) {
				animationSpeed = animationSpeed || 0;
				
				var visiblePatchesTabsNames = this.getVisiblePatchesTabsNames();
				if (visiblePatchesTabsNames[index]) {
					this.patchesTabs[visiblePatchesTabsNames[index]].clickFunction().scrollFunction(animationSpeed);
				}
			};
			
			this.getVisiblePatchesTabsNames = function() {
				var visibleTabs = [];
				
				$.each(this.patchesTabs, function(key, tab) {
					if (tab.isVisible) {
						visibleTabs.push(key);
					}
				});
				
				return visibleTabs;
			}

			this.setActivePatches = function(configuration){
				
				var activePatches = null;
				if(typeof configuration !== 'undefined'){
					activePatches = configuration;
				}
				else if(
					settings.hasOwnProperty('activePatches') &&
					settings.activePatches !== null &&
					Array.isArray(settings.activePatches) &&
					settings.activePatches.length > 0
				){
					activePatches = settings.activePatches;
				}

				var $this = this;
				if(activePatches !== null){
					var x = 0;

					var asyncLoop = function(arr, func) {
						func(arr[x], function(){
							x++;
							$cache.document.trigger('configurator.keypartloaded');
							if(x < arr.length) {
								$this.adaptToScreenHeight();
								asyncLoop(arr, func);
							} else {
								$this.adaptToScreenHeight();
								$this.showPatchesOnSide();
								$this.triggerUpdate( 'setActivePatches' );
								$this.triggerUpdatePrice();
								$cache.document.trigger('setActivePatchesLoop.calc');
							}
						});
						if (x === arr.length) {
							$cache.document.trigger('setActivePatchesLoop.calc');
						}
					};

					asyncLoop(activePatches, this.setPatchOnSide);
				} else {
					$this.showPatchesOnSide();
					$this.triggerUpdate( 'setActivePatches' );
					$this.triggerUpdatePrice();
				}

				return this;
			};

			this.setPatchOnSide = function(config, callback) {
				if(settings.items.hasOwnProperty(config.item) && settings.items[config.item].hasOwnProperty('element')){
					var patch = null,
						patchCallback;

					if(config.hasOwnProperty('patch') && settings.patches.hasOwnProperty(config.patch)){
						patch = settings.patches[config.patch];
					}
					else if (config.hasOwnProperty('patch_id')){
						patch = settings.patches.find(function( obj ) {
							return obj.id === config.patch_id;
						})
					}

					if(typeof patch !== 'undefined' && patch !== null && patch.hasOwnProperty('element')){
						patch.element.setAsActive = false;
						patchCallback = patch.element.addPatch.bind(patch.element, callback, config.x, config.y, config.rotateAngle, config.text);

						if(typeof settings.items[config.item].element.sides[config.side] !== 'undefined'){
							setTimeout(function(){
								settings.items[config.item].element.sides[config.side].activate(patchCallback);
							}, 0);
						}
					}
				}
			};

			this.loading = function () {
				this.loadingElements++;
				this.isLoading = true;
				if(!this.hideLoader){
					this.loader.addClass('active');
				}

				return this;
			};

			this.loaded = function () {
				this.loadingElements--;

				if(this.loadingElements === 0){
					this.isLoading = false;
					this.loader.removeClass('active');
					htmlBoardElement.trigger('loaded');
				}

				return this;
			};

			this.refresh = function (){
				if(this.activeItem.hasOwnProperty('activeSide') && this.activeItem.activeSide.hasOwnProperty('canvasRefresh')){
					this.activeItem.activeSide.canvasRefresh();
				}
				this.activeItem.activeSide.refreshImage();
				var activeItem = this.activeItem;
				$.each(this.activePatches, function (sideKey, side) {
					if (sideKey === activeItem.id + '_' + activeItem.activeSide.id) {
						$.each(side, function (patchKey, patch) {
							patch.refresh();
						});
					}
				});

				return this;
			};

			this.adaptToScreenHeight = function () {
				var sideName = this.getCurrentBoardSidekey();
				var imageElement = this.activeItem.activeSide.imageElement;
				var self = this;
				app.components.global.images.imageLoaded(imageElement).then(function() {
					var mobileImageOffset = self.activeItem.activeSide.mobileImageOffset || settings.mobile.imageOffset[settings.type] || 100;
					mobileImageOffset /= 100;
					var heightProperty = app.device.isMobileUserAgent() && mobileImageOffset === 1 ? 'maxHeight' : 'height';
					var imageWithOffset = app.device.isMobileUserAgent() && mobileImageOffset < 1;
					var mobileHeight = window.innerWidth * mobileImageOffset;
					imageWrapper.css('maxWidth', '');
					imageWrapper.css(heightProperty, app.device.isMobileUserAgent() ? mobileHeight : '100%');
					var elementTop = htmlBoardElement.offset().top;
					var elementBottom = elementTop + htmlBoardElement.outerHeight();
					var sideHeight = parseInt(imageWrapper.css(heightProperty));
					if (!app.device.isMobileUserAgent()) {
						sideHeight += (Math.abs($(window).height() - elementBottom));
					}
					
					if (sideHeight > 0) {
						imageWrapper.css(heightProperty, imageWithOffset ? mobileHeight : sideHeight);
						
						var body = document.body,
							html = document.documentElement;
						
						if (!imageWithOffset && app.device.isMobileUserAgent() && app.device.isIOS()
							&& body.scrollHeight > body.offsetHeight) {
							sideHeight -= (body.scrollHeight - body.offsetHeight);
							imageWrapper.css(heightProperty, sideHeight);
						}

						var multiplier = app.device.currentDevice() === 'desktop' ? preciseDeviceRatio : 1;
						var documentHeight = Math.max(body.scrollHeight, html.clientHeight, html.offsetHeight);
						var clientHeight = !app.device.isMobileUserAgent() ? Math.max(screen.height, window.innerHeight) : window.innerHeight;
						var clientDelta = 0;

						if (!imageWithOffset && documentHeight > clientHeight) {
							clientDelta += documentHeight - clientHeight;
						}
						if (multiplier > 1 && window.innerHeight <= screen.height) {
							//scroll height
							clientDelta += 40;
						}
						if (clientDelta > 0) {
							sideHeight -= clientDelta;
							imageWrapper.css(heightProperty, sideHeight);
						}
						
						var maxWidth = imageWithOffset && self.activeItem.activeSide.shape === 'square' ? 
							mobileHeight : sideHeight * imageElement.width() / parseInt(imageElement.css(heightProperty));
						imageWrapper.css('maxWidth', maxWidth);
						self.zoomIn(true);
					}
				});
			};

			this.validateSize = function(newSize) {
				var toRemove = [];
				$.each(this.activePatches, function (sideKey, side) {
					$.each(side, function (patchKey, patch) {
						if(patch.isBannedSize(newSize)){
							toRemove.push(patch);
						}
					});
				});

				if(toRemove.length > 0){
					return {
						valid: false,
						callback: function () {
							$.each(toRemove, function (patchKey, patch) {
								patch.removePatch(true);
							})
							settings.beforeCallback({type: 'hide'});
						}
					};
				}
				else{
					return {valid: true};
				}
			};

			this.initSideImgPreviewBlock = function () {
				var sidePreviewBlock = $('<div/>', {class: settings.sidesConfig.sidePreview.container.cssClass + ' ' + settings.sidesConfig.sidePreview.container.jsClass})
				var sidePreviewImgBlockClass = settings.sidesConfig.sidePreview.imgContainerClass.cssClass + ' ' + settings.sidesConfig.sidePreview.imgContainerClass.jsClass;
				var prevSidePreviewImgBlock =  $('<div/>', {class: sidePreviewImgBlockClass}); 
				var nextSidePreviewImgBlock = $('<div/>', {class: sidePreviewImgBlockClass});
				var prevSidePreview = $('<div/>', {class: settings.sidesConfig.sidePreview.prev.cssClass + ' ' + settings.sidesConfig.sidePreview.prev.jsClass}).append(prevSidePreviewImgBlock);
				var nextSidePreview = $('<div/>', {class: settings.sidesConfig.sidePreview.next.cssClass + ' ' + settings.sidesConfig.sidePreview.next.jsClass}).append(nextSidePreviewImgBlock);
				
				sidePreviewBlock.append(prevSidePreview, nextSidePreview);
				$(settings.configuratorContainer).append(sidePreviewBlock);
				
				return this;
			}
			
			this.init = function () {
				if(settings.typeCssClass){
					htmlBoardElement.addClass(settings.typeCssClass);
				}
				if(settings.adaptSizeToElement){
					var element = $(settings.adaptSizeToElement);
					if(element.length){
						htmlBoardElement.height(element.height());
					}
				}

				this
					.initLoader()
					.initPatches()
					.initItems()
					.initAdditionalItems()
					.initControllers()
					.initEvents();
				

				htmlBoardElement.on('loaded', function () {
					if(settings.fitScreen){
						this.adaptToScreenHeight();
					}
				}.bind(this));
				
				htmlBoardElement.one('loaded', function () {
					this.setActivePatches();
				}.bind(this));

				if(settings.fitScreen){
					this.adaptToScreenHeight();

					$window.load(function () {
						this.adaptToScreenHeight();
					}.bind(this));
				}
			};

			this.init();
		};

		this.initConfigurator = function () {
			board = new Board(
				$(settings.itemsContainer),
				$(settings.sidesContainer),
				$(settings.imageContainer),
				$(settings.imageWrapper),
				$(settings.patchesContainer),
				$(settings.tabsContainer),
				$(settings.controllersContainer)
			);
		};

		this.setValue = function(key, value){
			settings[key] = value;

			return this;
		};

		this.changeLoaderState = function( state ) {
			board.hideLoader = state;

			return this;
		}

		this.refresh = function(){
			board.refresh();

			return this;
		};

		this.adaptToScreenHeight = function () {
			board.adaptToScreenHeight();
		};

		this.setActivePatches = function (patches) {
			settings.activePatches = patches;
			board.reset();
			board.setActivePatches(patches)

			return this;
		};

		this.validateSize = function (size) {
			return board.validateSize(size);
		};

		if(settings.autoInit){
			this.initConfigurator();
		};
		
		this.activatePatchesTab = function(index, animationSpeed) {
			animationSpeed = animationSpeed || 0;
			
			board.activatePatchesTab(index, animationSpeed);
		};
		
		this.activateItem = function(index) {
			return board.activateItem(index);
		}

		return this;
	};

}( jQuery ));
